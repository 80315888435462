import { Stack, SxProps } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import CopilotReply from './CopilotReply';

const MAX_REPLIES = 2;

const hasInvalidReplies = (replies: string[]) => replies.some(reply => reply === 'INVALID');

export default function CopilotReplies({
  replies,
  onInsertClick,
  onSendClick,
}: CopilotRepliesProps) {
  const { t } = useTranslation();
  return (
    <Stack sx={bodyContainer} data-testid='copilot-replies'>
      {replies.length > 0 && !hasInvalidReplies(replies) ? (
        replies.slice(0, MAX_REPLIES).map((reply, index) => {
          return (
            <CopilotReply
              key={`instant-reply-${index}`}
              reply={reply}
              order={index + 1}
              onInsertClick={reply => onInsertClick(reply)}
              onSendClick={reply => onSendClick(reply)}
            />
          );
        })
      ) : (
        <CopilotReply reply={t('ai_copilot_sensitive_response')} />
      )}
    </Stack>
  );
}

type CopilotRepliesProps = {
  replies: string[];
  onInsertClick: (reply: string) => void;
  onSendClick: (reply: string) => void;
};

const bodyContainer: SxProps = {
  padding: '16px',
  flexDirection: 'column',
  gap: '24px',
  overflowY: 'auto',
  flex: 1,
  width: '100%',
};
