import { createSlice } from '@reduxjs/toolkit';

interface DeliveryLogsState {
  refetch: boolean;
}

const initialState: DeliveryLogsState = {
  refetch: false,
};

const deliveryLogsSlice = createSlice({
  name: 'deliverylogs',
  initialState,
  reducers: {
    triggerRefetch(state) {
      state.refetch = true;
    },
    resetRefetch(state) {
      state.refetch = false;
    },
  },
});

export const { triggerRefetch, resetRefetch } = deliveryLogsSlice.actions;

export default deliveryLogsSlice;
