import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import { Box, MenuItem, Switch, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import { AlertMessage } from '@operto/notifications-shared';
import TaskAlertListItem from 'Common/Lists/TaskAlertListItem';
import PropertyAlertsDropDownTable from 'Common/Tables/PropertyAlerts/PropertyAlertsDropDownTable';
import { companySelector } from 'company/state/companySelectors';
import { IPropertyAlertsStats } from 'dashboard/dashboardTypes';
import { dashboardStatSelector } from 'dashboard/state/dashboardSelectors';
import useNotifications from 'hooks/useNotifications';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import { logger } from 'lib/logger';
import { membersByIdsSelector } from 'member/state/memberSelectors';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toggleSlidebar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SlidebarType } from 'types/ui';
import TabBar from 'ui-library/Components/tabBar/TabBar';

enum AlertFilterType {
  TASKS = 'tasks',
  PROPERTY = 'property',
}

const AlertBell = () => {
  const dispatch = useAppDispatch();
  const { isFeatureEnabled } = useAppFeatures();
  const { t } = useTranslation();
  const { getAlerts, updateAlert } = useNotifications();
  const location = useLocation();
  const company = useAppSelector(companySelector());
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement>();
  const propertyAlertsState = useAppSelector(
    dashboardStatSelector('property_alerts_stats'),
  ) as IPropertyAlertsStats;
  const [tabIndex, setTabIndex] = React.useState(0);
  const [filterType, setFilterType] = React.useState(AlertFilterType.TASKS);
  const [taskAlertList, setTaskAlertList] = useState<AlertMessage[]>([]);
  const [unreadTaskAlertList, setUnreadTaskAlertList] = useState<AlertMessage[]>([]);
  const [showUnreads, setShowUnreads] = useState<boolean>(false);
  const propertyAlertsTotal = propertyAlertsState.total_alerts;

  const { data: allAlertsList, refetch: refetchAlerts } = getAlerts();

  const fetchAlerts = useCallback(() => {
    try {
      const orderedTaskAlerts: AlertMessage[] = (allAlertsList ?? ([] as AlertMessage[]))
        .flatMap(a => a.data.flat())
        .filter(alert => alert.type === 'task')
        .sort(
          (a: AlertMessage, b: AlertMessage) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );

      setTaskAlertList(orderedTaskAlerts);
      setUnreadTaskAlertList(orderedTaskAlerts.filter(taskAlertData => !taskAlertData.readAt));
    } catch (err) {
      logger.error('Failed to fetch alerts:', err);
    }
  }, [allAlertsList]);

  const unreadTaskAlertTotal = unreadTaskAlertList.length;

  const memberIds = [
    ...new Set(
      taskAlertList.reduce(
        (acc, item) => [...acc, +item.assigneeId, +item.meta.assignedBy],
        [],
      ) as number[],
    ),
  ];

  const members = useAppSelector(membersByIdsSelector(memberIds));

  const upperTabItems = [
    {
      label: `Tasks (${unreadTaskAlertTotal})`,
      value: AlertFilterType.TASKS,
      isDropDown: false,
    },
    {
      label: `Property (${propertyAlertsTotal})`,
      value: AlertFilterType.PROPERTY,
      isDropDown: false,
    },
  ];

  // TODO: remove when feature flag is no longer needed
  const handleClick = () => {
    dispatch(toggleSlidebar(SlidebarType.SHOW_PROPERTY_ALERTS, {}));
  };

  const handleAlertIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleMarkAllAsRead = async () => {
    const updatedTaskAlertList = taskAlertList.map(alert =>
      unreadTaskAlertList.some(unreadAlert => unreadAlert.id === alert.id)
        ? { ...alert, readAt: new Date().toISOString() }
        : alert,
    );

    setTaskAlertList(updatedTaskAlertList);
    setUnreadTaskAlertList([]);

    try {
      await Promise.all(
        unreadTaskAlertList.map(alert => {
          updateAlert({ id: alert.id, read: true });
        }),
      );

      await refetchAlerts();
    } catch {
      setTaskAlertList(taskAlertList);
      setUnreadTaskAlertList(unreadTaskAlertList);
    }

    setAnchorElMenu(undefined);
  };

  const handleTabIndexChange = (value: number) => {
    setTabIndex(value);
    setFilterType(upperTabItems[value].value);
  };

  const renderAlerts = useCallback(() => {
    const loadTaskAlerts = showUnreads ? unreadTaskAlertList : taskAlertList;
    return loadTaskAlerts.map((alert, index) => {
      const member = members.find(member => +member?.id === Number(alert.assigneeId)) || null;
      const assignedBy =
        members.find(member => +member?.id === Number(alert.meta.assignedBy)) || null;
      return (
        <React.Fragment key={index}>
          <TaskAlertListItem
            assigneeMember={member}
            assignedByMember={assignedBy}
            alert={alert}
            setAnchorEl={setAnchorEl}
          />
          <Divider />
        </React.Fragment>
      );
    });
  }, [taskAlertList, unreadTaskAlertList, showUnreads, members]);

  useEffect(() => {
    refetchAlerts().then(() => {
      fetchAlerts();
    });
  }, [refetchAlerts, fetchAlerts, location]);

  return (
    <>
      {isFeatureEnabled('tasks', company.id) ? (
        <IconButton onClick={handleAlertIconClick} size='large'>
          <Badge badgeContent={propertyAlertsTotal + unreadTaskAlertTotal} color='primary'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      ) : (
        <IconButton onClick={handleClick} size='large'>
          <Badge badgeContent={propertyAlertsTotal} color='error'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      )}

      <Menu
        PaperProps={{
          sx: { width: '516px', height: '800px', borderRadius: '16px', paddingX: '10px' },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '16px',
            paddingTop: '10px',
          }}
        >
          <Typography variant='h6'>Notifications</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Switch
              onChange={(e, val) => {
                setShowUnreads(val);
              }}
              checked={showUnreads}
            />
            <Typography variant='body1'>Unreads</Typography>
            <IconButton
              aria-label='more'
              id='long-button'
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup='true'
              onClick={handleMenuIconClick}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Box>
        <TabBar tabItems={upperTabItems} tabIndex={tabIndex} onTabChange={handleTabIndexChange} />
        {filterType === AlertFilterType.TASKS && (
          <List
            id='scrollable-activity'
            sx={{
              width: '100%',
              height: '550px',
              padding: '0px',
            }}
          >
            {taskAlertList.length === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <FactCheckOutlinedIcon sx={{ width: '80px', height: '80px' }} />
                <Typography sx={{ fontSize: '28px', fontWeight: '400' }}>
                  {t('no_notifications')}
                </Typography>
              </Box>
            ) : (
              renderAlerts()
            )}
          </List>
        )}

        {filterType === AlertFilterType.PROPERTY && <PropertyAlertsDropDownTable />}
      </Menu>

      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(anchorElMenu)}
        anchorEl={anchorElMenu}
        onClose={() => setAnchorElMenu(undefined)}
      >
        <MenuItem onClick={handleMarkAllAsRead}>{t('mark_all_as_read')}</MenuItem>
      </Menu>
    </>
  );
};

export default AlertBell;
