import { Avatar, SxProps } from '@mui/material';
import { tasksChatDropdownPropsType } from 'Common/DropDown/MessageDropDown';
import ChannelListItem from 'Common/Lists/ChannelListItem';
import { messengerSettingSelector } from 'company/state/companySelectors';
import { formatRelativeToTimeZone } from 'helper/date';
import { isProduction } from 'lib/constants';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGuestPortal } from 'redux/guestportal';
import { useAppSelector } from 'redux/hooks';
import { ChannelPreviewUIComponentProps } from 'stream-chat-react';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';

type MessageChanneListItemDropdownProps = ChannelPreviewUIComponentProps & {
  onClose: () => void;
  tasksChatDropdownProps?: tasksChatDropdownPropsType[];
};

const MessageChanneListItemDropdown = (props: MessageChanneListItemDropdownProps) => {
  const { channel, onClose } = props;

  const [isNewMessage, setIsNewMessage] = useState(false);

  const navigate = useNavigate();

  const messengerSetting = useAppSelector(messengerSettingSelector());
  const { settings } = useGuestPortal();

  const memberMessengerEnabled = messengerSetting.mp_member_messenger_enabled;
  const guestMessengerEnabled = messengerSetting.mp_guest_messenger_enabled;
  const smartButtonMessengerEnabled = messengerSetting.gp_smart_button_messenger_enabled;
  const checkinEarlyEnabled = settings?.checkinEarlyEnabled;

  // sort out the channel by guests or members
  const filteredChannelItem = () => {
    let item = null;
    if (
      memberMessengerEnabled &&
      !guestMessengerEnabled &&
      !smartButtonMessengerEnabled &&
      !checkinEarlyEnabled
    ) {
      if (channel.data?.channel_data === 'members') item = channel;
    } else if (
      !memberMessengerEnabled &&
      (guestMessengerEnabled || smartButtonMessengerEnabled || checkinEarlyEnabled)
    ) {
      if (channel.data?.channel_data === 'guests') item = channel;
    } else {
      item = channel;
    }

    return item;
  };

  const linkTo = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, url: string) => {
    navigate(url);
  };

  const handleChannelOnClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    linkTo(event, `/messenger/${channel.id}`);
    onClose();
  };

  channel.on('message.new', () => {
    setIsNewMessage(true);
  });

  const updatedAt = isProduction
    ? (channel.data?.updated_at as string)
    : (filteredChannelItem()?.data?.updated_at as string);
  const formattedTime = formatRelativeToTimeZone(updatedAt, 'UTC');

  let lastMessageText = isProduction
    ? channel.state.messages[channel.state.messages.length - 1]?.text || ''
    : filteredChannelItem()?.state.messages[filteredChannelItem()?.state.messages.length - 1]
        ?.text || '';

  lastMessageText =
    lastMessageText?.length < 60 ? lastMessageText : `${lastMessageText.slice(0, 70)}...`;

  let titleText = isProduction
    ? channel.data?.name || 'Channel'
    : filteredChannelItem()?.data?.name || 'Channel';
  titleText = titleText?.length < 10 ? titleText : `${titleText.slice(0, 14)}...`;

  const taskId = channel?.id?.split('tid')[1];
  const task = props?.tasksChatDropdownProps?.find(task => task.id === taskId);

  return (
    filteredChannelItem() && (
      <ChannelListItem
        title={titleText}
        onClick={handleChannelOnClick}
        message={lastMessageText}
        hasNewMessage={isNewMessage}
        iconObject={
          taskId ? (
            task?.assigneeName ? (
              <ProfileIcon size={'medium'} fullName={task?.assigneeName} />
            ) : (
              <Avatar sx={unassignedAvatarStyles} />
            )
          ) : (
            <ProfileIcon
              imageUrl={filteredChannelItem()?.data?.image}
              size={'medium'}
              fullName={filteredChannelItem()?.data?.name}
            />
          )
        }
        rightSubtext={formattedTime}
      />
    )
  );
};

const unassignedAvatarStyles: SxProps = {
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  width: '36px',
  height: '36px',
};

export default MessageChanneListItemDropdown;
