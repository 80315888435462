import { CloseOutlined } from '@mui/icons-material';
import { Box, IconButton, InputAdornment } from '@mui/material';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
  TextFieldPhone,
} from '@operto/ui';

import useTranslation from 'hooks/useTranslation';
import React, { useEffect, useState } from 'react';

export function getParsedPhoneNumber(phoneNumber: string) {
  // Check if the phone number starts with '+'; if not, add it
  if (!phoneNumber.startsWith('+')) {
    phoneNumber = `+1${phoneNumber}`;
  }

  // Parse the phone number and return the parsed number object
  const parsedNumber = parsePhoneNumber(phoneNumber);
  return parsedNumber;
}

export interface ClearablePhoneInputProps {
  phoneValue: string;
  onPhoneChange: (value: string) => void;
  onPhoneError: (value: boolean) => void;
}

export const ClearablePhoneInput = ({
  phoneValue,
  onPhoneChange,
  onPhoneError,
}: ClearablePhoneInputProps) => {
  const { t, locale } = useTranslation();
  const isValid = (phone: string) => {
    return !phone || (isPossiblePhoneNumber(phone) && isValidPhoneNumber(phone));
  };

  const parsedNumber = getParsedPhoneNumber(phoneValue);
  const countryCode = parsedNumber?.country || 'US';
  phoneValue = parsedNumber?.formatInternational() || '';
  const [phone, setPhone] = useState(phoneValue || '');
  const [error, setError] = useState(!isValid(phone));

  useEffect(() => {
    if (onPhoneError) {
      onPhoneError(error);
    }
  }, [error, onPhoneError]);

  const handleChange = (value: string) => {
    setPhone(value);
    setError(!isValid(value));
    onPhoneChange(value);
  };

  const handleClear = () => {
    setPhone('');
    setError(true);
    onPhoneChange('');
  };

  const topValue = error ? '40%' : '60%';
  const transformValue = error ? 'translateY(-40%)' : 'translateY(-60%)';

  return (
    <Box position='relative' display='inline-flex' width='100%'>
      <TextFieldPhone
        useFlag
        value={phone}
        onPhoneChange={handleChange}
        helperText={!phone ? t('phone_is_required') : error ? t('phone_is_invalid') : undefined}
        locale={locale}
        defaultCountry={countryCode}
        placeholder={t('phone_number')}
      />
      {true && (
        <InputAdornment
          position='end'
          sx={{
            position: 'absolute',
            right: '8px',
            top: topValue,
            transform: transformValue,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            pointerEvents: 'auto', // Ensure the button is clickable
          }}
        >
          <IconButton aria-label='clear input' onClick={handleClear} edge='end' size='small'>
            <CloseOutlined />
          </IconButton>
        </InputAdornment>
      )}
    </Box>
  );
};
