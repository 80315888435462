// @ts-nocheck
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Collapse,
  Grid,
  Paper,
  Popover,
  PopoverProps,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SettingCard } from '@operto/ui-library';
import CategoriesDropdown from 'Pages/GuestPortal/Common/CategoriesDropdown';
import addDays from 'date-fns/addDays';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GuideCategory, ICategory, IPage, useGuestPortal } from 'redux/guestportal';
import { newCategoryTemplate } from 'redux/guestportal/hooks/useGuidesCategories';
import { useAppSelector } from 'redux/hooks';
import { ApplicationState } from 'redux/reducers';
import SimpleReactValidator from 'simple-react-validator';
import { GuidesDetailsPagesFormProps } from './GuidesDetailsPagesForm';

type CategoryPopoverProps = PopoverProps & {
  onSave: (category: GuideCategory) => void;
};
const CategoryPopover: React.FC<CategoryPopoverProps> = props => {
  const { onSave, onClose } = props;
  const user = useAppSelector((state: ApplicationState) => state.members.byId[state.user.user.id]);
  const [errors, setErrors] = React.useState([]);
  const [categoryName, setCategoryName] = useState<string>('');
  // TODO: UNCATEGORIZED
  const validator = new SimpleReactValidator({
    validators: {
      no_uncategory: {
        message: ` ${`The word "uncategorized" is a reserved word. Please use other names that doesn't contain any reserved words.`}`,
        rule: val => {
          return !val.toLowerCase().includes('uncategorized');
        },
        required: true,
      },
    },
  });
  const [helperErrorText, setHelperErrorText] = useState('');

  const handleOnBlur = (e: React.ChangeEvent) => {
    const field = e.target.id;
    validator.showMessageFor(field);
    setHelperErrorText(validator?.errorMessages?.category_name_input);
    const tempErrorObj: Record<string, unknown> = {};
    tempErrorObj[field] = !validator.fieldValid(field);
    setErrors(tempErrorObj);
  };

  return (
    <Popover
      {...props}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Paper square elevation={10} style={{ padding: 20, width: 300 }}>
        <Grid container justifyContent='space-between'>
          <Typography>Create new category</Typography>
          <CloseIcon onClick={e => onClose?.(e, 'backdropClick')} />
        </Grid>
        <TextField
          style={{ marginTop: 10, marginBottom: 10 }}
          id='category_name_input'
          label='Category Name'
          placeholder="Enter category's name"
          error={errors.category_name_input}
          variant='outlined'
          fullWidth
          value={categoryName ?? ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCategoryName(e.target.value);
          }}
          onBlur={handleOnBlur}
          helperText={errors.category_name_input ? helperErrorText : ''}
        />
        <Typography>
          {validator.message('category_name_input', categoryName, 'required|no_uncategory')}
        </Typography>
        <Grid container justifyContent='flex-end'>
          <Button onClick={e => onClose?.(e, 'backdropClick')}>CANCEL</Button>
          <Button
            disabled={!validator.allValid()}
            onClick={() => {
              if (validator.allValid()) {
                onSave({
                  ...newCategoryTemplate(user?.name ?? 'Unknown'),
                  category: categoryName,
                } as ICategory);
              }
            }}
          >
            SAVE
          </Button>
        </Grid>
      </Paper>
    </Popover>
  );
};

type AssignToCategoryCardProps = GuidesDetailsPagesFormProps & {
  onCategoryChange: (category: ICategory) => void;
  page: IPage;
};
const AssignToCategoryCard: React.FC<AssignToCategoryCardProps> = props => {
  const { page, onCategoryChange } = props;
  const { categoriesList, addCategory } = useGuestPortal();

  const [category, setCategory] = React.useState<ICategory>(undefined);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const { guideId } = useParams();

  useEffect(() => {
    if (categoriesList?.length > 0) {
      if (page?.category_id) {
        setCategory(categoriesList.find((c: ICategory) => c.category_id === page.category_id));
      } else {
        setCategory(categoriesList[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesList]);

  return (
    <>
      {!page?.content_id && (
        <SettingCard title='Assign to Category' subtitle="Select this page's category">
          <CategoriesDropdown
            selectedCategory={category}
            categoriesList={categoriesList}
            onChange={e => {
              const category: ICategory = categoriesList.find(
                c => c.category_id === e.target.value,
              );
              setCategory(category);
              onCategoryChange?.(category);
            }}
          />
          <Grid container alignItems='center'>
            <Grid item>
              <Button
                color='primary'
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setOpen(true);
                  setAnchorEl(event.currentTarget);
                }}
                data-testid='add-category-button'
              >
                <AddIcon />
              </Button>
              <CategoryPopover
                open={open}
                onClose={() => setOpen(false)}
                anchorEl={anchorEl}
                data-testid-='add-category-popover'
                onSave={cat => {
                  setOpen(false);
                  addCategory({ ...cat, section_id: guideId });
                }}
              />
            </Grid>
            <Grid item>
              <Typography>Create a new category</Typography>
            </Grid>
          </Grid>
        </SettingCard>
      )}
    </>
  );
};

const PageStatusCard: React.FC<GuidesDetailsPagesFormProps> = props => {
  const { page, onPageChange } = props;
  const { propertyId } = useParams();
  return (
    <SettingCard title='Page Status' subtitle="Set this page's Published or Scheduled status">
      <Grid container style={{ marginTop: 20, alignItems: 'center' }}>
        <Grid item>
          <Switch
            onChange={(e, value) => onPageChange?.({ ...page, published: value })}
            checked={page?.published}
          />
        </Grid>
        <Grid item>
          <Typography>Publish Page</Typography>
        </Grid>
      </Grid>
      {!propertyId && (
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item>
            <Switch
              onChange={(e, value) => onPageChange?.({ ...page, featured: value })}
              checked={page?.featured}
            />
          </Grid>
          <Grid item>
            <Typography>Featured on Home Page</Typography>
          </Grid>
        </Grid>
      )}
      <Grid container style={{ alignItems: 'center' }}>
        <Grid item>
          <Switch
            onChange={(e, value) => onPageChange?.({ ...page, scheduled: value })}
            checked={page?.scheduled}
          />
        </Grid>
        <Grid item>
          <Typography>Schedule</Typography>
        </Grid>
      </Grid>
    </SettingCard>
  );
};

const ScheduleThisPageCard: React.FC<GuidesDetailsPagesFormProps> = props => {
  const { page, onPageChange } = props;
  const scheduleStart = page?.scheduled_start ? fromUnixTime(page.scheduled_start) : new Date();
  const scheduleEnd = page?.scheduled_end
    ? fromUnixTime(page.scheduled_end)
    : addDays(new Date(), 1);

  return (
    <SettingCard
      title='Schedule This Page'
      subtitle='Select the time period that this page will be published in.'
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container style={{ paddingTop: '20px' }}>
          <DatePicker
            disableToolbar
            variant='inline'
            format='MM/dd/yyyy'
            margin='normal'
            label='Start Date'
            value={scheduleStart}
            onChange={date =>
              onPageChange?.({
                ...page,
                scheduled_start: getUnixTime(date),
              })
            }
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            renderInput={params => <TextField {...params} />}
          />
          <Box marginX={3} />
          <DatePicker
            disableToolbar
            variant='inline'
            format='MM/dd/yyyy'
            margin='normal'
            label='End Date'
            value={scheduleEnd}
            onChange={date =>
              onPageChange?.({
                ...page,
                scheduled_end: getUnixTime(date),
              })
            }
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            renderInput={params => <TextField {...params} />}
          />
        </Grid>
      </LocalizationProvider>
    </SettingCard>
  );
};

type GuidesDetailsPagesFormTabSettingsProps = GuidesDetailsPagesFormProps &
  AssignToCategoryCardProps & {
    onPageChange?: (page: IPage) => void;
  };
const GuidesDetailsPagesFormTabSettings: React.FC<
  GuidesDetailsPagesFormTabSettingsProps
> = props => {
  const { page, onPageChange, onCategoryChange } = props;
  return (
    <Box style={{ width: 900 }}>
      {!page?.content_id && (
        <AssignToCategoryCard
          page={page}
          onPageChange={onPageChange}
          onCategoryChange={onCategoryChange}
        />
      )}
      <PageStatusCard page={page} onPageChange={onPageChange} />
      <Collapse in={page.scheduled}>
        <ScheduleThisPageCard page={page} onPageChange={onPageChange} />
      </Collapse>
    </Box>
  );
};

export default GuidesDetailsPagesFormTabSettings;
