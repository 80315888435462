import {
  ChatBubbleOutline as ChatBubbleOutlineIcon,
  MarkUnreadChatAlt as MarkUnreadChatAltIcon,
} from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Task } from '@operto/tasks-shared';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import useTranslation from 'hooks/useTranslation';
import MemberProfile from 'member/memberProfileGetter';
import { IMember } from 'member/memberType';
import React, { ReactNode } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { TableCell, TableCellWithTooltip } from 'ui-library/Components/table/TableCell';
import { taskPriorityComparator, taskStatusComparator } from './task-utils';
import { NOT_STARTED_STATUS } from './useTasks';

export const ColumnSet = {
  status: 'tableStatus',
  priority: 'priority',
  dueDate: 'dueDateLocalized',
  createdAt: 'createdAt',
  guest: 'guest',
  guestMsg: 'msgGuestCount',
  task: 'task',
  assignee: 'assignee',
  assigneeMsg: 'msgMemberCount',
  id: 'id',
} as const;

export type FieldKey = keyof typeof ColumnSet;
export type FieldType = (typeof ColumnSet)[keyof typeof ColumnSet];
type ColValKeyType = { [key in FieldType]: FieldKey };

const ColValKeyMap = Object.keys(ColumnSet).reduce<ColValKeyType>((acc, key: FieldKey) => {
  acc[ColumnSet[key]] = key;
  return acc;
}, {} as ColValKeyType);

type ColumnParams = {
  members?: IMember[];
  t?: (key: string) => string;
  navigate?: NavigateFunction;
  columnSet?: readonly FieldKey[];
};

interface TaskColumn extends GridColDef {
  field: FieldType;
}

const DEFAULT_COLUMN_SET: Readonly<FieldKey[]> = [
  'guest',
  'guestMsg',
  'task',
  'dueDate',
  'status',
  'priority',
  'assignee',
  'assigneeMsg',
  'createdAt',
  'id',
];

const StatusContainer = ({ status }: { status: string }) => {
  const { t } = useTranslation();

  const statusColor = {
    notStarted: 'rgba(0, 0, 0, 0.38)',
    canceled: 'rgba(0, 0, 0, 0.38)',
    overdue: 'rgba(211, 47, 47, 1)',
    started: 'rgba(21, 94, 255, 1)',
    completed: 'rgba(76, 175, 80, 1)',
  }[status];

  const statusText = {
    notStarted: t('not_started'),
    canceled: t('canceled'),
    overdue: t('overdue'),
    started: t('started'),
    completed: t('completed'),
  }[status];

  return (
    <TableCell
      title={
        <Box
          sx={{
            backgroundColor: statusColor,
            borderRadius: '50px',
            height: '10px',
            width: '10px',
          }}
        />
      }
      description={statusText}
      flexDirection='row'
      sx={{ alignItems: 'baseline', gap: 1 }}
    ></TableCell>
  );
};

const DateContainer = ({
  localizedDate = new Date().toISOString(),
  timezone = 'America/Vancouver',
}: {
  localizedDate: string;
  timezone: string;
}) => {
  const utcDate = zonedTimeToUtc(localizedDate, timezone);
  const datePart = format(utcToZonedTime(utcDate, timezone), 'yyyy-MM-dd', { timeZone: timezone });
  const timePart = format(utcToZonedTime(utcDate, timezone), 'hh:mm a zzz', { timeZone: timezone });

  return <TableCell title={datePart} description={timePart} />;
};

const PriorityContainer = ({ priority }: { priority?: string }) => {
  const { t } = useTranslation();

  const priorityBgColor = priority
    ? {
        low: 'rgba(115, 214, 119, 1)',
        medium: 'rgba(255, 207, 67, 1)',
        high: 'rgba(211, 47, 47, 1)',
      }[priority]
    : 'rgba(0, 0, 0, 0.12)';

  const priorityText = priority
    ? {
        low: t('low'),
        medium: t('medium'),
        high: t('high'),
      }[priority]
    : t('none');

  const priorityColor = priority
    ? {
        low: 'black',
        medium: 'black',
        high: 'white',
      }[priority]
    : 'rgba(0, 0, 0, 0.6)';

  return (
    <TableCell
      sx={{ width: 'auto' }}
      title={
        <Box
          sx={{
            backgroundColor: priorityBgColor,
            borderRadius: '4px',
            padding: '0 4px 0 4px',
            color: priorityColor,
          }}
        >
          <Typography variant='caption-sm-700' sx={{ padding: '0 2px 0 2px' }}>
            {priorityText}
          </Typography>
        </Box>
      }
    />
  );
};

export default function tasksTableColumns({
  members,
  t,
  navigate,
  columnSet = DEFAULT_COLUMN_SET,
}: ColumnParams = {}) {
  const getRowStatus = (row: Task) => {
    const dueUtc = zonedTimeToUtc(row.dueDateLocalized, row.unitTimeZone || 'America/Vancouver');
    const nowUtc = new Date();
    const isOverdue = dueUtc < nowUtc;
    const isNotStarted = NOT_STARTED_STATUS.includes(row.status);
    const isInProgress = row.status === 'in progress';
    const isCanceled = row.status === 'canceled';
    const isCompleted = row.status === 'completed';

    switch (true) {
      case isCompleted:
        return 'completed';
      case isOverdue:
        return 'overdue';
      case isInProgress:
        return 'started';
      case isCanceled:
        return 'canceled';
      case isNotStarted:
      default:
        return 'notStarted';
    }
  };

  const renderStatus = ({ row }: GridRenderCellParams<unknown, Task>) => {
    row.tableStatus = getRowStatus(row);
    return <StatusContainer status={row.tableStatus} />;
  };

  const renderPriority = ({ row }: GridRenderCellParams<unknown, Task>) => {
    return <PriorityContainer priority={row.priority} />;
  };

  const renderDue = ({ row }: GridRenderCellParams<unknown, Task>) => {
    return (
      <TableCell
        sx={{ width: '100%' }}
        title={<DateContainer localizedDate={row.dueDateLocalized} timezone={row.timezone} />}
      />
    );
  };

  const renderRequested = ({ row }: GridRenderCellParams<unknown, Task>) => {
    return (
      <TableCell
        sx={{ width: '100%' }}
        title={<DateContainer localizedDate={row.createdAt} timezone={row.timezone} />}
      />
    );
  };

  const renderGuest = ({ row }: GridRenderCellParams<unknown, Task>) => {
    const tooltipTitle = (
      <Box>
        {row.guest && (
          <>
            {row.guest}
            <br />
          </>
        )}
        {row.unit}
      </Box>
    );

    return (
      <TableCellWithTooltip
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        tooltipText={tooltipTitle}
        title={row.guest}
        description={row.unit}
      />
    );
  };

  const renderTask = ({ row }: GridRenderCellParams<unknown, Task>) => {
    const tooltipTitle = (
      <Box>
        {row.title}
        <br />
        {row.description}
      </Box>
    );

    return (
      <TableCellWithTooltip
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        tooltipText={tooltipTitle}
        title={row.title}
        description={row.description}
      />
    );
  };

  const renderAssignee = ({ row }: GridRenderCellParams<unknown, Task>) => {
    const member = members.find(member => +member?.id === +row.assigneeId);
    let tooltipTitle: string | ReactNode = '';
    if (member) {
      tooltipTitle = (
        <Box>
          {member?.name}
          <br />
          {member?.email}
        </Box>
      );
    }

    return (
      <TableCellWithTooltip
        flexDirection='row'
        sx={{ gap: 1, alignItems: 'center' }}
        tooltipText={tooltipTitle}
        title={<MemberProfile size='large' memberData={member} />}
        description={
          <TableCell
            title={member?.name}
            description={member?.email}
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          />
        }
      />
    );
  };

  const renderMessageCount = (
    value: number,
    path: string,
    checkFieldValue?: string,
    params?: { assigneeId: string },
    tooltipTitle?: string,
  ) => {
    if (!checkFieldValue) {
      return <></>;
    }

    return (
      <Button
        onClick={e => {
          e.stopPropagation();
          navigate?.(path, { state: params });
        }}
      >
        <TableCellWithTooltip
          tooltipText={tooltipTitle}
          title={
            value ? (
              <>
                <MarkUnreadChatAltIcon sx={{ color: '#9C0011' }} />
                <span style={{ color: '#9C0011' }}>{`(${value})`}</span>
              </>
            ) : (
              <ChatBubbleOutlineIcon style={{ color: '#444746' }} />
            )
          }
        />
      </Button>
    );
  };

  const renderGuestMessage = ({ value, row }: GridRenderCellParams<number, Task>) => {
    return renderMessageCount(
      value,
      `/messenger/${row.msgGroupId}`,
      row.reservationId,
      null,
      t('guest_inbox'),
    );
  };

  const renderMemberMessage = ({ value, row }: GridRenderCellParams<number, Task>) => {
    return renderMessageCount(
      value,
      `/messenger/${row.msgMemberId}`,
      row.assigneeId,
      {
        assigneeId: row.assigneeId,
      },
      t('task_inbox'),
    );
  };

  const renderID = ({ value }: GridRenderCellParams<string, Task>) => {
    return value;
  };

  const columnList = [
    {
      field: 'tableStatus',
      headerName: t('status'),
      sortable: true,
      width: 130,
      resizable: false,
      renderCell: renderStatus,
      sortComparator: taskStatusComparator,
    },
    {
      field: 'priority',
      headerName: t('priority'),
      sortable: true,
      width: 100,
      resizable: false,
      renderCell: renderPriority,
      sortComparator: taskPriorityComparator,
    },
    {
      field: 'dueDateLocalized',
      headerName: t('due'),
      sortable: true,
      width: 100,
      resizable: false,
      renderCell: renderDue,
    },
    {
      field: 'createdAt',
      headerName: t('created_on'),
      sortable: true,
      width: 100,
      resizable: false,
      renderCell: renderRequested,
    },
    {
      field: 'guest',
      headerName: t('guest'),
      maxWidth: 200,
      width: 200,
      sortable: true,
      renderCell: renderGuest,
    },
    {
      field: 'msgGuestCount',
      headerName: '',
      sortable: false,
      maxWidth: 80,
      resizable: false,
      renderCell: renderGuestMessage,
    },
    {
      field: 'task',
      headerName: t('task'),
      maxWidth: 200,
      width: 200,
      sortable: false,
      renderCell: renderTask,
    },
    {
      field: 'assignee',
      headerName: t('assignee'),
      maxWidth: 200,
      width: 200,
      sortable: true,
      renderCell: renderAssignee,
    },
    {
      field: 'msgMemberCount',
      headerName: '',
      sortable: false,
      maxWidth: 80,
      width: 80,
      resizable: false,
      renderCell: renderMemberMessage,
    },
    {
      field: 'id',
      headerName: t('id'),
      sortable: true,
      width: 200,
      resizable: false,
      renderCell: renderID,
    },
  ] as TaskColumn[];

  return columnList
    .filter(col => {
      const key = ColValKeyMap[col.field];
      return columnSet.includes(key);
    })
    .sort((cL, cR) => {
      const keyL = ColValKeyMap[cL.field];
      const keyR = ColValKeyMap[cR.field];
      return columnSet.indexOf(keyL) - columnSet.indexOf(keyR);
    });
}
