import { Grid } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import { UpsellFields } from 'Pages/Marketplace/UpsellPage';
import React, { useEffect } from 'react';
import { CalendarPickerSection } from './TabsComponents/CalendarPicker';
import InformationSection from './TabsComponents/InformationSection';
import MediaSection from './TabsComponents/MediaSection';
import { PreferredTime } from './TabsComponents/PreferredTimeSection';

interface ContentProps {
  upsellFields: UpsellFields;
  updateUpsellFields: <K extends keyof UpsellFields>(field: K, value: UpsellFields[K]) => void;
}

export const Content = ({ upsellFields, updateUpsellFields }: ContentProps) => {
  const { t } = useTranslation();
  const { information, media, date, preferredTime, imageUrl } = upsellFields;

  useEffect(() => {
    if (imageUrl && media.files.length === 0) {
      updateUpsellFields('media', { ...media, files: [imageUrl] });
    }
  }, [imageUrl, media, media.files.length, updateUpsellFields]);

  const updateContentFields = (field: keyof UpsellFields['information'], value: string | null) => {
    updateUpsellFields('information', { ...information, [field]: value });
  };

  const updateMediaFields = (field: keyof UpsellFields['media'], value: string[]) => {
    updateUpsellFields('media', { ...media, [field]: value });
    updateUpsellFields('imageUrl', value.at(0) ?? '');
  };

  const updateDateFields = (
    field: keyof UpsellFields['date'],
    value: string | string[] | boolean | null,
  ) => {
    updateUpsellFields('date', { ...date, [field]: value });
  };

  const updatePreferredTime = (
    field: keyof UpsellFields['preferredTime'],
    value: string | boolean | null,
  ) => {
    updateUpsellFields('preferredTime', { ...preferredTime, [field]: value });
  };
  // TODO: Will be updated in the future
  // const updateServiceButton = (field: keyof UpsellFields['serviceButton'], value: string) => {
  //   updateUpsellFields('serviceButton', { ...serviceButton, [field]: value });
  // };

  // TODO: Set md 12 untill right side is hidden
  return (
    <Grid item xs={12} md={12}>
      <InformationSection
        name={information.name}
        header={information.header}
        subheader={information.subheader}
        description={information.description}
        category={information.category}
        setName={(name: string) => {
          updateContentFields('name', name);
          if (!upsellFields.name || upsellFields.name === upsellFields.information.name) {
            updateUpsellFields('name', name || t('offer_empty_title'));
          }
        }}
        setHeader={(header: string) => updateContentFields('header', header)}
        setSubheader={(subheader: string) => updateContentFields('subheader', subheader)}
        setDescription={(description: string) => updateContentFields('description', description)}
        setCategory={(category: string) => updateContentFields('category', category)}
      />

      <MediaSection media={media.files} updateMediaFields={updateMediaFields} />

      <CalendarPickerSection
        header={date.header}
        datePickerType={date.datePickerType}
        active={date.active}
        setDates={(header: string) => updateDateFields('header', header)}
        setdatePickerType={(datePickerType: string) =>
          updateDateFields('datePickerType', datePickerType)
        }
        setActive={(active: boolean) => updateDateFields('active', active)}
      />

      <PreferredTime
        header={preferredTime.header}
        active={preferredTime.active}
        setHeader={(header: string) => updatePreferredTime('header', header)}
        setActive={(active: boolean) => updatePreferredTime('active', active)}
      />
      {/* // TODO: Will be updated in the future */}
      {/* <ServiceButtonSection
        serviceSelectionStatus={serviceButton.values}
        setServiceSelectionStatus={(status: string) => updateServiceButton('values', status)}
      /> */}

      {/* // TODO: Will be updated in the future */}
      {/* <ButtonSection /> */}
      {/* <QuantityPickerSection
        quantityPickerStatus={false} // update as needed
        setQuantityPickerStatus={} // Update as needed
      /> */}
    </Grid>
  );
};
