import { Typography } from '@mui/material';
import { Task } from '@operto/tasks-shared';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { StreamMessage } from 'stream-chat-react';
import { BaseSystemMessage } from './BaseSystemMessage';

interface SystemMessageProps {
  task: Task;
  message: StreamMessage;
}

export const DescriptionSystemMessage = ({ task, message }: SystemMessageProps) => {
  const { t } = useTranslation();

  const content = (
    <Typography variant='caption'>
      {t('task_description_changed', {
        title: task.title || '',
        id: task.id || '',
      })}
    </Typography>
  );

  return <BaseSystemMessage date={message.created_at?.toLocaleString() || ''} content={content} />;
};
