import EventEmitter from 'events';

export const createEventEmmiter = <T extends string>() => {
  class BaseEventEmitter extends EventEmitter {
    on(topic: T, listener: (...args: unknown[]) => void) {
      return super.on(topic, listener);
    }
    off(topic: T, listener: (...args: unknown[]) => void) {
      return super.off(topic, listener);
    }
    emit(topic: T, ...args: unknown[]) {
      return super.emit(topic, ...args);
    }
  }

  return new BaseEventEmitter();
};
