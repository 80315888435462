import { List, ListItem, ListItemText, Typography } from '@mui/material';
import InfoDialog from 'Common/Dialog/InfoDialog';
import { companySelector } from 'company/state/companySelectors';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import React from 'react';
import { useAppSelector } from 'redux/hooks';

export interface AutomateInfoDialogProps {
  open: boolean;
  onClose: () => void;
  anchorEl: null | HTMLElement;
}

const AutomateInfoDialog = ({ open, onClose, anchorEl }: AutomateInfoDialogProps) => {
  const { t } = useTranslation();
  const company = useAppSelector(companySelector());
  const { isFeatureEnabled } = useAppFeatures();
  const checkOutTriggersEnabled = isFeatureEnabled('communications-check-out-trigger', company.id);

  const phoneNumber = '+1 778-948-9487';
  const email = 'no-reply@operto.com';

  const bulletsEventTriggers = [t('before_checkin'), t('at_or_after_checkin')];
  const bulletsSenderDetails = [`${t('emails')}: ${email}`, `${t('sms')}: ${phoneNumber}`];
  const triggersCount = checkOutTriggersEnabled ? 'four' : 'two';

  const paragraphOneText = (
    <>
      <Typography variant='body1'>
        {t('you_can_set_up_to_n_event_triggers', {
          triggersCount: triggersCount,
        })}
      </Typography>
      <List sx={{ listStyleType: 'disc', padding: 0 }}>
        {bulletsEventTriggers.map((bullet, index) => (
          <ListItem key={index} sx={{ padding: '0', margin: 0, pl: 3 }}>
            <ListItemText primary={bullet} sx={{ display: 'list-item', padding: 0, margin: 0 }} />
          </ListItem>
        ))}
      </List>
      <Typography variant='body1'>{t('trigger_can_include_two_channels')}</Typography>
    </>
  );

  const paragraphTwoText = (
    <>
      <List sx={{ listStyleType: 'disc', padding: 0 }}>
        {bulletsSenderDetails.map((bullet, index) => (
          <ListItem key={index} sx={{ padding: '0', margin: 0, pl: 3 }}>
            <ListItemText primary={bullet} sx={{ display: 'list-item', padding: 0, margin: 0 }} />
          </ListItem>
        ))}
      </List>

      <Typography variant='body1'>{t('automate_info_dialog_paragraph_two_text_note')}</Typography>
    </>
  );

  return (
    <InfoDialog
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      title={t('flows')}
      paragraphOneTitle={t('event_triggers')}
      paragraphOneText={paragraphOneText}
      paragraphTwoTitle={t('sender_details')}
      paragraphTwoText={paragraphTwoText}
    />
  );
};

export default AutomateInfoDialog;
