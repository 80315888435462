import {
  SendgridMessage,
  TwilioMessage,
  InputPostAlerts,
  InputGetAlerts,
} from '@operto/notifications-shared';
import { trpc } from '@operto/trpc-client';

export default function useNotifications() {
  const sendEmailMutation = trpc?.notifications?.sendEmail?.useMutation();
  const sendEmail = async (email: SendgridMessage) => {
    return await new Promise((resolve, reject) => {
      sendEmailMutation?.mutate(email, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const sendSmsMutation = trpc?.notifications?.sendSms?.useMutation();
  const sendSms = async (sms: TwilioMessage) => {
    return await new Promise((resolve, reject) => {
      sendSmsMutation?.mutate(sms, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  const getAlerts = ({ assigneeId, unreadOnly }: InputGetAlerts = {}) =>
    trpc.notifications.getAlerts.useQuery(
      {
        assigneeId: assigneeId,
        unreadOnly: unreadOnly,
      },
      {
        enabled: !!assigneeId,
      },
    );

  const updateAlertMutation = trpc.notifications?.updateAlert?.useMutation();
  const updateAlert = async ({ id, read }: UpdateAlertInput) => {
    return await new Promise((resolve, reject) => {
      updateAlertMutation?.mutate(
        { id, read },
        {
          onSuccess: resolve,
          onError: reject,
        },
      );
    });
  };

  const createAlertMutation = trpc.notifications?.createAlert?.useMutation();
  const createAlert = async (params: InputPostAlerts) => {
    return await new Promise((resolve, reject) => {
      createAlertMutation?.mutate(params, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };

  return {
    sendEmail,
    sendSms,
    getAlerts,
    updateAlert,
    createAlert,
  };
}

// TODO: Use types exported from backend when notifications-shared is updated
export type UpdateAlertInput = {
  id: string;
  read: boolean;
};
