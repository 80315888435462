import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItemButton,
  ListSubheader,
  Popover,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React, { useCallback, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GroupViewModel, UnitViewModel } from 'services/novaApi';
import OpertoProgressCircle from 'ui-library/Components/misc/OpertoProgressCircle';
import LocksAssignPropertyDropDownSearch from './LocksAssignPropertyDropDownSearch';

export interface LockAssignPropertyDropdownProps {
  unitList?: UnitViewModel[];
  onLoadMore?: () => void;
  hasMore?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  onAssign?: (unitId: number, unitName: string) => void;
  searchKeyword: string;
  onSearchChange: (value: string) => void;
  groupList?: GroupViewModel[];
  onGroupSelect?: (group: number) => void;
  selectedGroups?: number[];
}

export default function LockAssignPropertyDropdown({
  unitList = [],
  onLoadMore,
  hasMore = true,
  onAssign,
  searchKeyword,
  onSearchChange,
  groupList = [],
  onGroupSelect,
  selectedGroups,
}: LockAssignPropertyDropdownProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const [groupSearchValue, setGroupSearchValue] = useState('');

  const handleListItemClick = (index: number, unitId: number, unitName: string) => {
    setSelectedIndex(index);
    onAssign?.(unitId, unitName); // Trigger onAssign when an item is selected
  };

  // Filter the dropdown unitList based on the search input
  const filteredOptions = unitList.filter((unit: UnitViewModel) =>
    unit.unit_name?.toLowerCase().includes(searchKeyword.toLowerCase()),
  );

  const listStyle = {
    maxHeight: '300px',
    minHeight: '100px',
    overflowY: 'auto',
    marginTop: '4px',
    marginLeft: '-8px',
  };

  const formControlStyle = {
    marginBottom: '10px',
    width: '100%',
    marginLeft: 0,
  };

  const selectStyle = {
    height: '40px', // Consistent height
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'start',
    },
  };

  const boxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingX: 1,
  };

  const paperPropsStyle = {
    maxHeight: 474,
    width: '100%',
    maxWidth: 435,
  };

  const listItemButtonStyle = {
    height: '40px',
    width: '100%',
  };

  const handleGroupSelect = (event: React.MouseEvent, group: GroupViewModel) => {
    event.stopPropagation();
    onGroupSelect?.(group.id); // Trigger group selection with group ID
  };

  const [anchorEl, setAnchorEl] = useState<null | { top: number; left: number }>(null);

  const toggleGroupFilter = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    const anchorPosition = {
      top: rect.bottom,
      left: rect.left,
    };
    setAnchorEl(anchorPosition);
  }, []);

  const handleClose = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  }, []);

  const deselectAllGroups = () => {
    selectedGroups.forEach(groupId => onGroupSelect(groupId));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'group-filter-popover' : undefined;

  const filteredGroups = groupList.filter((group: GroupViewModel) =>
    group.name.toLowerCase().includes(groupSearchValue.toLowerCase()),
  );

  const handleGroupSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupSearchValue(event.target.value);
  };

  return (
    <FormControl fullWidth variant='outlined' sx={formControlStyle}>
      <InputLabel
        sx={{
          '&.MuiInputLabel-outlined': {
            transform: 'translate(14px, 10px) scale(1)',
          },
          '&.Mui-focused': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
        }}
        id='unit-select-label'
      >
        {t('assign_unit')}
      </InputLabel>
      <Select
        labelId='unit-select-label'
        label={t('assign_unit')}
        onOpen={() => inputRef.current?.focus()}
        sx={selectStyle}
        MenuProps={{
          PaperProps: {
            sx: paperPropsStyle,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        <ListSubheader sx={{ padding: '8px' }}>
          <LocksAssignPropertyDropDownSearch
            value={searchKeyword}
            onChange={e => onSearchChange(e.target.value)}
            fullWidth
            toggleGroupFilter={toggleGroupFilter}
            placeholder={t('unit_search_place_holder')}
            showTuneIcon={true}
            handleClearInput={onSearchChange}
          />
        </ListSubheader>

        <List sx={{ width: '100%', overflowY: 'auto', maxHeight: '300px' }} id='unit-select-scroll'>
          <InfiniteScroll
            dataLength={unitList.length}
            next={onLoadMore} // Function to fetch more items
            hasMore={hasMore} // Indicates whether more data is available
            loader={<OpertoProgressCircle />}
            scrollableTarget='unit-select-scroll' // This must match the `id` of the container
          >
            {filteredOptions.map((option, index) => (
              <Tooltip
                key={index}
                title={option.is_lock_assigned ? t('disabled_unit_tooltip') : ''}
                placement='bottom'
              >
                <ListItemButton
                  data-testid='unit-list-item'
                  role='button'
                  selected={selectedIndex === index}
                  onClick={() => handleListItemClick(index, option.unit_id, option.unit_name)}
                  sx={listItemButtonStyle}
                  disabled={option.is_lock_assigned}
                >
                  {option.unit_name}
                </ListItemButton>
              </Tooltip>
            ))}
          </InfiniteScroll>
        </List>

        <Popover
          id={id}
          open={open}
          anchorReference='anchorPosition'
          anchorPosition={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            sx: { width: '370px', maxHeight: '500px', p: 2 },
          }}
        >
          <Box sx={boxStyle}>
            <Typography variant='subtitle1' gutterBottom sx={{ margin: 0 }}>
              {t('units_groups')}
            </Typography>
            <IconButton onClick={handleClose} sx={{ p: 0, position: 'relative', left: -10 }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box>
            <LocksAssignPropertyDropDownSearch
              value={groupSearchValue}
              onChange={handleGroupSearchChange}
              fullWidth
              size='small'
              toggleGroupFilter={toggleGroupFilter}
              placeholder={t('group_search_place_holder')}
              showTuneIcon={false}
              handleClearInput={() => setGroupSearchValue('')}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Typography variant='body2' gutterBottom sx={{ margin: 0, paddingLeft: 1 }}>
                {`${t('use_the')} `}
                <Link
                  href='/groups'
                  sx={{
                    color: 'primary.main',
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                    fontWeight: 'bold',
                  }}
                >
                  {t('units_groups')}
                </Link>
                {` ${t('page_to_categorize')}`}
              </Typography>
            </Box>
          </Box>

          <List id='unit-select-scroll' sx={listStyle}>
            {filteredGroups.map((group, index) => (
              <ListItemButton key={index} onClick={event => handleGroupSelect(event, group)}>
                <Checkbox
                  data-testid={'group-check-box'}
                  checked={selectedGroups.includes(group.id)}
                  role='checkbox'
                />
                {group.name}
              </ListItemButton>
            ))}
          </List>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 1 }}>
            <Button
              onClick={event => {
                event.stopPropagation();
                deselectAllGroups();
              }}
              sx={{ color: 'primary.main', textTransform: 'none' }}
            >
              {t('clear')}
            </Button>
          </Box>
        </Popover>
      </Select>
    </FormControl>
  );
}
