import { Button } from '@mui/material';
import { CompanyMetaData } from 'company/companyType';
import {
  getCompanyInformation,
  getMetaData,
  updateCompanyMetaData,
} from 'company/state/companyAction';
import { getCompanyMetaData } from 'company/state/companySelectors';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { TranslationCard } from './TranslationCard';

type TranslationProps = {
  visible?: boolean;
};

export const Translation = ({ visible }: TranslationProps) => {
  const { snackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [exclusionList, setExclusionList] = React.useState<string[]>([]);

  const metaData = useAppSelector(getCompanyMetaData());
  const metaDataJson = React.useMemo(() => {
    try {
      if (!metaData) return { translation_exclusion: [] };
      return JSON.parse(metaData);
    } catch (e) {
      logger.error('Error parsing metadata', e);
      return { translation_exclusion: [] };
    }
  }, [metaData]);

  useEffect(() => {
    setExclusionList(metaDataJson.translation_exclusion ?? []);
  }, [metaDataJson]);

  const setMetaData = (metaData: CompanyMetaData): Promise<void> => {
    return Promise.resolve(dispatch(updateCompanyMetaData(metaData)));
  };

  React.useEffect(() => {
    dispatch(getCompanyInformation());
    dispatch(getMetaData());
  }, [dispatch]);

  const handleSave = async (input: string): Promise<CompanyMetaData | null> => {
    try {
      const updatedExclusions = [...exclusionList];

      if (input.trim() && !exclusionList.some(item => item.toLowerCase() === input.toLowerCase())) {
        updatedExclusions.push(input);
      }

      const updatedMetaData = {
        ...metaDataJson,
        translation_exclusion: updatedExclusions,
      };

      await setMetaData(updatedMetaData);
      setExclusionList(updatedExclusions);

      return updatedMetaData;
    } catch (e) {
      setExclusionList(metaDataJson.translation_exclusion);
      logger.error('Error update metadata', e);
      return null;
    }
  };

  const undoAction = (input: string) => (
    <Button
      color='secondary'
      size='small'
      data-testid='undo-assign-lock-button'
      sx={{ color: 'white', textTransform: 'none', fontWeight: 'bold' }}
      onClick={() => {
        handleSave(input);
        snackbar(t('snackbar_undoing'), null);
        setTimeout(() => {
          snackbar(t('snackbar_undone'), null);
        }, 3000);
      }}
    >
      {t('snackbar_undo_button')}
    </Button>
  );

  const handleDelete = (index: number) => {
    try {
      const inputWordOrPhrase = exclusionList[index];
      const updatedExclusions = [...exclusionList];
      updatedExclusions.splice(index, 1);
      setExclusionList(updatedExclusions);

      const updatedMetaData = {
        ...metaDataJson,
        translation_exclusion: updatedExclusions,
      };

      setMetaData(updatedMetaData);

      snackbar(t('words_removed'), undoAction(inputWordOrPhrase), 6000);
    } catch (e) {
      setExclusionList(metaDataJson.translation_exclusion);
      logger.error('Error updating metadata:', e);
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <LoadingContainer>
      <TranslationCard
        handleSave={handleSave}
        handleDelete={handleDelete}
        exclusionList={exclusionList}
      />
    </LoadingContainer>
  );
};

export default Translation;
