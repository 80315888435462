import { TriggerDataType } from '@operto/communications-shared';
import { useMemo } from 'react';

// Triggers sorting order
const triggerOrder = new Map<string, number>([
  ['BEFORE_CHECK_IN', 0],
  ['CHECK_IN', 1],
  ['AFTER_CHECK_IN', 2],
  ['BEFORE_CHECK_OUT', 3],
  ['CHECK_OUT', 4],
  ['AFTER_CHECK_OUT', 5],
]);

export default function useTriggerSorting(triggers: TriggerDataType[]) {
  const sortedTriggers = useMemo(() => {
    if (!triggers) return [];
    return [...triggers].sort((a, b) => {
      const typeOrderA = triggerOrder.get(a.type) ?? -1;
      const typeOrderB = triggerOrder.get(b.type) ?? -1;

      // first level sort by trigger type
      if (typeOrderA !== typeOrderB) {
        return typeOrderA - typeOrderB;
      }

      // second level sort by trigger offset
      return a.offset - b.offset;
    });
  }, [triggers]);

  return sortedTriggers;
}
