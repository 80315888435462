import { Type } from '@operto/tasks-shared';
import { OpertoTheme } from 'App/OpertoTheme';

export const TaskTypeStyles = (type: Type) => {
  switch (type) {
    case 'cleaning':
      return {
        value: 'cleaning',
        background: OpertoTheme.tagsColors.backgroundDefault,
        text: OpertoTheme.tagsColors.textDefault,
      };
    case 'maintenance':
      return {
        value: 'maintenance',
        background: OpertoTheme.tagsColors.maintenanceBackground,
        text: OpertoTheme.tagsColors.textDefault,
      };
    case 'housekeeping':
      return {
        value: 'housekeeping',
        background: OpertoTheme.tagsColors.housekeepingBackground,
        text: OpertoTheme.tagsColors.textDefault,
      };
    case 'accounting':
      return {
        value: 'accounting',
        background: OpertoTheme.tagsColors.backgroundDefault,
        text: OpertoTheme.tagsColors.textDefault,
      };
    case 'front desk':
      return {
        value: 'front_desk',
        background: OpertoTheme.tagsColors.backgroundDefault,
        text: OpertoTheme.tagsColors.textDefault,
      };
    case 'valet':
      return {
        value: 'valet',
        background: OpertoTheme.tagsColors.backgroundDefault,
        text: OpertoTheme.tagsColors.textDefault,
      };
    case 'room service':
      return {
        value: 'room_service',
        background: OpertoTheme.tagsColors.roomServiceBackground,
        text: OpertoTheme.tagsColors.textDefault,
      };
    case 'other':
      return {
        value: 'other',
        background: OpertoTheme.tagsColors.backgroundDefault,
        text: OpertoTheme.tagsColors.textDefault,
      };
  }
};
