import { Priority } from '@operto/tasks-shared';
import { OpertoTheme } from 'App/OpertoTheme';

export const TaskPriorityStyles = (priority: Priority) => {
  switch (priority) {
    case 'low':
      return {
        background: OpertoTheme.tagsColors.lowPriorityBackground,
        text: OpertoTheme.tagsColors.textDefault,
      };
    case 'medium':
      return {
        background: OpertoTheme.tagsColors.mediumPriorityBackground,
        text: OpertoTheme.tagsColors.textDefault,
      };
    case 'high':
      return {
        background: OpertoTheme.tagsColors.highPriorityBackground,
        text: OpertoTheme.tagsColors.highPriorityText,
      };
    default:
      return {
        background: OpertoTheme.tagsColors.backgroundDefault,
        text: OpertoTheme.tagsColors.textDefault,
      };
  }
};
