import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import { SettingCard } from '@operto/ui-library';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React from 'react';
import { CompanyMetaData } from 'company/companyType';

type TranslationCardProps = {
  handleSave: (input: string) => Promise<CompanyMetaData | null>;
  handleDelete: (index: number) => void;
  exclusionList: string[];
};

export const TranslationCard = ({
  handleSave,
  handleDelete,
  exclusionList,
}: TranslationCardProps) => {
  const { t } = useTranslation();
  const [input, setInput] = React.useState('');
  const inputExists = exclusionList.some(
    (item: string) => item.toLowerCase() === input.toLowerCase(),
  );

  const handleSaveAndReset = async () => {
    try {
      await handleSave(input);
      setInput('');
    } catch (e) {
      logger.error('Error updating exclusion list:', e);
    }
  };

  const renderList = () => {
    return (
      <List>
        {exclusionList.map((item, index) => (
          <div key={item || index}>
            <ListItem
              secondaryAction={
                <IconButton edge='end' aria-label='delete' onClick={() => handleDelete(index)}>
                  <DeleteOutlinedIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={item}
                primaryTypographyProps={{
                  component: 'div',
                }}
              />
            </ListItem>
            <Divider component='li' />
          </div>
        ))}
      </List>
    );
  };

  return (
    <SettingCard
      title={t('translation_exclusion_list')}
      subtitle={t('manage_words_or_phrases_here_that_you_dont_want_to_be_translated')}
    >
      <Box
        sx={{
          gap: 24,
          width: 500,
        }}
      >
        <TextField
          fullWidth
          value={input || ''}
          onChange={e => setInput(e.target.value)}
          sx={{ margin: 0, paddingY: 3 }}
          error={inputExists}
          helperText={inputExists ? t('already_added') : ''}
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {input != '' && (
            <Button
              variant='contained'
              color='primary'
              onClick={handleSaveAndReset}
              disabled={inputExists}
            >
              Save
            </Button>
          )}
        </Box>

        {renderList()}
      </Box>
    </SettingCard>
  );
};
