import { OpertoTheme } from 'App/OpertoTheme';
import React from 'react';

export interface CompleteIconProps {
  fill?: string;
  width?: number;
  height?: number;
}

export const CompleteIcon = ({
  fill = OpertoTheme.statusColors.completed,
  width = 16,
  height = 16,
}: CompleteIconProps) => (
  <svg viewBox='0 0 14 14' width={width} height={height} fill='none'>
    <path
      d='M7.00016 0.333984C3.32016 0.333984 0.333496 3.32065 0.333496 7.00065C0.333496 10.6807 3.32016 13.6673 7.00016 13.6673C10.6802 13.6673 13.6668 10.6807 13.6668 7.00065C13.6668 3.32065 10.6802 0.333984 7.00016 0.333984ZM7.00016 12.334C4.06016 12.334 1.66683 9.94065 1.66683 7.00065C1.66683 4.06065 4.06016 1.66732 7.00016 1.66732C9.94016 1.66732 12.3335 4.06065 12.3335 7.00065C12.3335 9.94065 9.94016 12.334 7.00016 12.334ZM10.0602 4.05398L5.66683 8.44732L3.94016 6.72732L3.00016 7.66732L5.66683 10.334L11.0002 5.00065L10.0602 4.05398Z'
      fill={fill}
    />
  </svg>
);
