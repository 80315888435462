import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import { Box, IconButton, Stack, SxProps, Tooltip, tooltipClasses } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { Task } from '@operto/tasks-shared';
import { theme, useMedia } from '@operto/ui';
import CopilotInputIconDisabled from 'assets/img/icons/icn-copilot-input-disabled.svg';
import CopilotInputIcon from 'assets/img/icons/icn-copilot-input.svg';
import { companySelector, messengerSettingSelector } from 'company/state/companySelectors';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import TasksTable from 'Pages/Tasks/TasksTable';
import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { createEventEmmiter } from 'shared/event';
import { Channel as TChannel } from 'stream-chat';
import {
  Channel,
  MessageInput,
  MessageInputFlat,
  MessageList,
  MessageToSend,
  Thread,
  useChannelActionContext,
  useChatContext,
  useMessageInputContext,
  Window,
} from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { userSelector } from 'user/state/userSelectors';
import CustomChannelHeader from './CustomChannelHeader';
import { CustomMessage } from './CustomMessage';
import MessagePeopleListChatContainer from './MessagePeopleListChatContainer';
import { MessageFilterIndex } from './MessagingPage';
import CustomSystemMessage from './SystemMessage/CustomSystemMessage';
import TaskMessengerAddList from './TaskMessengerAddList';

type ChatEmmitterTopics = 'msgInputInit';

export const ChatMsgEmmitter = createEventEmmiter<ChatEmmitterTopics>();

interface IChatMessagesProps {
  setCurrentChannel: (channel: TChannel<DefaultStreamChatGenerics>) => void;
  handleNewChat: (onNewChat: boolean) => void;
  handleChannelOnClick: (channel: TChannel) => void;
  isNewChat: boolean;
  filterIndex: MessageFilterIndex;
  isMessengerEnabled: boolean;
  onCopilotButtonClick: () => void;
  onSendMessage?: (message: MessageToSend) => void;
  copilotButtonDisabled?: boolean;
  onTaskClick: (row: GridRowParams<Task>) => void;
  onNewTaskClick: () => void;
  onViewTaskClick?: (taskId: string) => void;
}

interface CustomMessageInputProps {
  isMessengerEnabled: boolean;
  onCopilotButtonClick: () => void;
  onSendMessage?: (message: MessageToSend) => void;
  copilotButtonDisabled?: boolean;
}

const RefInput = () => {
  const { textareaRef } = useMessageInputContext();

  useEffect(() => {
    ChatMsgEmmitter.emit('msgInputInit', textareaRef.current);
  }, [textareaRef]);

  return <MessageInputFlat />;
};

const CustomMessageInput = (props: CustomMessageInputProps) => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useAppFeatures();
  const featureCopilotEnabled = isFeatureEnabled('copilot') && !props.copilotButtonDisabled;

  const messengerSetting = useAppSelector(messengerSettingSelector());
  const { sendMessage } = useChannelActionContext();

  const isCopilotEnabled = messengerSetting.mp_instant_reply_enabled;

  const overrideSubmitHandler = (message: MessageToSend) => {
    sendMessage(message, { target_channel_type: 'sms' }); // or 'whatsapp' in the future
    props.onSendMessage && props.onSendMessage(message);
  };

  const handleCopilotClick = () => {
    if (isCopilotEnabled) {
      props.onCopilotButtonClick();
    }
  };

  if (!props.isMessengerEnabled) {
    return null;
  }

  return (
    <Stack sx={inputContainerStyles}>
      {featureCopilotEnabled && (
        <Tooltip
          title={isCopilotEnabled ? '' : t('instant_reply_disabled_message')}
          slotProps={tooltipSlotProps}
          placement='bottom'
        >
          <IconButton sx={{ padding: '0px' }} onClick={handleCopilotClick}>
            <Box
              style={{
                ...copilotIconStyles,
                ...(isCopilotEnabled ? copilotIconEnabledStyles : copilotIconDisabledStyles),
              }}
            >
              {isCopilotEnabled ? <CopilotInputIcon /> : <CopilotInputIconDisabled />}
            </Box>
          </IconButton>
        </Tooltip>
      )}

      <MessageInput
        grow
        maxRows={15}
        Input={RefInput}
        overrideSubmitHandler={overrideSubmitHandler}
        additionalTextareaProps={{
          style: {
            ...theme.typography['body-lg-400'],
            color: theme.palette.text.primary,
            opacity: '0.75',
          },
        }}
      />
    </Stack>
  );
};

CustomMessageInput.displayName = 'CustomMessageInput';

const MessageListContainer = ({
  type,
  activeTab,
  onClick,
}: {
  type: MessageFilterIndex;
  activeTab: number;
  onClick?: (row: GridRowParams<Task>) => void;
}) => {
  const { channel } = useChatContext();

  if (type === MessageFilterIndex.GUESTS && activeTab === 1) {
    const [, reservationId] = channel?.id?.split('-');
    return (
      <Box sx={containerStyles}>
        <TasksTable type='messenger' resID={reservationId} onTaskClick={onClick} />
      </Box>
    );
  }

  return <MessageList messageActions={[]} />;
};

const ChatMessages = ({
  setCurrentChannel,
  handleNewChat,
  handleChannelOnClick,
  isNewChat,
  filterIndex,
  isMessengerEnabled,
  onCopilotButtonClick,
  onSendMessage,
  copilotButtonDisabled,
  onTaskClick,
  onNewTaskClick,
  onViewTaskClick,
}: IChatMessagesProps) => {
  const { isFeatureEnabled } = useAppFeatures();
  const { isDesktop, isTablet, isMobile } = useMedia();
  const { channel } = useChatContext();
  const displayChat = (isDesktop || isTablet || isMobile) && channel;
  const [showChevronTabletIcon, setShowChevronTabletIcon] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const loggedInMember = useAppSelector(userSelector());
  const company = useAppSelector(companySelector());
  const isMembersTab = filterIndex === MessageFilterIndex.MEMBERS;
  const resID = !isMembersTab ? channel?.id?.split('-')[1] : null;
  const currentMemberIds = Object.keys(channel?.state?.members || {});

  const handleChevronClick = useCallback(() => {
    setCurrentChannel(undefined);
  }, [setCurrentChannel]);

  const renderChevronIcon = useCallback(() => {
    const handleChevronTabletClick = () => {
      setShowChevronTabletIcon(false);
      handleChevronClick();
    };

    return (
      <>
        {!isFeatureEnabled('hackfest', company.id) && showChevronTabletIcon && (
          <IconButton
            sx={{
              width: '48px',
              height: '48px',
            }}
            onClick={handleChevronTabletClick}
          >
            <ChevronRightOutlined
              sx={{
                width: '100%',
                height: '100%',
                borderRadius: 32,
                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
                background: 'white',
              }}
            />
          </IconButton>
        )}
      </>
    );
  }, [company.id, handleChevronClick, isFeatureEnabled, showChevronTabletIcon]);

  const handleTabClick = (displayTaskTab: number) => {
    setActiveTab(displayTaskTab);
  };

  const [showTaskAddList, setShowTaskAddList] = useState(false);
  const handleTaskAddPeopleClick = () => {
    setShowTaskAddList(true);
  };

  const handleTaskAddPeopleClose = () => {
    setShowTaskAddList(false);
  };

  useEffect(() => {
    if (channel && isTablet) {
      setShowChevronTabletIcon(true);
    }

    handleTaskAddPeopleClose();
  }, [channel, isTablet]);

  return (
    <Box
      id='chat-messages'
      sx={{
        display: 'flex',
        flex: '1 0 0',
      }}
    >
      {!isFeatureEnabled('hackfest', company.id) && showChevronTabletIcon && (
        <Box sx={{ position: 'absolute', top: 13, left: -25, zIndex: 'tooltip' }}>
          {renderChevronIcon()}
        </Box>
      )}

      {isNewChat ? (
        <MessagePeopleListChatContainer
          onNewChat={handleNewChat}
          peopleType={filterIndex}
          user={loggedInMember}
          lastClickedOnChannel={handleChannelOnClick}
        />
      ) : showTaskAddList ? (
        <TaskMessengerAddList
          currentMemberIds={currentMemberIds}
          onTaskAddPeopleClose={handleTaskAddPeopleClose}
        />
      ) : (
        <Box sx={{ display: displayChat ? 'flex' : 'none', flex: '1 0 0' }}>
          <Channel MessageSystem={CustomSystemMessage} Message={CustomMessage}>
            <Window>
              <CustomChannelHeader
                onChevronClick={handleChevronClick}
                showFavouriteIcon={filterIndex === MessageFilterIndex.GUESTS}
                showUnitName={filterIndex === MessageFilterIndex.GUESTS}
                isGuestTab={filterIndex === MessageFilterIndex.GUESTS}
                isTasksTab={filterIndex === MessageFilterIndex.TASKS}
                onTaskTabClick={handleTabClick}
                activeTab={activeTab}
                resID={resID}
                propertyName={channel?.data.property_name as string}
                onNewTaskClick={onNewTaskClick}
                onViewTaskClick={onViewTaskClick}
                onTaskAddPeopleClick={handleTaskAddPeopleClick}
              />

              <MessageListContainer
                activeTab={activeTab}
                type={filterIndex}
                onClick={onTaskClick}
              />

              {activeTab !== 1 && (
                <CustomMessageInput
                  isMessengerEnabled={isMessengerEnabled}
                  onCopilotButtonClick={onCopilotButtonClick}
                  onSendMessage={onSendMessage}
                  copilotButtonDisabled={copilotButtonDisabled}
                />
              )}
            </Window>
            <Thread />
          </Channel>
        </Box>
      )}
    </Box>
  );
};

ChatMessages.displayName = 'ChatMessages';

const containerStyles: SxProps = {
  margin: '24px 24px',
  borderRadius: '16px',
  boxShadow: `
  0px 1px 3px 0px #CACDDA80,
  0px 0px 1px 0px #BFC2D14D,
  0px 1px 1px 0px #9098B71A
`,
  backgroundColor: '#FFFFFF',
  overflow: 'auto',
  justifyContent: 'flex-start',
  display: 'inline-block',
  height: '100%',
};

export default ChatMessages;

const tooltipSlotProps = {
  popper: {
    sx: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
        marginTop: '0px',
      },
    },
  },
};

const inputContainerStyles: SxProps = {
  flexDirection: 'row',
  padding: '0px 16px 16px 16px',
  alignItems: 'flex-end',
  justifyContent: 'center',
  '& div[class^="str-chat__message-input"]': {
    padding: '0px',
  },
};

const copilotIconStyles: CSSProperties = {
  height: '48px',
  width: '48px',
  borderRadius: '50px',
  padding: '8px',
};

const copilotIconEnabledStyles: CSSProperties = {
  background: 'linear-gradient(146deg, #155EFF -12.23%, #E57CC6 118.35%)',
};

const copilotIconDisabledStyles: CSSProperties = {
  background: 'rgba(0, 0, 0, 0.12)',
};
