import React from 'react';
import { MessageContextValue, MessageSimple, useMessageContext } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

export const CustomMessage = (props: Partial<MessageContextValue<DefaultStreamChatGenerics>>) => {
  const { isMyMessage, message } = useMessageContext();

  const isRightSide = () => {
    // OPN1-23247: all operator's messages in guest channels should be right side
    const isGuestChannel = ['members', 'tid'].find(cid => message.cid?.includes(cid)) === undefined;
    return isGuestChannel && message.user?.id.startsWith('mid');
  };

  return <MessageSimple {...props} isMyMessage={() => isMyMessage() || isRightSide()} />;
};
