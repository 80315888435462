import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { FormTextFieldRegexEmail } from 'ui-library/Components/input/FormTextField';

export interface ClearableEmailInputProps {
  emailValue: string;
  onEmailChange: (value: string) => void;
  onEmailError: (value: boolean) => void;
}

export const ClearableEmailInput = ({
  emailValue,
  onEmailChange,
  onEmailError: onEmailError,
}: ClearableEmailInputProps): ReactElement => {
  const { t } = useTranslation();
  const isValid = (email: string): boolean => {
    return !!email.match(FormTextFieldRegexEmail);
  };
  const [email, setEmail] = useState(emailValue || '');
  const [error, setError] = useState(!isValid(email));
  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (onEmailError) {
      onEmailError(error);
    }
  }, [error, onEmailError]);

  const handleChange = (event: { target: { value: string } }) => {
    const value = event.target.value;
    setEmail(value);
    setError(!isValid(value));
    onEmailChange(value);
  };

  const handleClear = () => {
    setEmail('');
    onEmailChange('');
    setError(true);
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };

  return (
    <TextField
      fullWidth
      label={t('email')}
      value={email}
      onChange={handleChange}
      placeholder={t('email')}
      helperText={!email ? t('email_is_required') : error ? t('email_is_invalid') : undefined}
      error={error}
      inputRef={textFieldRef}
      InputLabelProps={{ shrink: true }}
      sx={{ margin: '16px 0 8px 0' }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end' style={{ position: 'absolute', right: '8px' }}>
            <IconButton aria-label='clear input' onClick={handleClear} edge='end' size='small'>
              <CloseOutlined />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
