import { MessageMetadataType } from '@operto/messaging-service-shared';
import { Task } from '@operto/tasks-shared';
import React from 'react';
import { EventComponent, EventComponentProps } from 'stream-chat-react';
import { AssigneeSystemMessage } from './AssigneeSystemMessage';
import { ChannelMembersSystemMessage } from './ChannelMembersSystemMessage';
import { DescriptionSystemMessage } from './DescriptionSystemMessage';
import { GuestSystemMessage } from './GuestSystemMessage';
import { PrioritySystemMessage } from './PrioritySystemMessage';

const CustomSystemMessage = (props: EventComponentProps) => {
  const { message } = props;
  const metadata = message?.metadata as MessageMetadataType;

  const internalMessageType = metadata?.internalType;
  const task: Task = metadata?.task;

  switch (internalMessageType) {
    case 'task-priority-updated':
      return <PrioritySystemMessage task={task} message={message} />;
    case 'task-description-updated':
      return <DescriptionSystemMessage task={task} message={message} />;
    case 'task-assignee-updated':
      return <AssigneeSystemMessage task={task} message={message} />;
    case 'task-updated':
    case 'task-created':
      return <GuestSystemMessage task={task} message={message} />;
    case 'channel-members-updated':
      return <ChannelMembersSystemMessage message={message} />;
    default:
      return <EventComponent {...props} />;
  }
};

export default CustomSystemMessage;
