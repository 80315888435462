//@ts-nocheck

import { NotifiableError } from '@bugsnag/js';
import { OpertoLogger } from 'Logger/logger';
import * as api from 'api/companyAPI';
import {
  appendCompanyIdByToken,
  appendCompanyNameByToken,
  checkForTokenExpiry,
} from 'auth/LinkedAccountsHelper';
import { AxiosResponse } from 'axios';
import { ICompany, MessengerSetting, ProviderTypes, CompanyMetaData } from 'company/companyType';
import { toTitleCase } from 'helper/helper';
import { IPropertyAlert } from 'propertyAlerts/propertyAlertType';
import { toggleSnackbar } from 'redux/actions/ui';
import { logout } from 'redux/actions/users';
import { AppDispatch } from 'redux/store';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { Actions } from '../../types/actions';

export const getCompanyInformation = (currentCompany?: ICompany) => (dispatch: AppDispatch) =>
  api
    .getCompany()
    .then((data: AxiosResponse) => {
      const company = data.data.data;
      dispatch({
        type: Actions.storeCompanyInfoFromServer,
        company,
      });

      // ----------------------------------------
      // store in linked accounts localstorage
      const currentToken = localStorage.getItem('mpAuth');
      appendCompanyNameByToken(JSON.parse(currentToken), company.name);
      appendCompanyIdByToken(JSON.parse(currentToken), company.id);
      checkForTokenExpiry();
      // ----------------------------------------

      return company;
    })
    .catch((err: NotifiableError) => {
      if (err && err.response && err.response.status === 401) {
        if (currentCompany && currentCompany.allMembersMigratedToCognito) {
          localStorage.setItem('mpAuth', '');
          window.location.href = process.env.REACT_APP_AMPLIFY_APP_AUTH_URL;
        } else {
          // redirect to login page and display an error message to let customers know the session is expired
          dispatch(logout('?error=timeout'));
        }
      }
    });

export const updateCompany = (company: ICompany) => (dispatch: AppDispatch) => {
  const companyObj = {
    address: company.address,
    logo_url: company.logoUrl,
    name: company.name,
    phone_number: company.phoneNumber,
    website_url: company.websiteUrl,
    email: company.email,
    test_account_enabled: company.testAccountEnabled,
    dashboard_widget_enabled: false,
    dashboard_portal_enabled: false,
    member_pincode_format: company.accessCodeFormat,
    access_code_setting: company.accessCodeSetting,
    guest_portal_status: company.guestPortalStatus,
    verification_provider_type: company.verification_provider_type,
    smw_enabled: company.smwEnabled,
  };
  return new Promise((resolve, reject) => {
    api
      .updateCompany(companyObj)
      .then((data: AxiosResponse) => {
        const company = data.data.data;
        dispatch({
          type: Actions.storeCompanyInfoFromServer,
          company,
        });
        resolve(company);
        return true;
      })
      .catch((err: NotifiableError) => {
        reject(err);
        OpertoLogger.Log(err);
      });
  });
};

export const getActiveAlerts = () => async (dispatch: AppDispatch) => {
  const activeAlerts = await api.getActiveAlerts();
  const alertMap: Map<number, IPropertyAlert[]> = new Map();
  activeAlerts.data.forEach(propertyAlerts => {
    alertMap.set(propertyAlerts.property_id, propertyAlerts.alerts);
  });
  const propertyIds = [...alertMap.keys()];
  const activeAlertsDict = propertyIds.reduce<{
    [propertyId: number]: IPropertyAlert[];
  }>((map, propertyId) => {
    map[propertyId] = alertMap.get(propertyId) as IPropertyAlert[];
    return map;
  }, {});

  dispatch({
    type: Actions.storeCompanyActiveAlerts,
    propertyIds,
    activeAlertsDict,
  });
};

export const getMessengerSetting = () => (dispatch: AppDispatch) => {
  api
    .getMessengerSetting()
    .then((data: AxiosResponse) => {
      const messengerSetting = data.data.data;
      dispatch({
        type: Actions.storeMessengerSetting,
        messengerSetting,
      });
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};

export const updateMessengerSetting = (setting: MessengerSetting) => (dispatch: AppDispatch) => {
  api
    .updateMessengerSetting(setting)
    .then((data: AxiosResponse) => {
      const { message } = data.data;
      dispatch({
        type: Actions.storeMessengerSetting,
        messengerSetting: setting,
      });

      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message,
          variant: SnackbarVariant.SUCCESS,
        }),
      );
    })
    .catch((err: NotifiableError) => {
      // if the status is 400, display warning pop-up
      if (err.message.includes('400')) {
        dispatch({ type: Actions.warnToDisableSmartButtonTriggers });
      } else {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: `ERROR: ${err.message}`,
            variant: SnackbarVariant.ERROR,
          }),
        );
      }

      const metadata = {
        ...setting,
      };
      OpertoLogger.Log(err);
      OpertoLogger.LeaveBreadCrumb('update messenger setting', metadata, 'state');
    });
};

export const setCompanyVerificationProvider =
  (company_id: number, providers: Array<ProviderTypes>) => (dispatch: AppDispatch) =>
    api
      .setCompanyVerification(company_id, {
        verification_providers: providers,
      })
      .then(() => {
        dispatch(getCompanyInformation()).then(updatedCompany => {
          const message = !updatedCompany?.verification_provider_type
            ? 'Guest Verification has been disabled'
            : `${toTitleCase(
                updatedCompany?.verification_provider_type,
              )} Verification has been enabled`;

          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message,
              variant: SnackbarVariant.SUCCESS,
            }),
          );
        });
      })
      .catch(() => {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: 'Error while updating the verification provider',
            variant: SnackbarVariant.ERROR,
          }),
        );
      });

export const getMetaData = () => (dispatch: AppDispatch) => {
  api
    .getCompanyMetaData()
    .then((data: AxiosResponse) => {
      const metaData = data.data.data;
      dispatch({
        type: Actions.storeCompanyMetaData,
        metaData,
      });
    })
    .catch((err: NotifiableError) => {
      OpertoLogger.Log(err);
    });
};

export const updateCompanyMetaData = (metaData: CompanyMetaData) => (dispatch: AppDispatch) => {
  api
    .updateCompanyMetaData(metaData)
    .then(() => {
      dispatch({
        type: Actions.storeCompanyMetaData,
        metaData: metaData,
      });
    })
    .catch((err: NotifiableError) => {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: `ERROR: ${err.message}`,
          variant: SnackbarVariant.ERROR,
        }),
      );

      OpertoLogger.Log(err);
    });
};
