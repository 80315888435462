import { Badge, Box, Link, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { AlertMessage } from '@operto/notifications-shared';
import { format, parseISO } from 'date-fns';
import useNotifications from 'hooks/useNotifications';
import useTranslation from 'hooks/useTranslation';
import MemberProfile from 'member/memberProfileGetter';
import { IMember } from 'member/memberType';
import getProperty from 'property/state/propertySelectors';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

export interface TaskAlertListItemProps {
  assigneeMember: IMember | null;
  assignedByMember: IMember | null;
  alert: AlertMessage;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | undefined>>;
}

const TaskAlertListItem = ({
  assigneeMember,
  assignedByMember,
  alert,
  setAnchorEl,
}: TaskAlertListItemProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateAlert } = useNotifications();
  const property = useAppSelector(getProperty(alert.meta.unitId));
  const opertatorName = assignedByMember ? assignedByMember.name : '';
  const assigneeName = assigneeMember ? assigneeMember.name : '';
  const date = parseISO(alert.createdAt);
  const formattedTime = format(date, 'h:mm a');

  const taskCreated = alert.meta.type == 'created';
  const taskAssigned = alert.meta.type == 'assigned';
  const taskUpdated = alert.meta.type == 'updated';

  const taskCreatedText = (
    <>{t('task_created', { operator_name: opertatorName, alert_title: <b>{alert.title}</b> })}</>
  );
  const taskAssignedText = (
    <>
      {t('task_assigned', {
        operator_name: opertatorName,
        alert_title: <b>{alert.title}</b>,
        assignee_name: assigneeName,
      })}
    </>
  );
  const taskUpdatedText = (
    <>
      {t('task_updated', {
        operator_name: opertatorName,
        alert_title: <b>{alert.title}</b>,
        task_status: (
          <span style={{ color: alert.meta.taskStatus === 'canceled' ? '#B3261E' : 'inherit' }}>
            {alert.meta.taskStatus}
          </span>
        ),
      })}
    </>
  );

  const title = taskCreated
    ? taskCreatedText
    : taskAssigned
    ? taskAssignedText
    : taskUpdated
    ? taskUpdatedText
    : '';

  const handleRedirectToTask = async () => {
    await updateAlert({ id: alert.id, read: true });
    navigate(`tasks`, { state: { showAddTaskPanel: true, editTaskId: alert.meta.taskId } });
    setAnchorEl(undefined);
  };

  return (
    <ListItem
      alignItems='flex-start'
      sx={{
        display: 'flex',
        position: 'relative',
        padding: '12px 16px',
        '&:hover': {
          bgcolor: 'grey.200',
        },
      }}
    >
      <ListItemAvatar>
        <MemberProfile size='medium' memberData={taskUpdated ? assignedByMember : assigneeMember} />
      </ListItemAvatar>
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box sx={{ gap: 0, display: 'flex', flexDirection: 'column' }}>
          <Link
            onClick={handleRedirectToTask}
            sx={{
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.87)',
              textDecoration: 'none',
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {title}
          </Link>
          <Typography variant='body2' sx={{ color: 'rgba(0, 0, 0, 0.60);' }}>
            {property ? `${property.name}, ` : ''}
            {assigneeMember ? `Assignee ${assigneeMember.name}` : 'Unassigned'}
          </Typography>
          <Typography variant='caption' sx={{ color: 'rgba(0, 0, 0, 0.60);' }}>
            {formattedTime}
          </Typography>
        </Box>
        <Box sx={{ marginLeft: 'auto', display: 'flex', padding: '12px 0 0 8px' }}>
          <Badge color='primary' variant='dot' invisible={alert.readAt ? true : false} />
        </Box>
      </Box>
    </ListItem>
  );
};

export default TaskAlertListItem;
