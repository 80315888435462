import { NotFound } from 'Common/NotFound';
import Account from 'Pages/Account/AccountPage';
import UpsellSettings from 'Pages/Account/UpsellSettings';
import AutomatePage from 'Pages/Automate/AutomatePage';
import AutomateTable from 'Pages/Automate/AutomateTable';
import AutomateWorkflowPage from 'Pages/Automate/AutomateWorkflow/AutomateWorkflowPage';
import CommunicationsEmailTemplatePage from 'Pages/Communications/CommunicationsEmailTemplate/CommunicationsEmailTemplatePage';
import CommunicationsPage from 'Pages/Communications/CommunicationsPage';
import CommunicationsSMSTemplatePage from 'Pages/Communications/CommunicationsSMSTemplate/CommunicationsSMSTemplatePage';
import CommunicationsTable from 'Pages/Communications/CommunicationsTable';
import DashboardPage from 'Pages/Dashboard/DashboardPage';
import DeliveryLogsPage from 'Pages/DeliveryLog/DeliveryLogsPage';
import DeliveryLogsTable from 'Pages/DeliveryLog/DeliveryLogsTable';
import ExpansionPage from 'Pages/Expansion/ExpansionPage';
import { GroupsPage } from 'Pages/Groups';
import { GroupSplitView } from 'Pages/Groups/Details/GroupSplitView';
import { GroupsGrid } from 'Pages/Groups/GroupsGrid';
import GuestPortal from 'Pages/GuestPortal';
import AssignedUnitsTabPage from 'Pages/GuestPortal/AssignedUnits';
import GuidesFeaturedPage from 'Pages/GuestPortal/Featured/GuidesFeaturedPage';
import GlobalTabPage from 'Pages/GuestPortal/Global';
import { FilterType, GuidesTabPage } from 'Pages/GuestPortal/Guides';
import SettingsTabPage from 'Pages/GuestPortal/Settings/';
import GuestsPage from 'Pages/Guests/GuestsPage';
import HelpCenterPage from 'Pages/HelpCenter/HelpCenterPage';
import Home from 'Pages/Home/Home';
import Cloudbeds from 'Pages/Integrations/Cloudbeds/Cloudbeds';
import IntegrationsPage from 'Pages/Integrations/IntegrationsPage';
import { StripeRedirectPage } from 'Pages/Integrations/StripeRedirectPage';
import LocksPage from 'Pages/Locks/LocksPage';
import OrderItemsPage from 'Pages/Marketplace/OrderItemsPage';
import UpsellItemsPage from 'Pages/Marketplace/UpsellItemsPage';
import UpsellPage from 'Pages/Marketplace/UpsellPage';
import MessagingPage from 'Pages/Messaging/MessagingPage';
import MobileKey from 'Pages/MobileKeyTest/MobileKey';
import PaymentsPage from 'Pages/Payments/PaymentsPage';
import MembersPage from 'Pages/People/MembersPage';
import SaltoGroupsPage from 'Pages/SaltoGroups/SaltoGroupsPage';
import SuccessCenter from 'Pages/SuccessCenter/SuccessCenter';
import TasksPage from 'Pages/Tasks/TasksPage';
import TasksTable from 'Pages/Tasks/TasksTable';
import TransactionsPage from 'Pages/Transactions/TransactionsPage';
import EnergyManagerPage from 'Pages/Unit/EnergyManagerPage';
import FavouritesPage from 'Pages/Unit/FavouritesPage';
import CustomAddress from 'Pages/Unit/GuestPortal/CustomAddress';
import CustomHost from 'Pages/Unit/GuestPortal/CustomHost';
import CustomText from 'Pages/Unit/GuestPortal/CustomText';
import UnitDetails from 'Pages/Unit/GuestPortal/UnitDetails';
import UnitGuidesContainer from 'Pages/Unit/GuestPortal/UnitGuidesContainer';
import RoomTypeActivitiesPage from 'Pages/Unit/RoomTypeActivitiesPage';
import RoomTypeUnitPage from 'Pages/Unit/RoomTypeUnitPage';
import RoomTypesPage from 'Pages/Unit/RoomTypesPage';
import AdditionalLocksContainer from 'Pages/Unit/TabContainers/AdditionalLocksContainer';
import AssociateEntriesContainer from 'Pages/Unit/TabContainers/AssociateEntriesContainer';
import GuestsContainer from 'Pages/Unit/TabContainers/GuestsContainer';
import MembersContainer from 'Pages/Unit/TabContainers/MembersContainer';
import UnitAccessContainer from 'Pages/Unit/UnitAccessContainer';
import UnitDashboardContainer from 'Pages/Unit/UnitDashboardContainer';
import UnitDevicesPage from 'Pages/Unit/UnitDevicesPage';
import UnitPage from 'Pages/Unit/UnitPage';
import UnitsPage from 'Pages/Unit/UnitsPage';
import Variables from 'Pages/Variables';
import CustomVariablePage from 'Pages/Variables/Custom/CustomVariable';
import SystemVariablePage from 'Pages/Variables/System/SystemVariable';
import UnitCodesContainer from 'code/UnitCodesContainer';
import { companySelector } from 'company/state/companySelectors';
import HumidityCard from 'device/SmartCards/HumidityCard';
import NetatmoNoiseCard from 'device/SmartCards/NetatmoNoiseCard';
import NoiseAwareCard from 'device/SmartCards/NoiseAwareCard';
import OccupancyCard from 'device/SmartCards/OccupancyCard';
import TemperatureCard from 'device/SmartCards/TemperatureCard';
import { useAuthorizationAccess } from 'hooks/useAuthorizationAccess';
import { IntegrationsApiSystem } from 'integrations/integrationsTypes';
import { findEnabledIntegrationsApiSystemSelector } from 'integrations/state/integrationsSelectors';
import { useAppFeatures } from 'lib/app-features';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { isAdmin } from 'redux/actions/ui';
import { useAppSelector } from 'redux/hooks';

const CombinedNoiseCard = () => {
  return (
    <>
      <NetatmoNoiseCard hideFooter />
      <NoiseAwareCard hideFooter />
    </>
  );
};

const ProtectedRoute = ({ allowed, component }: { allowed: boolean; component: JSX.Element }) => {
  if (allowed) {
    return component;
  }

  return <Navigate to='/404-page-not-found' />;
};

export const AppRoutes = () => {
  const isPaymentSystemEnabled = useAppSelector(
    findEnabledIntegrationsApiSystemSelector(IntegrationsApiSystem.PAYMENT),
  );
  const company = useAppSelector(companySelector());
  const { isFeatureEnabled, isLoading, isUninitialized } = useAppFeatures();
  const { canAccessAutomate, canAccessCommunications } = useAuthorizationAccess();

  if (isUninitialized || isLoading) {
    return null;
  }

  return (
    <Routes>
      <Route path='/' element={<Home />}>
        <Route index element={<DashboardPage />} />

        <Route path='messenger' element={<MessagingPage />}>
          <Route path='members' element={<MessagingPage />} />
          <Route path=':channelId' element={<MessagingPage />} />
        </Route>

        <Route path='offers'>
          <Route index element={<UpsellItemsPage />} />
          <Route path=':id' element={<UpsellPage />}>
            <Route index element={<UpsellPage />} />
          </Route>
        </Route>

        <Route path='orders'>
          <Route index element={<OrderItemsPage />} />
        </Route>

        <Route
          path='transactions'
          element={
            <ProtectedRoute
              allowed={isFeatureEnabled('transactionsPage', company.id)}
              component={<TransactionsPage />}
            />
          }
        ></Route>

        <Route path='units'>
          <Route index element={<UnitsPage />} />
          <Route path=':propertyId' element={<UnitPage />}>
            <Route index element={<UnitDashboardContainer />} />

            <Route path='guests' element={<GuestsContainer />}>
              <Route path=':type' element={<GuestsContainer />} />
            </Route>

            <Route path='access' element={<UnitAccessContainer />}>
              <Route index element={<UnitCodesContainer />} />
              <Route path='unit-code' element={<UnitCodesContainer />} />
              <Route path='unit-code/:type' element={<UnitCodesContainer />} />
              <Route path='associate-entries' element={<AssociateEntriesContainer />} />
              <Route path='associate-entries/:type' element={<AssociateEntriesContainer />} />
              <Route path='additional-devices' element={<AdditionalLocksContainer />} />
            </Route>

            <Route path='devices' element={<UnitDevicesPage />}>
              <Route path='noise' element={<CombinedNoiseCard />} />
              <Route path='temperature' element={<TemperatureCard hideFooter />} />
              <Route path='occupancy' element={<OccupancyCard hideFooter />} />
              <Route path='humidity' element={<HumidityCard hideFooter />} />
            </Route>

            <Route path='energy' element={<EnergyManagerPage />} />
            <Route path='members/:type' element={<MembersContainer />} />

            <Route path='guest-portal'>
              <Route path='unit-details' element={<UnitDetails />} />
              <Route path='custom-host' element={<CustomHost />} />
              <Route path='custom-address' element={<CustomAddress />} />
              <Route path='custom-text' element={<CustomText />} />
              <Route path='guides' element={<UnitGuidesContainer />}>
                <Route index element={<GuidesTabPage filter={FilterType.INDEX} />} />
                <Route path=':guideId' element={<GuidesTabPage filter={FilterType.INDEX} />} />
                <Route
                  path=':guideId/pages'
                  element={<GuidesTabPage filter={FilterType.PAGES} />}
                />
                <Route
                  path=':guideId/pages/:pageId'
                  element={<GuidesTabPage filter={FilterType.PAGES} />}
                />
                <Route
                  path=':guideId/categories'
                  element={<GuidesTabPage filter={FilterType.CATEGORIES} />}
                />
              </Route>
            </Route>
          </Route>
          <Route path='favorites' element={<FavouritesPage />} />
        </Route>

        <Route path='room-types'>
          <Route index element={<RoomTypesPage />} />
          <Route path=':roomType/:propertyId' element={<RoomTypeUnitPage />}>
            <Route index element={<GuestsContainer />} />
            <Route path='guests' element={<GuestsContainer />} />
            <Route path='activities' element={<RoomTypeActivitiesPage />} />
            <Route path='guest-portal'>
              <Route path='unit-details' element={<UnitDetails />} />
              <Route path='custom-host' element={<CustomHost />} />
              <Route path='custom-address' element={<CustomAddress />} />
              <Route path='custom-text' element={<CustomText />} />
              <Route path='guides' element={<UnitGuidesContainer />}>
                <Route index element={<GuidesTabPage filter={FilterType.INDEX} />} />
                <Route path=':guideId'>
                  <Route path='pages' element={<GuidesTabPage filter={FilterType.PAGES} />} />
                  <Route
                    path='categories'
                    element={<GuidesTabPage filter={FilterType.CATEGORIES} />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path='members/:type' element={<MembersContainer />} />
          </Route>
        </Route>

        <Route path='members' element={<MembersPage />}>
          <Route path='add' element={<MembersPage />} />
          <Route path=':memberId/edit' element={<MembersPage />} />
        </Route>

        <Route
          path='tasks'
          element={
            <ProtectedRoute
              allowed={isFeatureEnabled('tasks', company.id)}
              component={<TasksPage />}
            />
          }
        >
          <Route index element={<TasksTable />} />
        </Route>

        <Route
          path='locks'
          element={
            <ProtectedRoute
              allowed={isAdmin() && isFeatureEnabled('locks', company.id)}
              component={<LocksPage />}
            />
          }
        />

        <Route
          path='groups'
          element={
            <ProtectedRoute allowed={isFeatureEnabled('groups')} component={<GroupsPage />} />
          }
        >
          <Route index element={<GroupsGrid />} />
          <Route path='add' element={<GroupsGrid />} />
          <Route path=':groupId' element={<GroupSplitView />} />
          <Route path=':groupId/members' element={<GroupSplitView />} />
          <Route path=':groupId/units' element={<GroupSplitView />} />
        </Route>

        <Route path='saltogroups' element={<SaltoGroupsPage />}>
          <Route path='add' element={<SaltoGroupsPage />} />
          <Route path=':locationId/edit' element={<SaltoGroupsPage />} />
        </Route>

        <Route path='guests' element={<GuestsPage />}>
          <Route path=':type' element={<GuestsPage />} />
        </Route>

        <Route
          path='deliverylogs'
          element={
            <ProtectedRoute
              allowed={
                isAdmin() ||
                (isFeatureEnabled('communications', company.id) &&
                  isFeatureEnabled('deliveryLogs', company.id))
              }
              component={<DeliveryLogsPage />}
            />
          }
        >
          <Route index element={<DeliveryLogsTable />} />
        </Route>

        <Route
          path='communications'
          element={
            <ProtectedRoute
              allowed={
                (isAdmin() || isFeatureEnabled('communications', company.id)) &&
                canAccessCommunications()
              }
              component={<CommunicationsPage />}
            />
          }
        >
          <Route index element={<CommunicationsTable />} />
          <Route path='create/sms' element={<CommunicationsSMSTemplatePage />} />
          <Route path='create/email/:builderType?' element={<CommunicationsEmailTemplatePage />} />
          <Route
            path='create/sms/:templateId/:language'
            element={<CommunicationsSMSTemplatePage />}
          />
          <Route
            path='edit/sms/:templateId/:language?'
            element={<CommunicationsSMSTemplatePage />}
          />
          <Route
            path='create/email/:templateId/:language?'
            element={<CommunicationsEmailTemplatePage />}
          />
          <Route
            path='edit/email/:templateId/:language?'
            element={<CommunicationsEmailTemplatePage />}
          />
        </Route>

        <Route
          path='automate'
          element={
            <ProtectedRoute
              allowed={
                (isAdmin() || isFeatureEnabled('communications', company.id)) && canAccessAutomate()
              }
              component={<AutomatePage />}
            />
          }
        >
          <Route index element={<AutomateTable />} />
          <Route path='create' element={<AutomateWorkflowPage />} />
          <Route path='edit/:workflowId' element={<AutomateWorkflowPage />} />
        </Route>

        <Route path='guest-portal' element={<GuestPortal />}>
          <Route index element={<AssignedUnitsTabPage />} />
          <Route path='content' element={<GlobalTabPage active />}>
            <Route path='branding' element={<GlobalTabPage active />} />
            <Route path='address' element={<GlobalTabPage active />} />
            <Route path='host-details' element={<GlobalTabPage active />} />
            <Route path='global-text' element={<GlobalTabPage active />} />
            <Route path='translation' element={<GlobalTabPage active />} />
          </Route>

          <Route path='guides'>
            <Route index element={<GuidesTabPage />} />
            <Route path=':guideId' element={<GuidesTabPage />} />
            <Route path=':guideId/pages' element={<GuidesTabPage filter={FilterType.PAGES} />} />
            <Route
              path=':guideId/pages/:pageId'
              element={<GuidesTabPage filter={FilterType.PAGES} />}
            />
            <Route
              path=':guideId/categories'
              element={<GuidesTabPage filter={FilterType.CATEGORIES} />}
            />
            <Route
              path=':guideId/categories/:categoryId'
              element={<GuidesTabPage filter={FilterType.CATEGORIES} />}
            />
            <Route
              path=':guideId/ordering'
              element={<GuidesTabPage filter={FilterType.ORDERING} />}
            />
          </Route>

          <Route path='featured' element={<GuidesFeaturedPage />}>
            <Route path='guides/:guideId' element={<GuidesFeaturedPage />} />
            <Route path='guides/:guideId/categories/:categoryId' element={<GuidesFeaturedPage />} />
            <Route
              path='guides/:guideId/categories/:categoryId/pages/:pageId'
              element={<GuidesFeaturedPage />}
            />
          </Route>

          <Route path='settings' element={<SettingsTabPage active />} />
          <Route path='none' element={<GlobalTabPage active={false} />} />
        </Route>

        <Route
          path='payments'
          element={<ProtectedRoute allowed={isPaymentSystemEnabled} component={<PaymentsPage />} />}
        />

        <Route path='settings'>
          <Route path='account' element={<Account />} />
          <Route path='integrations'>
            <Route index element={<IntegrationsPage />} />
            <Route path='stripe' element={<StripeRedirectPage />} />
          </Route>
          <Route path='variables' element={<Variables />}>
            <Route path='system' element={<SystemVariablePage />} />
            <Route path='custom' element={<CustomVariablePage />} />
          </Route>
          <Route path='upsells' element={<UpsellSettings />} />
        </Route>

        <Route path='/help-center' element={<HelpCenterPage />} />
        <Route path='/expansion' element={<ExpansionPage />} />
        <Route path='/mobilekey/:resId/:lastName' element={<MobileKey />} />
        <Route path='/cloud-beds' element={<Cloudbeds />} />
        <Route path='/success-center' element={<SuccessCenter />} />
      </Route>

      <Route path='/*' element={<NotFound />} />
    </Routes>
  );
};
