import { Stack, SxProps } from '@mui/material';
import React from 'react';

interface BaseSystemMessageProps {
  date: string;
  content: React.ReactNode;
  customStyles?: SxProps;
}

export const BaseSystemMessage = ({ date, content, customStyles }: BaseSystemMessageProps) => {
  const defaultStyles: SxProps = {
    backgroundColor: '#f4f4f4',
    padding: '8px',
    borderRadius: '8px',
    margin: '8px auto',
    width: 'fit-content',
    ...customStyles,
  };

  return (
    <Stack sx={defaultStyles} className='str-chat__message--system' data-testid='message-system'>
      <Stack className='str-chat__message--system__date'>
        <strong>{date}</strong>
      </Stack>
      <Stack className='str-chat__message--system__text'>
        <Stack className='str-chat__message--system__line' />
        {content}
        <Stack className='str-chat__message--system__line' />
      </Stack>
    </Stack>
  );
};
