import { DefaultGenerics, FormatMessageResponse } from 'stream-chat';

/**
 * Formats a given conversation into a human-readable text blob.
 *
 * The input is expected to be an array of message objects (conversation),
 * where each message contains user information and text.
 *
 * Depending on the user.id prefix:
 *   - If it starts with 'mid', we consider it an Operator message.
 *   - If it starts with 'rid', we consider it a Guest message.
 *
 * The output is a single string with each message prefixed by either:
 *   "- Operator: " or "- Guest: "
 *
 * @param conversation - An array of message objects containing user and text data.
 * @returns A formatted string representing the conversation.
 */
export const formatConversation = (
  conversation: FormatMessageResponse<DefaultGenerics>[],
): string => {
  // If the input is not an array, return an empty string.
  if (!Array.isArray(conversation)) return '';

  // Initialize an array to hold each formatted line of text.
  const outputLines: string[] = [];

  // Iterate through each message in the conversation.
  conversation.forEach(msg => {
    // Extract user ID and text from the message
    const userId = msg.user && msg.user.id ? msg.user.id : '';
    const text = msg.text || '';

    // Check the prefix of the userId to determine who the sender is.
    if (userId.startsWith('mid')) {
      // 'mid' prefix indicates an Operator message.
      outputLines.push(`- Operator: ${text}`);
    } else if (userId.startsWith('rid')) {
      // 'rid' prefix indicates a Guest message.
      outputLines.push(`- Guest: ${text}`);
    }
  });

  // Join all formatted lines into a single string separated by newlines.
  return outputLines.join('\n');
};
