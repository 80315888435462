import {
  AttachMoneyOutlined,
  BedOutlined,
  BuildOutlined,
  CleaningServicesOutlined,
  DirectionsCarOutlined,
  MoreHorizOutlined,
  TableRestaurantOutlined,
} from '@mui/icons-material';
import { Box, SxProps, Typography } from '@mui/material';
import { Priority, Type } from '@operto/tasks-shared';
import { OpertoTheme } from 'App/OpertoTheme';
import React from 'react';
import { TaskPriorityStyles } from './TaskPriorityStyles';
import { TaskTypeStyles } from './TaskTypeStyles';

const renderIcon = (type: string) => {
  switch (type) {
    case 'cleaning':
    case 'housekeeping':
      return <CleaningServicesOutlined sx={typeIconStyles} />;
    case 'accounting':
      return <AttachMoneyOutlined sx={typeIconStyles} />;
    case 'front_desk':
      return <TableRestaurantOutlined sx={typeIconStyles} />;
    case 'valet':
      return <DirectionsCarOutlined sx={typeIconStyles} />;
    case 'maintenance':
      return <BuildOutlined sx={typeIconStyles} />;
    case 'room_service':
      return <BedOutlined sx={typeIconStyles} />;
    case 'other':
    default:
      return <MoreHorizOutlined sx={typeIconStyles} />;
  }
};

export const Tags = ({ type, priority }: { type: string; priority: string }) => {
  const styledType = TaskTypeStyles(type as Type);
  const styledPriority = TaskPriorityStyles(priority as Priority);

  return (
    <>
      {type && (
        <Box
          sx={{
            backgroundColor: styledType?.background || OpertoTheme.tagsColors.backgroundDefault,
            ...TagsContainerStyles,
          }}
        >
          {styledType?.value && renderIcon(styledType.value)}
          <Typography
            sx={{
              color: styledType?.text || OpertoTheme.tagsColors.textDefault,
              ...TagsTextStyles,
            }}
          >
            {type}
          </Typography>
        </Box>
      )}
      {priority && (
        <Box
          sx={{
            backgroundColor: styledPriority?.background || OpertoTheme.tagsColors.backgroundDefault,
            ...TagsContainerStyles,
          }}
        >
          <Typography
            sx={{
              color: styledPriority?.text || OpertoTheme.tagsColors.textDefault,
              ...TagsTextStyles,
            }}
          >
            {priority}
          </Typography>
        </Box>
      )}
    </>
  );
};

const TagsContainerStyles: SxProps = {
  borderRadius: 1,
  display: 'flex',
  flexDirection: 'row',
  padding: '2px 6px',
  alignItems: 'center',
};

const TagsTextStyles: SxProps = {
  textTransform: 'capitalize',
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 500,
  fontSize: '11px',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const typeIconStyles: SxProps = {
  fontSize: 'inherit',
  mr: '2px',
};
