import { Box } from '@mui/material';
import { getCompanyInformation } from 'company/state/companyAction';
import { companySelector } from 'company/state/companySelectors';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import { LockFilterType } from 'lock/lockType';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdConnectWebviewAndConnectWebviewIdConnectedAccountIdQuery,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdSeamOnboardingStatusQuery,
  usePostAccessCompatibilityOnboardingGenerateLinkMutation,
  usePostAccessCompatibilityOnboardSeamAccountMutation,
} from 'services/novaApi';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import LocksTable from './LocksTable';

const boxCenteredStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: '20px',
  marginTop: '-100px',
};

const iframeStyle = {
  borderRadius: '28px',
  border: 'none',
  minHeight: '600px',
  height: '702.533px',
  width: '400px',
};

const LocksPage = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(companySelector());
  const companyId = company?.id;
  const [filterType] = useState(LockFilterType.ALL_LOCKS);
  const [connectWebviewUrl, setConnectWebviewUrl] = useState<string | null>(null);
  const [connectWebviewId, setConnectWebviewId] = useState<string | null>(null);
  const [connectedAccountId, setConnectedAccountId] = useState<string | null>(null);
  const [hasAttemptedOnboarding, setHasAttemptedOnboarding] = useState(false);
  const [triggerGenerateLink, { data, isSuccess }] =
    usePostAccessCompatibilityOnboardingGenerateLinkMutation();
  const [isSeamAccount, setIsSeamAccount] = useState<boolean>(false);
  const [loadingTimeout, setLoadingTimeout] = useState<boolean>(false);

  const { t } = useTranslation();
  const { snackbar } = useSnackbar();

  const {
    data: onboardingStatusData,
    isSuccess: isOnboardingStatusSuccess,
    isLoading: isOnboardingStatusLoading,
  } = useGetAccessCompatibilityOnboardingByLegacyCompanyIdSeamOnboardingStatusQuery({
    legacyCompanyId: companyId,
  });

  const [triggerOnboardSeamAccount] = usePostAccessCompatibilityOnboardSeamAccountMutation();

  const {
    data: connectedAccount,
    isError: isConnectedAccountError,
    error: connectedAccountError,
  } = useGetAccessCompatibilityOnboardingByLegacyCompanyIdConnectWebviewAndConnectWebviewIdConnectedAccountIdQuery(
    { legacyCompanyId: companyId, connectWebviewId: connectWebviewId },
    { skip: !companyId || !connectWebviewId },
  );

  useEffect(() => {
    if (isConnectedAccountError && connectedAccountError) {
      const handleSnackbarClose = () => {
        triggerGenerateLink({ redirectUrl: '/locks' });
      };

      snackbar(t('error_fetching_connected_account'), null, 4000, handleSnackbarClose);

      logger.error('Failed to fetch connected account', connectedAccountError);
    }
  }, [isConnectedAccountError, connectedAccountError, snackbar, t, triggerGenerateLink]);

  useEffect(() => {
    dispatch(getCompanyInformation());
  }, [dispatch]);

  useEffect(() => {
    if (isOnboardingStatusSuccess && onboardingStatusData) {
      setIsSeamAccount(onboardingStatusData.is_onboarded);

      if (!onboardingStatusData.is_onboarded) {
        triggerGenerateLink({ redirectUrl: '/locks' });
      }
    }
  }, [isOnboardingStatusSuccess, onboardingStatusData, triggerGenerateLink]);

  useEffect(() => {
    if (isSuccess && data?.connect_view_url) {
      setConnectWebviewUrl(data.connect_view_url);
    }
  }, [isSuccess, data?.connect_view_url]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.event?.event_type === 'connect_webview.login_succeeded') {
        setConnectWebviewId(event.data?.event?.connect_webview_id);
      } else if (event.data?.event?.event_type === 'connect_webview.closed') {
        setConnectWebviewUrl(null);
        setConnectWebviewId(null);
        setLoadingTimeout(false);
      }
    };

    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, []);

  useEffect(() => {
    if (connectedAccount) {
      setConnectedAccountId(connectedAccount.connected_account_id);
    }
  }, [connectedAccount]);

  useEffect(() => {
    const onboardSeamAccount = async () => {
      if (!isOnboardingStatusSuccess || !onboardingStatusData || hasAttemptedOnboarding) {
        return;
      }

      if (isSeamAccount || onboardingStatusData.is_onboarded) {
        return;
      }

      if (connectedAccountId && companyId && !isSeamAccount) {
        setHasAttemptedOnboarding(true);
        setLoadingTimeout(true);

        try {
          await triggerOnboardSeamAccount({
            importSeamAccount: {
              legacyCompanyId: companyId,
              seamConnectedAccountId: connectedAccountId,
            },
          }).unwrap();

          setIsSeamAccount(true);
          setConnectWebviewUrl(null);
        } catch (error) {
          setConnectWebviewUrl(null);
          const onError = () => {
            triggerGenerateLink({ redirectUrl: '/locks' });
          };
          if (error.status === 400) {
            let errorMessage = error.data ?? t('error_onboarding_seam_account');
            if (errorMessage.includes('Seam Account already imported')) {
              errorMessage = t('lock_account_already_imported');
            }
            snackbar(errorMessage, null, 4000, onError);
          } else {
            snackbar(t('error_onboarding_seam_account'), null, 4000, onError);
          }
          logger.error('Failed to onboard Seam account', error);
        } finally {
          setLoadingTimeout(false); // Prevent race conditions
        }
      }
    };

    onboardSeamAccount();
  }, [
    companyId,
    triggerOnboardSeamAccount,
    connectedAccountId,
    isSeamAccount,
    t,
    snackbar,
    isOnboardingStatusSuccess,
    onboardingStatusData,
    hasAttemptedOnboarding,
    triggerGenerateLink,
  ]);

  const isLoading =
    isOnboardingStatusLoading ||
    (loadingTimeout && !isSeamAccount) ||
    (!isSeamAccount && !connectWebviewUrl);

  if (isLoading) {
    return (
      <Box sx={boxCenteredStyle}>
        <LoadingContainer loading={true} />
      </Box>
    );
  }

  if (!onboardingStatusData?.is_onboarded && connectWebviewUrl) {
    return (
      <Box sx={boxCenteredStyle}>
        <iframe
          style={iframeStyle}
          allow='clipboard-write'
          src={connectWebviewUrl}
          data-testid='connect-webview-iframe'
          title='Connect Webview'
        />
      </Box>
    );
  }

  if (isSeamAccount) {
    return <LocksTable filterType={filterType} />;
  }
};

export default LocksPage;
