// @ts-nocheck
import createLinkPlugin from '@draft-js-plugins/anchor';
import {
  BoldButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton,
} from '@draft-js-plugins/buttons';
import Editor from '@draft-js-plugins/editor';
import createInlineToolbarPlugin, { Separator } from '@draft-js-plugins/inline-toolbar';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import { Box, SxProps } from '@mui/material';
import InsertVariablesMenu from 'Pages/Variables/Common/InsertVariablesMenu';
import { htmlStringToLines } from 'Pages/Variables/helpers/VariableHelpers';
import {
  CompositeDecorator,
  ContentState,
  EditorState,
  Modifier,
  convertFromHTML,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import ScriptDropDownMenu from 'ui-library/Components/drop-down/ScriptDropDownMenu';

const findCorrectPositionInHtmlEncodedText = (text: string, position: number): number => {
  // in an html encoded text, the position of the cursor is not the same as the position in the text
  // for example, in the text <b>hello</b>, the position of the cursor in the text is 0, but the position in the html is 3
  // this function finds the correct position in the html encoded text

  if (!text || text.length === 0) {
    return 0;
  }

  const regex = new RegExp(/<[^>]+>/g); // matches all tags
  const positionsAndTags = []; // array of objects containing the tag name and its position
  const tagPositions = []; // array of arrays containing the start and end position of each tag
  let match;
  while ((match = regex.exec(text)) !== null) {
    const startPosition = match.index; // the position of the tag in the text
    const endPosition = match.index + match[0].length; // the position of the end of the tag in the text
    positionsAndTags.push({ tag: match[0], start: startPosition, end: endPosition });
    tagPositions.push([startPosition, endPosition]);
  }

  const middlePositions = []; // array of the length of the text in the gap, between each tag

  for (let i = 0; i < tagPositions.length - 1; i++) {
    const currentEnd = tagPositions[i][1];
    const nextStart = tagPositions[i + 1][0];
    middlePositions.push(nextStart - 1 - currentEnd + 1);
  }

  let remainder = position; // the position of the cursor in the text, relative to the start of the text (not the html)
  let positionGroupIndex = 0; // the index of the tag in the array of tags

  middlePositions.forEach((positionLength, index) => {
    if (remainder - positionLength >= 0) {
      //should skip this middle tag, as the cursor is after this one
      remainder -= positionLength;
    } else {
      positionGroupIndex = index; // the cursor is in this middle tag
      return;
    }
  });

  if (tagPositions.length > positionGroupIndex && tagPositions[positionGroupIndex].length > 1) {
    return tagPositions[positionGroupIndex][1] + remainder;
  }

  return 0;
};

type InlineTextEditorProps = {
  editable?: boolean;
  value: string;
  onTextChange?: (value: string) => void;
  showScriptButton: boolean;
  contentState?: ContentState;
  entityKey?: string;
  style?: SxProps;
  disableToolbar?: boolean;
};
const InlineTextEditor: React.FC<InlineTextEditorProps> = props => {
  const {
    value,
    onTextChange = () => void 0,
    editable = true,
    showScriptButton = true,
    disableToolbar,
    style,
  } = props;
  const blocksFromHTML = convertFromHTML(value);
  const content = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentSelectionPos, setCurrentSelectionPos] = useState(0);
  const [currentSelectionLine, setCurrentSelectionLine] = useState(0);

  function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
  }
  const Link = props => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
      <a href={url} target='_blank' rel='noreferrer'>
        {props.children}
      </a>
    );
  };

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(content, decorator));
  const linkPlugin = createLinkPlugin({ placeholder: 'http://…', linkTarget: '_blank' });
  const linkifyPlugin = createLinkifyPlugin({
    target: '_blank',
    component: (props: ComponentProps) => {
      const { ...rest } = props;
      return <a {...rest} target='_blank' />;
    },
  });

  const [plugins, InlineToolbar] = useMemo(() => {
    const inlineToolbarPlugin = createInlineToolbarPlugin({});
    return [[inlineToolbarPlugin, linkPlugin, linkifyPlugin], inlineToolbarPlugin.InlineToolbar];
    // Related: CS2DEV-6164
    // Reason: Using useMemo with depencies of inlineToolbarPlugin and linkPlugin causes the rendereder to break
    // so far, we have not found a solution to this problem and have decided to disable the rule
    // this decision has been made by @shayan919293 and @radelcom as the issue to the application
    // was considered P2 and critical to the application

    // TODO: find the solution to this problem and remove the rule

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editor = useRef<Editor | null>(null);

  const insertText = (text: string, offset: number) => {
    const newContentState = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text,
    );

    const newState = EditorState.createWithContent(newContentState);

    const newSelection = editorState.getSelection().merge({
      focusOffset: offset,
      anchorOffset: offset,
    });

    setEditorState(EditorState.acceptSelection(newState, newSelection));
  };

  const onEditorChange = (state: EditorState) => {
    const plainText = state.getCurrentContent().getPlainText('');
    const selectionState = state.getSelection();
    const contentState = state.getCurrentContent();
    const blockKey = selectionState.getStartKey();
    const blockMap = contentState.getBlockMap();
    const lineNumber = blockMap.keySeq().toList().indexOf(blockKey) + 1;

    setCurrentSelectionPos(state.getSelection().getAnchorOffset());
    setCurrentSelectionLine(lineNumber - 1); // line number starts from 0

    if (plainText.length > 0) {
      let newValue = draftToHtml(convertToRaw(state.getCurrentContent()));

      /**
       * https://operto.atlassian.net/browse/OPN1-12453
       *
       * NOTE: draft-js is not maintained anymore. this is a bug in the library.
       *
       * draftToHtml(convertToRaw(state.getCurrentContent())) return 'underline'
       * as <ins> tag which is not supported in html. during the conversion, we need to replace
       * <ins> with <u> to make it work.
       */
      newValue = newValue.replaceAll('ins>', 'u>');
      onTextChange(newValue);
    } else {
      onTextChange(plainText);
    }
    setEditorState(state);
  };

  const focus = (): void => {
    editor.current?.focus();
  };

  const updateEditorState = newValue => {
    const blocksFromHTML = convertFromHTML(newValue);
    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );

    setEditorState(EditorState.createWithContent(content));
  };
  useEffect(() => {
    if (!editable) {
      updateEditorState(value);
    }
  }, [value, editable]);

  const valueArray = htmlStringToLines(value);
  return (
    <Box display='flex' alignItems='flex-start'>
      <Box sx={{ ...editorContainerStyles, ...style }}>
        <Box
          border={0.5}
          borderColor='disabled'
          borderRadius='4px'
          paddingX='14px'
          paddingY='0px'
          onClick={focus}
          style={{ pointerEvents: editable ? 'unset' : 'none' }}
        >
          <Editor
            onBlur={e => {
              setAnchorEl(e.currentTarget);
            }}
            editorState={editorState}
            onChange={onEditorChange}
            plugins={plugins}
            ref={(element: T) => {
              editor.current = element;
            }}
            placeholder='Value'
          />
        </Box>
        {!disableToolbar && (
          <InlineToolbar>
            {externalProps => (
              <>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <Separator />
                <HeadlineOneButton {...externalProps} />
                <HeadlineTwoButton {...externalProps} />
                <Separator />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
                <linkPlugin.LinkButton {...externalProps} />
              </>
            )}
          </InlineToolbar>
        )}
      </Box>
      {showScriptButton && (
        <>
          <ScriptDropDownMenu
            marginTop={8}
            onTextInsert={(text: string) => {
              insertText(text, currentSelectionPos);
            }}
            addVariable
          />
          {valueArray.map((textInLine, index) => {
            if (index === currentSelectionLine) {
              return (
                <Fragment key={index}>
                  <InsertVariablesMenu
                    anchorEl={anchorEl ?? editor?.current}
                    targetText={textInLine}
                    onVariableSelectedUpdate={(newText: string) => {
                      valueArray[index] = newText;
                      const editedValueArray = valueArray.join('\n');
                      onTextChange(editedValueArray);
                      updateEditorState(editedValueArray);
                    }}
                    currentCursorPosition={findCorrectPositionInHtmlEncodedText(
                      textInLine,
                      currentSelectionPos,
                    )}
                  />
                </Fragment>
              );
            }
          })}
        </>
      )}
    </Box>
  );
};

const editorContainerStyles: SxProps = {
  width: '100%',
  '.DraftEditor-root': {
    wordWrap: 'break-word',
  },
};

export default InlineTextEditor;
