import { RTKQapi } from 'redux/rtkq-api';
import { store } from 'redux/store';

const appApiVersion = 'v2';

export const COPILOT_CHAT_TAG = 'copilot_chat';

const enhancedApi = RTKQapi.enhanceEndpoints({ addTagTypes: [COPILOT_CHAT_TAG] });

export const copilotChatApi = enhancedApi.injectEndpoints({
  endpoints: builder => ({
    getReservation: builder.query({
      query: ({ companyId, reservationId }: { companyId: number; reservationId: number }) =>
        `${appApiVersion}/companies/${companyId}/reservations/${reservationId}/chatbot`,
    }),
  }),
});

export const fetchReservation = async (companyId: number, reservationId: number) => {
  try {
    const result = await store.dispatch(
      copilotChatApi.endpoints.getReservation.initiate({ companyId, reservationId }),
    );
    if (result.data.data) {
      return result.data.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const { useGetReservationQuery } = copilotChatApi;
