import { Stack, SxProps } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import CopilotPanelEmpty from './CopilotPanelEmpty';
import CopilotPanelSkeleton from './CopilotPanelSkeleton';
import CopilotPanelTitlebar from './CopilotPanelTitlebar';
import CopilotReplies from './CopilotReplies';
import useCopilot from './useCopilot';

export default forwardRef<copilotPanelRefType, CopilotPanelProps>(function CopilotPanel(
  { onClose, isOpen, onSendReply, onInsertReply },
  copilotPanelRef,
) {
  const [instantReplies, setInstantReplies] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { createSuggestions } = useCopilot();

  const containReplies = instantReplies.length > 0;

  useImperativeHandle(copilotPanelRef, () => ({
    async getInstantReply({ reservationInfo, guidesInfo, conversationData }) {
      setIsLoading(true);

      const replies = await createSuggestions({
        reservationInfo: reservationInfo,
        guidesInfo: guidesInfo,
        conversation: conversationData,
      });

      if (replies) {
        setInstantReplies(JSON.parse(replies));
      }

      setIsLoading(false);
    },
    resetInstantReply() {
      setInstantReplies([]);
    },
  }));

  if (!isOpen) return null;

  const getBody = () => {
    if (isLoading) {
      return <CopilotPanelSkeleton />;
    }

    if (!containReplies) {
      return <CopilotPanelEmpty />;
    }

    return (
      <CopilotReplies
        replies={instantReplies}
        onInsertClick={onInsertReply}
        onSendClick={onSendReply}
      />
    );
  };

  return (
    <Stack sx={rootContainer}>
      <CopilotPanelTitlebar onClose={onClose} />

      {getBody()}
    </Stack>
  );
});

type CopilotPanelProps = {
  onClose: () => void;
  isOpen: boolean;
  onSendReply: (reply: string) => void;
  onInsertReply: (reply: string) => void;
};

export type copilotPanelRefType = {
  getInstantReply: ({
    reservationInfo,
    guidesInfo,
    conversationData,
  }: {
    reservationInfo: string;
    guidesInfo: string;
    conversationData: string;
  }) => void;
  resetInstantReply: () => void;
};

const rootContainer: SxProps = {
  flexDirection: 'column',
  minWidth: '350px',
  maxWidth: '350px',
  alignItems: 'center',
  borderLeft: '1px solid #EFF0F6',
  overflow: 'hidden',
  backgroundColor: '#FFF',
  borderTopRightRadius: '16px',
  borderBottomRightRadius: '16px',
};
