import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabelProps,
  MenuItem,
  Slide,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { Task } from '@operto/tasks-shared';
import { AddCard } from '@operto/ui-library';
import { FormContainer } from 'Common/FormContainer';
import { ICompany } from 'company/companyType';
import { getCurrentCompany } from 'company/state/companySelectors';
import format from 'date-fns/format';
import { FilterType } from 'guest/guestType';
import { getGuests } from 'guest/state/guestActions';
import { guestByFilter } from 'guest/state/guestSelectors';
import { DATE_FORMAT } from 'helper/date';
import useMessaging from 'hooks/useMessaging';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import cloneDeep from 'lodash/cloneDeep';
import { IMember } from 'member/memberType';
import { getSelf, memberSelector } from 'member/state/memberSelectors';
import { PropertyFilterType } from 'property/propertyType';
import { getProperties } from 'property/state/propertyActions';
import { getPropertiesByFilter } from 'property/state/propertySelectors';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getReservations } from 'reservation/state/reservationActions';
import { getAllReservationsByIds } from 'reservation/state/reservationSelectors';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { FormTextField } from 'ui-library/Components/input/FormTextField';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { userSelector } from 'user/state/userSelectors';
import TasksFieldSelector from './TasksFieldSelector';
import useTasks from './useTasks';
import { GUEST_NOTIFIABLE_TASK_STATUSES } from './utils';

const rootContainerStyles: SxProps = {
  borderLeft: '1px solid var(--light-other-outlined-border-23-p, rgba(8, 22, 62, 0.23))',
  position: 'absolute',
  right: 0,
  maxWidth: '500px',
  flexDirection: 'column',
  zIndex: 10,
};

export interface TaskDetailsProps {
  handleClose: () => void;
  taskId?: string;
  onTaskUpdate?: (task: Task) => void;
  incomingReservationId?: string;
}

export interface TaskOptions {
  id: string;
  name: string;
}

const labelOptions: TaskOptions[] = [
  { id: 'housekeeping', name: 'Housekeeping' },
  { id: 'accounting', name: 'Accounting' },
  { id: 'front desk', name: 'Front Desk' },
  { id: 'valet', name: 'Valet' },
  { id: 'maintenance', name: 'Maintenance' },
  { id: 'room service', name: 'Room Service' },
  { id: 'other', name: 'Other' },
];

const statusOptions: TaskOptions[] = [
  { id: 'requested', name: 'Requested' },
  { id: 'reviewed', name: 'Reviewed' },
  { id: 'scheduled', name: 'Scheduled' },
  { id: 'in progress', name: 'In Progress' },
  { id: 'completed', name: 'Completed' },
  { id: 'canceled', name: 'Canceled' },
  { id: 'closed', name: 'Closed' },
  { id: 'declined', name: 'Declined' },
];

const priorityOptions: TaskOptions[] = [
  { id: 'none', name: 'None' },
  { id: 'low', name: 'Low' },
  { id: 'medium', name: 'Medium' },
  { id: 'high', name: 'High' },
];

const taskDetailsAsteriskStyles: InputLabelProps = {
  shrink: true,
  sx: { '.MuiInputLabel-asterisk': { color: '#D32F2F' } },
};

const TITLE_MAX_LENGTH = 200;
const DESCRIPTION_MAX_LENGTH = 1000;

const TasksDetails = ({
  handleClose,
  taskId,
  onTaskUpdate,
  incomingReservationId,
}: TaskDetailsProps) => {
  const { t } = useTranslation();
  const { snackbar } = useSnackbar();
  const currentMember: IMember = useAppSelector(getSelf());

  const dispatch = useAppDispatch();
  const { createChannel, addMembersToChannel, broadcastMessage, removeMemberFromChannel } =
    useMessaging();
  const { getTask, createTask, updateTask } = useTasks({
    refetchOnWindowFocus: false,
    fetchOnMount: false,
  });
  const members = useAppSelector(memberSelector());
  const loggedInMember = useAppSelector(userSelector());
  const currentUserId = loggedInMember?.getStreamUser?.id;
  const company: ICompany = useAppSelector(getCurrentCompany());
  const formDefaultState = useMemo(() => {
    const minDueDate = format(new Date(), DATE_FORMAT);
    return {
      dueDateLocalized: minDueDate,
      priority: 'none',
    };
  }, []);

  const reservationOptions = useAppSelector(getAllReservationsByIds());
  const { guests: guestOptions } = useAppSelector(guestByFilter(FilterType.ALL_GUESTS));
  const { properties: propertyOptions } = useAppSelector(
    getPropertiesByFilter({
      filterType: PropertyFilterType.ALL_PROPERTIES,
    }),
  );

  const [localTask, setLocalTask] = useState<Task>();
  const [createdAt, setCreatedAt] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setShowLoading] = useState(true);
  const [showCompleteWarning, setShowCompleteWarning] = useState(false);
  const [showCancelWarning, setShowCancelWarning] = useState(false);

  const clientHeight = document.getElementById('page-container')?.clientHeight;
  const isNew = !(taskId || localTask?.id);
  const {
    data: task,
    isLoading: isTaskLoading,
    isFetching: isTaskFetching,
  } = getTask(taskId) ?? {};
  const { handleSubmit, watch, setValue, reset, clearErrors, control } = useForm<Task>({
    mode: 'all',
    defaultValues: formDefaultState,
    values: localTask,
  });
  const formState = useFormState({ control });
  // workaround, since isDirty upon change on the form happens, but dirtyFields object is empty
  const isFormChanged = Object.keys(formState.dirtyFields).length > 0;

  const titleValue = watch('title');
  const titleLengthText = titleValue && `${titleValue?.length} / ${TITLE_MAX_LENGTH}`;

  const descriptionValue = watch('description');
  const descriptionLengthText =
    descriptionValue && `${descriptionValue?.length} / ${DESCRIPTION_MAX_LENGTH}`;

  const labelValue = watch('type');
  const label = labelOptions.find(option => option.id === labelValue);

  const statusValue = watch('status');
  const status = statusOptions.find(option => option.id === statusValue);

  const memberValue = watch('assigneeId');
  const member = members.find(option => option.id === +memberValue);

  const compareNumbers = (a: number, b: number) => a - b;
  const compareStrings = (a: string, b: string) => a.localeCompare(b);

  // set up logic to auto-populate reservation, property & guest:
  // include id options that are shared between reservations and guests ONLY
  const reservationIds = new Set(reservationOptions.map(res => res.id));
  const guestIds = new Set(guestOptions.map(guest => guest.id));
  const sharedIds = new Set([...reservationIds].filter(x => guestIds.has(x)));

  // filter, then sort in place
  const sortedReservations = reservationOptions.filter(res => sharedIds.has(res.id));
  const sortedGuests = guestOptions.filter(guest => sharedIds.has(guest.id));

  sortedReservations.sort((a, b) => compareNumbers(a?.id, b?.id));
  sortedGuests.sort((a, b) => compareStrings(a?.name || '', b?.name || ''));
  propertyOptions.sort((a, b) => compareStrings(a?.name || '', b?.name || ''));

  // find reservation and guest based on form values to compare against
  const reservationIdValue = watch('reservationId');
  const guestIdValue = watch('guestId');
  const propertyIdValue = watch('unit');
  const reservation = sortedReservations.find(res => res.id === +reservationIdValue);
  const guest = sortedGuests.find(
    guest => guest.id === +guestIdValue || guest.id === reservation?.id,
  );
  const property = propertyOptions.find(
    property => property.id === +propertyIdValue || property.id === reservation?.property_id,
  );

  const handleCompleteClick = () => {
    setShowCompleteWarning(true);
  };

  const handleCancelClick = () => {
    setShowCancelWarning(true);
  };

  const handleCompleteDialogConfirm = async () => {
    setIsSaving(true);
    setShowCompleteWarning(false);

    if (!task) {
      return;
    }
    try {
      const updatedTask = { ...task, status: 'completed' };
      await updateTask(updatedTask);

      setLocalTask(updatedTask);
      onTaskUpdate?.(updatedTask);
    } catch (error) {
      snackbar(t('failure_to_mark_task_as_complete'));
    } finally {
      setIsSaving(false);
    }
  };

  const handleCompleteDialogDismiss = () => {
    setShowCompleteWarning(false);
  };

  const handleCancelDialogConfirm = async () => {
    setIsSaving(true);
    setShowCancelWarning(false);

    if (!task) {
      return;
    }

    try {
      const updatedTask = { ...task, status: 'canceled' };
      await updateTask(updatedTask);

      setLocalTask(updatedTask);
      onTaskUpdate?.(updatedTask);
    } catch (error) {
      snackbar(t('failure_to_cancel_task'));
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancelDialogDismiss = () => {
    setShowCancelWarning(false);
  };

  const doUpdateTask = async (data: Task, memberIds: string[]) => {
    data.updatedById = String(currentMember.id);
    await updateTask(data);

    await addMembersToChannel({
      channelId: `cid${company?.id}-tid${data.id}`,
      memberIds: memberIds,
    });

    const currentAssigneeId = localTask.assigneeId;

    if (currentAssigneeId && currentAssigneeId !== data.assigneeId) {
      await removeMemberFromChannel({
        channelId: `cid${company?.id}-tid${data.id}`,
        memberId: `mid-${currentAssigneeId}`,
      });
    }
  };

  const doCreateTask = async (data: Task, memberIds: string[]) => {
    data.createdById = String(currentMember.id);
    data.updatedById = String(currentMember.id);
    data = await createTask(data);

    await createChannel({
      channelId: `cid${company?.id}-tid${data.id}`,
      memberIds: [...new Set(memberIds)],
      channelName: data.title,
      channelData: 'tasks',
      propertyId: `${property?.id}`,
      propertyName: property?.name,
    });

    if (data.reservationId) {
      const guestChannelUserIds = members.map(member => `mid-${member.id}`);
      guestChannelUserIds.push(`rid-${data.reservationId}`);

      await createChannel({
        channelId: `${company?.id}-${data.reservationId}`,
        memberIds: guestChannelUserIds,
        channelName: reservation?.guest_name,
        channelData: 'guests',
        propertyId: `${property?.id}`,
        propertyName: property?.name,
      });
    }

    if (GUEST_NOTIFIABLE_TASK_STATUSES.includes(data.status) && data.reservationId) {
      await broadcastMessage({
        channelId: `${company?.id}-${data.reservationId}`,
        message: `${data.title} (${data.id}) - ${data.status}`,
        messageType: 'system',
        metadata: { internalType: 'task-created', task: data },
      });
    }

    return data;
  };

  const submitTask = async (data: Task) => {
    setIsSaving(true);

    let copyData: Task = cloneDeep(data);
    delete copyData.guestId; // Temp solution to avoid errors in BE, in future ticket we are fixing getting guest

    const memberIds: string[] = [];
    if (currentUserId) {
      memberIds.push(currentUserId);
    }

    if (copyData.reservationId) {
      copyData.reservationId = String(copyData.reservationId);
    }

    if (copyData.unit) {
      copyData.unit = String(copyData.unit);
    }

    if (copyData.assigneeId) {
      copyData.assigneeId = String(copyData.assigneeId);
      memberIds.push(`mid-${copyData.assigneeId}`);
    }

    let processError;
    if (isNew) {
      try {
        copyData = await doCreateTask(cloneDeep(copyData), memberIds);
      } catch (error) {
        logger.error('Error creating task', error);
        processError = error;
      }
    } else {
      try {
        await doUpdateTask(copyData, memberIds);
      } catch (error) {
        logger.error('Error updating task', error);
        processError = error;
      }
    }

    if (processError) {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: processError.message,
          variant: SnackbarVariant.ERROR,
        }),
      );
    } else {
      reset(copyData);
      setLocalTask(copyData);
      snackbar(t(isNew ? 'task_added' : 'changes_saved'));
      onTaskUpdate?.(copyData);
    }
    setIsSaving(false);
  };

  useEffect(() => {
    setShowLoading(true);
  }, [taskId]);

  useEffect(() => {
    const loadResources = async () => {
      setShowLoading(true);
      dispatch(getReservations());
      dispatch(getGuests({ filterType: FilterType.ALL_GUESTS }));
      dispatch(getProperties());
    };

    loadResources();
  }, [dispatch]);

  useEffect(() => {
    const isReservationDataReady =
      guestOptions?.length > 0 && reservationOptions?.length > 0 && propertyOptions?.length > 0;
    const isNewTaskFormReady = isNew && isReservationDataReady;
    const isEditTaskFormReady =
      !isNew &&
      !isTaskLoading &&
      !isTaskFetching &&
      (reservation?.id ? !!guest && !!property : isReservationDataReady);
    if (isNewTaskFormReady || isEditTaskFormReady) {
      setShowLoading(false);
      clearErrors();
    }
  }, [
    guest,
    reservation,
    property,
    isTaskLoading,
    isTaskFetching,
    isNew,
    guestOptions,
    reservationOptions,
    propertyOptions,
    clearErrors,
  ]);

  // auto-populate reservation, property & guest fields based on selection
  useEffect(() => {
    if (reservationIdValue && reservation?.id && reservation?.property_id) {
      // internally use reservationId in the guestId field to better link the two
      setValue('guestId', reservation.id);
      setValue('unit', reservation.property_id);
    }
  }, [reservationIdValue, reservation?.id, reservation?.property_id, setValue]);

  useEffect(() => {
    if (guestIdValue && guest?.id && guest?.property_id) {
      setValue('reservationId', guest?.id);
      setValue('unit', guest?.property_id);
    }
  }, [guestIdValue, guest?.id, guest?.property_id, setValue]);

  useEffect(() => {
    if (task) {
      const taskData = { ...task, priority: task.priority ?? 'none' };
      setLocalTask(taskData);
    } else {
      setLocalTask(undefined);
    }
  }, [task, reset]);

  useEffect(() => {
    if (isNew) {
      reset(formDefaultState);

      if (incomingReservationId) {
        setValue('reservationId', incomingReservationId);
        setValue('unit', ''); // reset guest card,
        setValue('guestId', ''); // the auto population will override as intended
      }
    } else {
      if (localTask?.createdAt) {
        setCreatedAt(format(new Date(localTask.createdAt), DATE_FORMAT));
      }
      reset(localTask);
    }
  }, [localTask, isNew, reset, formDefaultState, incomingReservationId, setValue]);

  return (
    <Slide in={true} direction='left'>
      <Box sx={{ ...rootContainerStyles, height: clientHeight + 'px', overflow: 'auto' }}>
        <Stack>
          <LoadingContainer loading={isLoading}>
            <FormContainer
              title={isNew ? t('task_sidebar_create_task') : t('task_sidebar_edit_task')}
              onClose={handleClose}
              dirty={isFormChanged}
              onSubmit={handleSubmit(submitTask)}
              submitButtonTitle={t('save')}
              submitButtonIcon={null}
              submitButtonSx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: '15px' }}
              loading={isSaving}
              useConfirmLeaveDialogPopup={true}
            >
              {localTask &&
                !['completed', 'canceled', 'closed', 'declined'].includes(localTask.status) && (
                  <Box display='flex' gap={2} mb={3}>
                    <Button
                      variant='contained'
                      size='large'
                      onClick={handleCompleteClick}
                      sx={{
                        width: '226px',
                        borderRadius: '6px',
                      }}
                      disabled={isSaving}
                      data-testid='tasks-complete'
                    >
                      {t('complete')}
                    </Button>
                    <Button
                      variant='outlined'
                      size='large'
                      onClick={handleCancelClick}
                      color='error'
                      sx={{
                        width: '226px',
                        borderRadius: '6px',
                      }}
                      disabled={isSaving}
                      data-testid='tasks-cancel'
                    >
                      {t('cancel')}
                    </Button>
                  </Box>
                )}

              {showCompleteWarning && (
                <Dialog
                  open={showCompleteWarning}
                  onClose={handleCompleteDialogDismiss}
                  sx={{
                    '& .MuiDialog-paper': {
                      minWidth: '444px',
                    },
                  }}
                >
                  <DialogTitle sx={{ fontWeight: 700 }}>{t('complete_task_dialog')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText sx={{ color: '#000000DE' }}>
                      {task.title} {t('will_be_marked_as_completed')}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ padding: '8px 16px 16px 8px' }}>
                    <Button onClick={handleCompleteDialogDismiss}>{t('dismiss')} </Button>
                    <Button
                      onClick={handleCompleteDialogConfirm}
                      variant='contained'
                      color='primary'
                      disabled={isSaving}
                      data-testid={'tasks-complete-confirm'}
                    >
                      {t('complete_task')}
                    </Button>
                  </DialogActions>
                </Dialog>
              )}

              {showCancelWarning && (
                <Dialog
                  open={showCancelWarning}
                  onClose={handleCancelDialogDismiss}
                  sx={{
                    '& .MuiDialog-paper': {
                      minWidth: '444px',
                    },
                  }}
                >
                  <DialogTitle sx={{ fontWeight: 700 }}>{t('cancel_task_dialog')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText sx={{ color: '#000000DE' }}>
                      {t('this_will_cancel_task')} {task.title}.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ padding: '8px 16px 16px 8px' }}>
                    <Button onClick={handleCancelDialogDismiss}>{t('dismiss')} </Button>
                    <Button
                      onClick={handleCancelDialogConfirm}
                      variant='contained'
                      color='primary'
                      disabled={isSaving}
                      data-testid={'tasks-cancel-confirm'}
                    >
                      {t('cancel_task')}
                    </Button>
                  </DialogActions>
                </Dialog>
              )}

              <AddCard title='Task'>
                <FormTextField
                  rules={{
                    required: t('task_title_required'),
                    validate: value => value?.length <= TITLE_MAX_LENGTH || titleLengthText,
                  }}
                  required
                  field='title'
                  label={t('title')}
                  control={control}
                  helperText={titleLengthText}
                  FormHelperTextProps={{ sx: { textAlign: titleValue && 'right' } }}
                  InputLabelProps={taskDetailsAsteriskStyles}
                  disabled={isSaving}
                  data-testid='tasks-title'
                />

                <TasksFieldSelector
                  value={label}
                  options={labelOptions}
                  control={control}
                  fieldName='type'
                  fieldLabel={t('label')}
                  customProps={{
                    InputLabelProps: taskDetailsAsteriskStyles,
                    required: true,
                    rules: {
                      required: t('task_label_required'),
                    },
                  }}
                  isEnabled={!isSaving}
                />

                <TasksFieldSelector
                  value={status}
                  options={statusOptions}
                  control={control}
                  fieldName='status'
                  fieldLabel={t('status')}
                  isEnabled={!isSaving}
                  customProps={{
                    InputLabelProps: taskDetailsAsteriskStyles,
                    required: true,
                    rules: {
                      required: t('task_status_required'),
                    },
                  }}
                />
                <FormTextField
                  rules={{
                    required: t('task_due_on_required'),
                  }}
                  required
                  field='dueDateLocalized'
                  label={t('due_on')}
                  type='datetime-local'
                  control={control}
                  inputProps={{ min: formDefaultState.dueDateLocalized }}
                  InputLabelProps={taskDetailsAsteriskStyles}
                  disabled={isSaving}
                  data-testid='tasks-due-on'
                />
                <FormTextField
                  select
                  field='priority'
                  label={t('priority')}
                  control={control}
                  disabled={isSaving}
                  data-testid='tasks-priority'
                >
                  {priorityOptions.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </FormTextField>
                <FormTextField
                  rules={{
                    validate: value =>
                      value?.length <= DESCRIPTION_MAX_LENGTH || descriptionLengthText,
                  }}
                  multiline
                  rows={8}
                  field='description'
                  label={t('description')}
                  control={control}
                  helperText={descriptionLengthText}
                  FormHelperTextProps={{ sx: { textAlign: descriptionValue && 'right' } }}
                  disabled={isSaving}
                  data-testid='tasks-description'
                />
              </AddCard>

              <AddCard title={t('assignee')}>
                <TasksFieldSelector
                  value={member}
                  options={members}
                  control={control}
                  fieldName='assigneeId'
                  fieldLabel={t('assignee')}
                  showMember={true}
                  isEnabled={!isSaving}
                />
              </AddCard>

              <AddCard title={t('guest')}>
                <TasksFieldSelector
                  value={reservation}
                  options={sortedReservations}
                  control={control}
                  isEnabled={isNew && !isSaving && !incomingReservationId}
                  fieldName='reservationId'
                  fieldLabel={t('reservation')}
                />

                <TasksFieldSelector
                  value={property}
                  options={propertyOptions}
                  control={control}
                  isEnabled={false}
                  fieldName='unit'
                  fieldLabel={t('room')}
                />

                <TasksFieldSelector
                  value={guest}
                  options={sortedGuests}
                  control={control}
                  isEnabled={isNew && !isSaving && !incomingReservationId}
                  fieldName='guestId'
                  fieldLabel={t('guest')}
                />
              </AddCard>

              {!isNew && (
                <>
                  <Typography variant='subtitle-sm-600'>{t('created_on')}</Typography>
                  <Typography variant='body1'>{createdAt}</Typography>
                </>
              )}
            </FormContainer>
          </LoadingContainer>
        </Stack>
      </Box>
    </Slide>
  );
};

export default TasksDetails;
