import { createSelector } from '@reduxjs/toolkit';
import { ICompany, ProviderTypes } from 'company/companyType';
import { ApplicationState } from 'redux/reducers';

const getCompany = (state: ApplicationState) => state.company;

export const getCurrentCompany = () => createSelector([getCompany], (company: ICompany) => company);

export const companySelector = () => createSelector([getCompany], (company: ICompany) => company);

export const getCompanyId = () =>
  createSelector([getCompany], (company: ICompany) => company && company.id);

export const guestVerificationEnabledSelector = () =>
  createSelector(
    [getCompany],
    (company: ICompany) => company.verificationProvider !== ProviderTypes.NONE,
  );

export const roomTypeEnabledSelector = () =>
  createSelector([getCompany], (company: ICompany) => company.roomTypeEnabled);

export const messengerSettingSelector = () =>
  createSelector([getCompany], (company: ICompany) => company.messengerSetting);

export const smartButtonMessengerTriggersSelector = () =>
  createSelector([getCompany], (company: ICompany) => company.smartButtonMessengerTriggers);

export const getCompanyMetaData = () =>
  createSelector([getCompany], (company: ICompany) => company.metaData);
