import { Add } from '@mui/icons-material';
import { Box, Fab, Stack, Tooltip } from '@mui/material';
import { TemplateDataType, WorkflowDataType } from '@operto/communications-shared';
import { Text } from '@operto/ui';
import { companySelector } from 'company/state/companySelectors';
import { TriggerType, TriggerTypeOption } from 'hooks/useAutomate';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import React, { Fragment } from 'react';
import { useAppSelector } from 'redux/hooks';
import useTriggerSorting from '../useTriggerSorting';
import AutomateWorkflowTriggerCard from './AutomateWorkflowTriggerCard';

export default function AutomateWorkflowDiagram({
  selectedCardId,
  onCardClick,
  triggers,
  hideAdd,
  onClickBeforeCheckIn,
  onClickAfterCheckIn,
  onClickAfterCheckOut,
  showTriggerError,
}: AutomateWorkflowDiagramProps) {
  const { t } = useTranslation();
  const company = useAppSelector(companySelector());
  const { isFeatureEnabled } = useAppFeatures();
  const checkOutTriggersEnabled = isFeatureEnabled('communications-check-out-trigger', company.id);
  const triggersCount = checkOutTriggersEnabled ? 'four' : 'two';

  // Apply sorting to the triggers
  const sortedTriggers = useTriggerSorting(triggers);

  const localTriggers =
    sortedTriggers?.reduce((acc, trigger, i) => {
      acc[trigger.id] = { ...trigger, index: i };
      return acc;
    }, {} as Record<string, localTriggerType>) || {};

  const triggersBeforeCheckIn = Object.values(localTriggers).filter(
    trigger => (trigger as localTriggerType).type === TriggerTypeOption.BEFORE_CHECK_IN,
  ) as localTriggerType[];
  const triggersAtCheckIn = Object.values(localTriggers).filter(
    trigger => (trigger as localTriggerType).type === TriggerTypeOption.CHECK_IN,
  ) as localTriggerType[];
  const triggersAfterCheckIn = Object.values(localTriggers).filter(
    trigger => (trigger as localTriggerType).type === TriggerTypeOption.AFTER_CHECK_IN,
  ) as localTriggerType[];

  const triggersBeforeCheckOut = Object.values(localTriggers).filter(
    trigger => (trigger as localTriggerType).type === TriggerTypeOption.BEFORE_CHECK_OUT,
  ) as localTriggerType[];
  const triggersAtCheckOut = Object.values(localTriggers).filter(
    trigger => (trigger as localTriggerType).type === TriggerTypeOption.CHECK_OUT,
  ) as localTriggerType[];
  const triggersAfterCheckOut = Object.values(localTriggers).filter(
    trigger => (trigger as localTriggerType).type === TriggerTypeOption.AFTER_CHECK_OUT,
  ) as localTriggerType[];

  const hasAtCheckIn = !!triggersAtCheckIn.length;
  const hasAfterCheckIn = !!triggersAfterCheckIn.length;

  const hasAtCheckOut = !!triggersAtCheckOut.length;
  const hasAfterCheckOut = !!triggersAfterCheckOut.length;

  return (
    <Stack sx={rootContainerStyles}>
      <Stack sx={diagramContainerStyles}>
        {triggersBeforeCheckIn.map((trigger, index) => (
          <Fragment key={trigger.id}>
            {!!index && (
              <Box sx={typeContainerStyles}>
                <Box sx={horizontalLineStyles} />
              </Box>
            )}

            <AutomateWorkflowTriggerCard
              offset={trigger.offset}
              title={t('trigger_before_check_in')}
              isSelected={selectedCardId === trigger.id}
              onClick={() => onCardClick?.(trigger.id)}
              templates={trigger.templates}
              showErrorMessages={showTriggerError?.includes(trigger.index)}
              isAt={trigger.type === TriggerTypeOption.CHECK_IN}
            />
          </Fragment>
        ))}

        {!hideAdd && (
          <>
            {!!triggersBeforeCheckIn.length && (
              <Box sx={[typeContainerStyles, { height: '24px' }]}>
                <Box sx={[horizontalLineStyles, { height: '24px' }]} />
              </Box>
            )}

            <Tooltip title={t('add_trigger')} slotProps={tooltipSlotProps}>
              <Fab sx={fabStyles} onClick={onClickBeforeCheckIn}>
                <Add />
              </Fab>
            </Tooltip>
          </>
        )}

        {(!!triggersBeforeCheckIn.length || !hideAdd) && (
          <Box sx={typeContainerStyles}>
            <Box
              sx={[
                horizontalLineStyles,
                {
                  height: !hideAdd || !!triggersBeforeCheckIn.length ? undefined : '24px',
                  bottom: !hideAdd || !!triggersBeforeCheckIn.length ? undefined : 0,
                },
              ]}
            />

            {!checkOutTriggersEnabled && (
              <Text variant='h4-300' style={typeStyles}>
                {t('before')}
              </Text>
            )}
          </Box>
        )}

        <Box sx={mainProcessContainerStyles}>
          <Text variant='h3-700'>{t('reservation_check_in')}</Text>
        </Box>

        {hasAtCheckIn && (
          <>
            <Box sx={typeContainerStyles}>
              <Box sx={horizontalLineStyles} />
              {!checkOutTriggersEnabled && (
                <Text variant='h4-300' style={typeStyles}>
                  {t('at')}
                </Text>
              )}
            </Box>

            {triggersAtCheckIn.map((trigger, i) => (
              <Fragment key={trigger.id}>
                {!!i && (
                  <Box sx={typeContainerStyles}>
                    <Box sx={horizontalLineStyles} />
                  </Box>
                )}

                <AutomateWorkflowTriggerCard
                  offset={trigger.offset}
                  title={t('trigger_at_check_in')}
                  isSelected={selectedCardId === trigger.id}
                  onClick={() => onCardClick?.(trigger.id)}
                  hideScheduled
                  templates={trigger.templates}
                  showErrorMessages={showTriggerError?.includes(trigger.index)}
                  isAt={trigger.type === TriggerTypeOption.CHECK_IN}
                />
              </Fragment>
            ))}
          </>
        )}

        {hasAfterCheckIn && (
          <>
            <Box sx={typeContainerStyles}>
              <Box sx={horizontalLineStyles} />
              {!checkOutTriggersEnabled && (
                <Text variant='h4-300' style={typeStyles}>
                  {t('after')}
                </Text>
              )}
            </Box>

            {triggersAfterCheckIn.map((trigger, i) => (
              <Fragment key={trigger.index}>
                {!!i && (
                  <Box sx={typeContainerStyles}>
                    <Box sx={horizontalLineStyles} />
                  </Box>
                )}

                <AutomateWorkflowTriggerCard
                  offset={trigger.offset}
                  title={t('trigger_after_check_in')}
                  isSelected={selectedCardId === trigger.id}
                  onClick={() => onCardClick?.(trigger.id)}
                  templates={trigger.templates}
                  showErrorMessages={showTriggerError?.includes(trigger.index)}
                  isAt={trigger.type === TriggerTypeOption.CHECK_IN}
                />
              </Fragment>
            ))}
          </>
        )}

        {!hideAdd && (
          <>
            <Box sx={typeContainerStyles}>
              <Box sx={horizontalLineStyles} />
              {!checkOutTriggersEnabled && (
                <Text variant='h4-300' style={typeStyles}>
                  {t('at_or_after')}
                </Text>
              )}
            </Box>

            {!hideAdd && (
              <Tooltip title={t('add_trigger')} slotProps={tooltipSlotProps}>
                <Fab sx={fabStyles} onClick={onClickAfterCheckIn}>
                  <Add />
                </Fab>
              </Tooltip>
            )}
          </>
        )}

        {/* reservation check-out */}
        {checkOutTriggersEnabled && (
          <>
            {!!triggersBeforeCheckOut?.length && (
              <Box sx={typeContainerStyles}>
                <Box sx={horizontalLineStyles} />
              </Box>
            )}
            {triggersBeforeCheckOut.map((trigger, index) => (
              <Fragment key={trigger.id}>
                {!!index && (
                  <Box sx={typeContainerStyles}>
                    <Box sx={horizontalLineStyles} />
                  </Box>
                )}

                <AutomateWorkflowTriggerCard
                  offset={trigger.offset}
                  title={t('trigger_before_check_out')}
                  isSelected={selectedCardId === trigger.id}
                  onClick={() => onCardClick?.(trigger.id)}
                  templates={trigger.templates}
                  showErrorMessages={showTriggerError?.includes(trigger.index)}
                  isAt={trigger.type === TriggerTypeOption.CHECK_OUT}
                />
              </Fragment>
            ))}

            <Box sx={typeContainerStyles}>
              <Box
                sx={[
                  horizontalLineStyles,
                  {
                    height:
                      !hideAdd ||
                      !!triggersBeforeCheckOut.length ||
                      !!triggersAfterCheckIn.length ||
                      !!triggersAtCheckIn.length ||
                      (hideAdd &&
                        (!triggersBeforeCheckOut.length ||
                          !triggersAfterCheckIn.length ||
                          !triggersAtCheckIn.length))
                        ? undefined
                        : '24px',
                    bottom: !hideAdd || !!triggersBeforeCheckOut.length ? undefined : 0,
                  },
                ]}
              />
            </Box>

            <Box sx={mainProcessContainerStyles}>
              <Text variant='h3-700'>{t('reservation_check_out')}</Text>
            </Box>

            {hasAtCheckOut && (
              <>
                <Box sx={typeContainerStyles}>
                  <Box sx={horizontalLineStyles} />
                </Box>

                {triggersAtCheckOut.map((trigger, i) => (
                  <Fragment key={trigger.id}>
                    {!!i && (
                      <Box sx={typeContainerStyles}>
                        <Box sx={horizontalLineStyles} />
                      </Box>
                    )}

                    <AutomateWorkflowTriggerCard
                      offset={trigger.offset}
                      title={t('trigger_at_check_out')}
                      isSelected={selectedCardId === trigger.id}
                      onClick={() => onCardClick?.(trigger.id)}
                      hideScheduled
                      templates={trigger.templates}
                      showErrorMessages={showTriggerError?.includes(trigger.index)}
                      isAt={trigger.type === TriggerTypeOption.CHECK_OUT}
                    />
                  </Fragment>
                ))}
              </>
            )}

            {hasAfterCheckOut && (
              <>
                <Box sx={typeContainerStyles}>
                  <Box sx={horizontalLineStyles} />
                </Box>

                {triggersAfterCheckOut.map((trigger, pos) => (
                  <Fragment key={trigger.id}>
                    {!!pos && (
                      <Box sx={typeContainerStyles}>
                        <Box sx={horizontalLineStyles} />
                      </Box>
                    )}

                    <AutomateWorkflowTriggerCard
                      offset={trigger.offset}
                      title={t('trigger_after_check_out')}
                      isSelected={selectedCardId === trigger.id}
                      onClick={() => onCardClick?.(trigger.id)}
                      templates={trigger.templates}
                      showErrorMessages={showTriggerError?.includes(trigger.index)}
                      isAt={trigger.type === TriggerTypeOption.CHECK_OUT}
                    />
                  </Fragment>
                ))}
              </>
            )}

            {!hideAdd && (
              <>
                <Box sx={typeContainerStyles}>
                  <Box sx={horizontalLineStyles} />
                </Box>

                {!hideAdd && (
                  <Tooltip title={t('add_trigger')} slotProps={tooltipSlotProps}>
                    <Fab sx={fabStyles} onClick={onClickAfterCheckOut}>
                      <Add />
                    </Fab>
                  </Tooltip>
                )}
              </>
            )}
          </>
        )}

        <Box sx={messageContainerStyles}>
          <Text variant='body-lg-400'>
            {t('you_can_add_n_event_triggers', { triggersCount: triggersCount })}
          </Text>
        </Box>
      </Stack>
    </Stack>
  );
}

type AutomateWorkflowDiagramProps = {
  selectedCardId?: string;
  onCardClick?: (id: string) => void;
  triggers?: WorkflowDataType['triggers'][];
  hideAdd?: boolean;
  onClickBeforeCheckIn: () => void;
  onClickAfterCheckIn: () => void;
  onClickBeforeCheckOut: () => void;
  onClickAfterCheckOut: () => void;
  showTriggerError?: number[];
};

type localTriggerType = {
  id: string;
  index: number;
  offset: number;
  templates: TemplateDataType;
  type: TriggerType;
};

const rootContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  backgroundColor: '#EBEDF5',
  borderRadius: '16px',
  marginTop: '24px',
  marginBottom: '-8px',
  padding: '24px 0 24px 0',
  position: 'relative',
};

const diagramContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
};

const messageContainerStyles = {
  display: 'flex',
  paddingTop: '24px',
  paddingBottom: '24px',
  justifyContent: 'center',
  alignItems: 'center',
};

const fabStyles = {
  backgroundColor: '#fff',
};

const typeContainerStyles = {
  height: '78px',
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
};

const mainProcessContainerStyles = {
  width: '600px',
  display: 'flex',
  padding: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  border: '4px solid rgba(8, 22, 62, 0.23)',
  borderRadius: '28px',
};

const typeStyles = { backgroundColor: '#EBEDF5', zIndex: 1 };

const horizontalLineStyles = {
  borderRight: '2px solid black',
  height: '78px',
  position: 'absolute',
};

const tooltipSlotProps = {
  popper: { modifiers: [{ name: 'offset', options: { offset: [0, -8] } }] },
};
