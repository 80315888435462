import ChatBubblOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Box, SxProps, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MessageChanneListItemDropdown from 'Pages/Messaging/MessageChannelListItemDropdown';
import { MIN_MESSAGES_DATE } from 'Pages/Messaging/MessagingConstants';
import useTasks from 'Pages/Tasks/useTasks';
import { ClientContext } from 'helper/streamChatHelper';
import { trackEvent } from 'lib/analytics';
import { getMembers } from 'member/state/memberActions';
import { memberSelector } from 'member/state/memberSelectors';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ChannelSort } from 'stream-chat';
import { ChannelList, Chat } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { userSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';

const options = { state: true, presence: true, limit: 5 };
const sort: ChannelSort<DefaultStreamChatGenerics> = { has_unread: -1 };

export type tasksChatDropdownPropsType = {
  id: string;
  assigneeName?: string;
};

const MessageDropDown = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const {
    clientObject: { streamChatClient, clientUnreadCount },
    setClientObject,
  } = useContext(ClientContext);
  const loggedInMember: UserState = useAppSelector<UserState>(userSelector());
  const filters = {
    members: { $in: [loggedInMember?.getStreamUser?.id] },
    last_message_at: { $gt: MIN_MESSAGES_DATE },
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    trackEvent({
      screen: 'DASHBOARD',
      feature: 'MESSAGES DROP DOWN',
      event: 'CLICKED',
      unreadCount: clientUnreadCount,
    });
  };

  const dispatch = useAppDispatch();
  const { data: tasks } = useTasks({ fetchOnMount: true, refetchOnWindowFocus: false });

  const membersOptions = useAppSelector(memberSelector());
  const tasksChatDropdownProps: tasksChatDropdownPropsType[] = tasks?.map(task => {
    const assigneeName = membersOptions.find(member => +member?.id === +task?.assigneeId)?.name;

    return {
      id: task.id,
      assigneeName: assigneeName,
    };
  });

  useEffect(() => {
    dispatch(getMembers());
  }, [dispatch]);

  useEffect(() => {
    streamChatClient.on(event => {
      if (event.total_unread_count !== undefined) {
        setClientObject(prev => ({ ...prev, clientUnreadCount: event.total_unread_count }));
      }
    });
  }, [loggedInMember.getStreamUser.id, setClientObject, streamChatClient]);

  return (
    <Box>
      <IconButton onClick={handleClick} size='large'>
        <Badge badgeContent={clientUnreadCount} color='primary'>
          <ChatBubblOutlineIcon />
        </Badge>
      </IconButton>

      <Menu
        PaperProps={{ sx: { padding: '15px', minWidth: '300px' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
      >
        <Typography sx={{ marginLeft: '15px' }} variant='button'>
          Messages
        </Typography>

        {Boolean(anchorEl) && (
          <Box sx={chatContentContainerStyles}>
            <Chat client={streamChatClient}>
              <ChannelList
                Preview={props => (
                  <MessageChanneListItemDropdown
                    onClose={() => {
                      setAnchorEl(null);
                    }}
                    tasksChatDropdownProps={tasksChatDropdownProps}
                    {...props}
                  />
                )}
                filters={filters}
                options={options}
                sort={sort}
              />
            </Chat>
          </Box>
        )}

        <Box
          sx={viewAllContainerStyles}
          onClick={() => {
            navigate('/messenger');
            setAnchorEl(null);
          }}
        >
          <Typography color='primary' variant='body2'>
            View all Messages
          </Typography>
        </Box>
      </Menu>
    </Box>
  );
};

const chatContentContainerStyles: SxProps = {
  '.str-chat__load-more-button': {
    display: 'none',
  },
};

const viewAllContainerStyles: SxProps = {
  padding: '12px',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  cursor: 'pointer',
};

export default MessageDropDown;
