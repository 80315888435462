import { Box, Button, Stack, SxProps, Tooltip, tooltipClasses } from '@mui/material';
import { Text } from '@operto/ui';
import CopilotIcon from 'assets/img/icons/icn-copilot.svg';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

export default function CopilotReply({
  reply,
  order,
  onInsertClick,
  onSendClick,
  insertButtonDisabled,
  sendButtonDisabled,
}: CopilotReplyProps) {
  const { t } = useTranslation();

  return (
    <Stack sx={rootContainerStyles}>
      <Stack sx={titleContainerStyles}>
        <CopilotIcon />
        {order && <Text.CaptionLarge>{`${t('response')} ${order}`}</Text.CaptionLarge>}
      </Stack>

      <Box sx={replyContainerStyles}>
        <Text.BodySmall color='rgba(0, 0, 0, 0.6)'>{reply}</Text.BodySmall>
      </Box>

      <Stack sx={actionContainerStyles}>
        {onInsertClick && (
          <Tooltip title={t('instant_reply_insert_into_chat')} slotProps={tooltipSlotProps}>
            <Button
              disabled={insertButtonDisabled}
              variant='text'
              color='primary'
              size='small'
              onClick={() => onInsertClick(reply)}
            >
              {t('insert')}
            </Button>
          </Tooltip>
        )}

        {onSendClick && (
          <Tooltip title={t('send_now')} slotProps={tooltipSlotProps}>
            <Button
              disabled={sendButtonDisabled}
              variant='outlined'
              color='primary'
              size='small'
              onClick={() => onSendClick(reply)}
            >
              {t('send')}
            </Button>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
}

type CopilotReplyProps = {
  reply: string;
  order?: number;
  onInsertClick?: (reply: string) => void;
  onSendClick?: (reply: string) => void;
  insertButtonDisabled?: boolean;
  sendButtonDisabled?: boolean;
};

const tooltipSlotProps = {
  popper: {
    sx: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
        marginTop: '4px',
      },
    },
  },
};

const rootContainerStyles: SxProps = {
  flexDirection: 'column',
  gap: '4px',
};

const replyContainerStyles: SxProps = {
  padding: '12px 16px',
  flex: 1,
  borderRadius: '20px',
  backgroundColor: 'rgba(21, 94, 255, 0.04)',
};

const actionContainerStyles: SxProps = {
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'space-between',
  '& button': { textTransform: 'capitalize', fontWeight: 900 },
};

const titleContainerStyles: SxProps = { flexDirection: 'row', gap: '8px' };
