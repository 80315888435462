import { Box, Tab, Tabs } from '@mui/material';
import { SideDrawer } from 'Common/Drawer/SideDrawer';
import RightSideTopbar from 'Common/Slidebar/components/RightSideTopbar';
import ActionSnackbar from 'Common/Snackbar/ActionSnackbar';
import GPPreviewContainer from 'Pages/GuestPortal/Preview/GPPreviewContainer';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ICategory, IPage } from 'redux/guestportal';
import TabTransition from 'ui-library/Components/transitions/TabTransition';
import GuidesDetailsPagesFormTabEdit from './GuidesDetailsPagesFormTabEdit';
import GuidesDetailsPagesFormTabSettings from './GuidesDetailsPagesFormTabSettings';

enum GuidesDetailsPagesFormTabItem {
  Edit = 0,
  Settings,
  Preview,
}

type GuidesDetailsPagesFormContainerProps = {
  activeTab: GuidesDetailsPagesFormTabItem;
  onTabClick: (tab: GuidesDetailsPagesFormTabItem) => void;
  children?: React.ReactNode;
  onSave?: () => void;
  onClose: () => void;
  page: IPage;
  originalPage?: IPage;
  openSnackbar: boolean;
  setOpenSnackbar: (value: boolean) => void;
  handleClose: () => void;
};

const GuidesDetailsPagesFormContainer = ({
  page,
  children,
  activeTab,
  onTabClick,
  onSave,
  onClose,
  openSnackbar,
  setOpenSnackbar,
  handleClose,
}: GuidesDetailsPagesFormContainerProps) => {
  const snackbarSeverity = 'error';
  const snackbarButtonText = 'YES';
  const snackbarMessage =
    'You have unsaved changes that will be lost if you exit. Are you sure you want to exit?';

  if (page === undefined) {
    return null;
  }

  return (
    <>
      <ActionSnackbar
        onClose={() => {
          setOpenSnackbar(false);
          onClose();
        }}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={snackbarSeverity}
        actionButtonText={snackbarButtonText}
        snackbarMessage={snackbarMessage}
      />

      <RightSideTopbar
        title={page?.title?.length > 0 ? page?.title : 'New Page Title'}
        handleClose={handleClose}
        page={page}
        onSave={onSave}
      />
      <Tabs
        sx={{ backgroundColor: 'background.default' }}
        value={activeTab}
        onChange={(event: React.ChangeEvent, newValue: number) => onTabClick(newValue)}
      >
        <Tab label='Edit' />
        <Tab label='Settings' />
        {page.content_id && <Tab label='Preview' />}
      </Tabs>
      <Box p={1.5} display='flex'>
        {children}
      </Box>
    </>
  );
};

type GuidesDetailsPagesFormProps = {
  page: IPage;
  onSave: (page: IPage) => void;
};

export const GuidesDetailsPagesForm = ({ page, onSave }: GuidesDetailsPagesFormProps) => {
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [activeTab, setActiveTab] = useState<GuidesDetailsPagesFormTabItem>(
    GuidesDetailsPagesFormTabItem.Edit,
  );
  const [pageCopy, setPageCopy] = useState<IPage>(undefined);

  const goBack = () => {
    navigate(-1); // go back
  };

  const handleClose = () => {
    if (page === undefined) return;

    if (isEqual(page, pageCopy)) {
      goBack();
    } else {
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    setPageCopy(page);
    setActiveTab(GuidesDetailsPagesFormTabItem.Edit);
  }, [page]);

  return (
    <SideDrawer open={page !== undefined} onClose={handleClose}>
      <GuidesDetailsPagesFormContainer
        page={pageCopy}
        originalPage={page}
        activeTab={activeTab}
        onSave={() => onSave(pageCopy)}
        onTabClick={tab => setActiveTab(tab)}
        onClose={goBack}
        handleClose={handleClose}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      >
        <TabTransition show={activeTab === GuidesDetailsPagesFormTabItem.Edit}>
          <GuidesDetailsPagesFormTabEdit
            page={pageCopy}
            onPageChange={(copy: IPage) => setPageCopy(copy)}
          />
        </TabTransition>
        <TabTransition show={activeTab === GuidesDetailsPagesFormTabItem.Settings}>
          <GuidesDetailsPagesFormTabSettings
            page={pageCopy}
            onCategoryChange={(category: ICategory) => {
              setPageCopy({
                ...pageCopy,
                oldCategoryId: pageCopy.category_id,
                category_id: category?.category_id,
              });
            }}
            onPageChange={(copy: IPage) => setPageCopy(copy)}
          />
        </TabTransition>
        <TabTransition show={activeTab === GuidesDetailsPagesFormTabItem.Preview}>
          <GPPreviewContainer globalPropertyId={propertyId} />
        </TabTransition>
      </GuidesDetailsPagesFormContainer>
    </SideDrawer>
  );
};

export default GuidesDetailsPagesForm;
