import { LogDataType } from '@operto/delivery-logs-shared';
import { trpc } from '@operto/trpc-client';
import { logger } from 'lib/logger';
import { useMemo } from 'react';

export default function useDeliveryLogs(searchParams: URLSearchParams, propertyIds?: string[]) {
  // Convert URLSearchParams to FiltersType
  const filters = {
    startDate: searchParams.get('scheduled_from') ?? undefined,
    endDate: searchParams.get('scheduled_until') ?? undefined,
    channelType: searchParams.get('channel') || undefined,
    status: searchParams.get('status') || undefined,
    propertyIds: propertyIds || undefined,
  };

  const {
    data: logs,
    isFetching,
    isLoading,
    refetch,
  } = trpc.communications.getLogs.useQuery<LogDataType[]>(filters, {
    retry(failureCount: number, error: { data: { httpStatus: number } }) {
      // retry if not unauth, forbidden or not found
      return failureCount < 3 && ![401, 403, 404].includes(error.data.httpStatus);
    },
  });

  const retryRefetch = async (retries = 5, delay = 1500) => {
    let lastData = logs;
    for (let attempt = 1; attempt <= retries; attempt++) {
      await new Promise(resolve => setTimeout(resolve, delay * attempt));
      try {
        const newData = await refetch();
        if (newData?.data && newData.data.length > (lastData?.length || 0)) {
          lastData = newData.data;
          return lastData;
        }
      } catch (error) {
        logger.error(`Refetch attempt ${attempt} failed`, error);
      }
    }
    return lastData;
  };

  const mappedDeliveryLogs = useMemo(
    () =>
      logs?.map((log: LogDataType) => {
        return {
          id: log.messageId,
          scheduledAt: new Date(log.scheduledAt),
          status: log.status,
          guestId: log.guest.id,
          guestName: log.guest.name,
          recipient: log.recipient,
          unitId: log.unit.id,
          unitName: log.unit.name,
          timezone: log.unit.timezone || 'UTC',
          templateId: log.template.id,
          templateName: log.template.name,
          channelType: log.template.channelType,
          messageBody: log.messageBody,
          deliveredAt: log.deliveredAt,
          jobId: log.jobId,
          reservationId: log.reservationId || log.reservation?.id,
        };
      }),
    [logs],
  );

  return {
    data: mappedDeliveryLogs,
    isFetching,
    isLoading,
    refetch: retryRefetch,
  };
}
