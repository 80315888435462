import { CloseOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  IconButton,
  Stack,
  SxProps,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import List from '@mui/material/List';
import ChatListItem from 'Common/Lists/ChatListItem';
import SearchField from 'Common/TextField/SearchField';
import useMessaging from 'hooks/useMessaging';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import { IMember } from 'member/memberType';
import { memberSelector } from 'member/state/memberSelectors';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import { currentUserSelector } from 'user/state/userSelectors';
import { MessageFilterIndex } from './MessagingPage';

export interface TaskMessengerAddListProps {
  currentMemberIds: string[];
  onTaskAddPeopleClose: () => void;
}

const TaskMessengerAddList = ({
  currentMemberIds,
  onTaskAddPeopleClose,
}: TaskMessengerAddListProps) => {
  const members = useAppSelector(memberSelector());
  const self = useAppSelector(currentUserSelector());
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { snackbar } = useSnackbar();
  const { addMembersToChannel, removeMemberFromChannel, broadcastMessage } = useMessaging();

  const channelId = pathname.split('/')[2] || '';
  const initialMemberIds = currentMemberIds.map(member => parseInt(member?.split('-')[1]));
  const [selectedMemberIds, setSelectedMemberIds] = useState(initialMemberIds);
  const [sortedMembers, setSortedMembers] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const hasSorted = useRef(false);

  const isChecked = (id: number) => selectedMemberIds.includes(id);
  const allMembers = members.filter(
    (row: IMember) => row?.name && row?.name.toLowerCase().includes(searchValue?.toLowerCase()),
  );

  const handleSelect = (memberId: number) => {
    let newMembers = [];
    newMembers = !selectedMemberIds.includes(memberId)
      ? [...selectedMemberIds, memberId]
      : selectedMemberIds.filter(member => {
          return member !== memberId;
        });
    setSelectedMemberIds(newMembers);
  };

  const handleClose = () => {
    onTaskAddPeopleClose();
  };

  const handleSave = async () => {
    setLoading(true);

    const initialSet = new Set(initialMemberIds);
    const selectedSet = new Set(selectedMemberIds);

    const addedMembers = [...selectedMemberIds].filter(member => !initialSet.has(member));
    const removedMembers = [...initialMemberIds].filter(member => !selectedSet.has(member));

    const addedMembersIds = addedMembers.map(id => `mid-${id}`);
    const removedMembersIds = removedMembers.map(id => `mid-${id}`);

    const formatNames = (names: string[]) => {
      const len = names.length;
      if (len === 0) return '';
      if (len === 1) return names[0];
      if (len === 2) return `${names[0]} and ${names[1]}`;
      return `${names.slice(0, -1).join(', ')}, and ${names[len - 1]}`;
    };

    try {
      if (addedMembers.length > 0) {
        await addMembersToChannel({ memberIds: addedMembersIds, channelId: channelId });

        const memberNames = allMembers
          .filter(member => addedMembers.includes(member.id))
          .map(member => member.name);

        await broadcastMessage({
          channelId: channelId,
          message: `${self?.name} added ${formatNames(memberNames)} to the chat`,
          messageType: 'system',
          metadata: { internalType: 'channel-members-updated' },
        });
      }

      if (removedMembers.length > 0) {
        for (const memberId of removedMembersIds) {
          await removeMemberFromChannel({ channelId: channelId, memberId: memberId });
        }

        const memberNames = allMembers
          .filter(member => removedMembers.includes(member.id))
          .map(member => member.name);

        await broadcastMessage({
          channelId: channelId,
          message: `${self?.name} removed ${formatNames(memberNames)} from the chat`,
          messageType: 'system',
          metadata: { internalType: 'channel-members-updated' },
        });
      }
    } catch (e) {
      logger.error(e);
      snackbar(t('failed_update'));
    } finally {
      onTaskAddPeopleClose();
      setLoading(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(e.currentTarget.value);
    hasSorted.current = false;
  };

  useEffect(() => {
    if (!hasSorted.current) {
      // sort by checkbox-filled members, then alphabetically
      const sorted = allMembers.sort((a, b) => {
        const aIsChecked = selectedMemberIds.includes(a.id);
        const bIsChecked = selectedMemberIds.includes(b.id);
        if (aIsChecked !== bIsChecked) {
          return +bIsChecked - +aIsChecked;
        }
        return a.name.localeCompare(b.name);
      });

      setSortedMembers(sorted);
      hasSorted.current = true;
    }
  }, [allMembers, selectedMemberIds]);

  return (
    <Stack sx={mainContainerStyles}>
      <LoadingContainer loading={loading} overlay={true} />
      <Box sx={headerStyles}>
        <Typography variant='h3'>{t('add_member_to_chat')}</Typography>

        <Stack direction='row' spacing={2} sx={buttonContainerStyles}>
          <Tooltip title={t('save')} slotProps={tooltipSlotProps}>
            <LoadingButton
              variant='contained'
              size='large'
              loading={loading}
              onClick={handleSave}
              sx={saveButtonStyles}
            >
              {t('save')}
            </LoadingButton>
          </Tooltip>

          <Tooltip title={t('close')} slotProps={tooltipSlotProps}>
            <IconButton size='large' onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
      </Box>

      <Box sx={searchbarStyles}>
        <SearchField onChange={handleSearch} value={searchValue} size='small' fullWidth />
      </Box>

      <List sx={listStyles}>
        {sortedMembers
          .filter(member => member?.id !== self?.id)
          .map((member: IMember) => {
            return (
              <ChatListItem
                key={member.id}
                checkboxOnClick={handleSelect}
                reservationId={member.id}
                contactName={member.name}
                memberExists={isChecked(member.id)}
                chatMemberType={MessageFilterIndex.MEMBERS}
              />
            );
          })}
      </List>
    </Stack>
  );
};

const mainContainerStyles: SxProps = {
  width: '100%',
  height: '100%',
  position: 'relative',
};

const headerStyles: SxProps = {
  height: '72px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 24px 12px 16px',
  borderBottom: `1px solid #15297A1F`,
};

const searchbarStyles: SxProps = {
  width: '100%',
  height: '74px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0px 8px',
};

const listStyles = {
  overflowY: 'auto',
  padding: '0 16px',
};

const buttonContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const saveButtonStyles: SxProps = {
  border: '1px solid #155EFF80',
  borderRadius: '6px',
  height: '36px',
};

const tooltipSlotProps = {
  popper: {
    sx: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
        marginTop: '2px',
      },
    },
  },
};

export default TaskMessengerAddList;
