import { Avatar, Box, SxProps } from '@mui/material';
import { useMedia } from '@operto/ui';
import ChannelListItem from 'Common/Lists/ChannelListItem';
import { formatRelativeToTimeZone } from 'helper/date';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Channel } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';
import { useAppDispatch } from '../../redux/hooks';
import { tasksChatPropsType } from './ChannelMessagesList';
import { updateFavouriteAction } from './MessagingActions';

export interface MessageChatPreviewContainerProps {
  previousChannelId?: string;
  channel?: Channel;
  onNewChat?: (newChannel: boolean) => void;
  lastClickedOnChannel?: (channel: Channel) => void;
  hasCurrentChannel?: boolean;
  showFavouriteIcon: boolean;
  tasksChatProps?: tasksChatPropsType[];
}

const ChannelIcon = ({ name, imagePath }: { imagePath: string; name: string }) => {
  if (!name) {
    return <Avatar sx={unassignedAvatarStyles} />;
  }
  return <ProfileIcon imageUrl={imagePath} size={'medium'} fullName={name} />;
};

const MessageChanneListItem = (props: MessageChatPreviewContainerProps) => {
  const {
    previousChannelId,
    channel,
    onNewChat,
    lastClickedOnChannel,
    hasCurrentChannel,
    showFavouriteIcon,
    tasksChatProps,
  } = props;
  const { isDesktop, isMobile, isTablet } = useMedia();
  const { setActiveChannel } = useChatContext();
  const dispatch = useAppDispatch();
  const favourite = (channel?.data?.favourite as boolean) ?? false;
  const location = useLocation();
  const navigate = useNavigate();
  const itemRef = useRef<HTMLElement>(null);
  const delayTime = 300;

  const handleChannelOnClick = (channel: Channel) => {
    scrollToActiveItem.cancel();
    onNewChat(false);
    setActiveChannel(channel as never);
    lastClickedOnChannel(channel);
  };

  const calculateNameChars = (name: string): string => {
    if (isDesktop || isMobile) {
      return name?.slice(0, 30) + (name?.length >= 30 ? '...' : '');
    }

    if (isTablet) {
      const maxLength = hasCurrentChannel ? 20 : 90;
      return name?.slice(0, maxLength) + (name?.length >= maxLength ? '...' : '');
    }
  };

  const lastMessageText = channel.state.messages[channel.state.messages.length - 1]?.text || '';
  const titleText = channel.data?.name || 'Channel';
  const lastMessageAt = channel?.data?.last_message_at as string;
  const formattedTime = lastMessageAt ? formatRelativeToTimeZone(lastMessageAt, 'UTC') : '';

  const taskData = useMemo(() => {
    const taskId = channel?.id?.split('tid')[1];
    return tasksChatProps?.find(task => task.id === taskId);
  }, [channel?.id, tasksChatProps]);
  const captionInfo = useMemo(() => {
    if (taskData) {
      return { title: taskData?.unitName ?? '', url: `/units/${taskData?.unitId}` };
    }
    return {
      title: channel?.data?.['property_name'] as string,
      url: `/units/${channel?.data?.['property_id'] as number}`,
    };
  }, [channel?.data, taskData]);

  let onClickFavouriteTimeout: NodeJS.Timeout;
  const onClickFavouriteIcon = async () => {
    await dispatch(updateFavouriteAction(channel.id, !favourite));

    if (hasCurrentChannel && channel.id === (previousChannelId as string)) {
      clearTimeout(onClickFavouriteTimeout);
      onClickFavouriteTimeout = setTimeout(() => navigate(`/messenger/${channel.id}`), delayTime);
    }
  };

  const isActive = location.pathname.includes(channel.id);
  const scrollToActiveItem = debounce(() => {
    if (isActive) {
      itemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, delayTime);

  useEffect(() => {
    scrollToActiveItem();
    return () => {
      scrollToActiveItem.cancel();
    };
  }, [scrollToActiveItem]);

  return (
    <Box sx={{ background: isActive ? '#145fff14' : 'inherit' }} ref={itemRef} data-id={channel.id}>
      <ChannelListItem
        title={calculateNameChars(titleText)}
        onClick={() => {
          handleChannelOnClick(channel);
        }}
        captionInfo={captionInfo}
        message={lastMessageText}
        hasNewMessage={channel.countUnread() > 0}
        newMessages={channel.countUnread()}
        iconObject={
          <ChannelIcon
            name={taskData?.assigneeName || channel.data?.name}
            imagePath={channel.data?.image as string}
          />
        }
        rightSubtext={formattedTime}
        isFavourite={favourite}
        showFavouriteIcon={showFavouriteIcon}
        onClickFavouriteIcon={onClickFavouriteIcon}
      />
    </Box>
  );
};

const unassignedAvatarStyles: SxProps = {
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  width: '36px',
  height: '36px',
};

export default MessageChanneListItem;
