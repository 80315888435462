import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import { formatRelativeToTimeZone } from 'helper/date';
import getProperty from 'property/state/propertySelectors';
import { IPropertyAlert, IPropertyAlertOverview } from 'propertyAlerts/propertyAlertType';
import { getPropertiesAlerts } from 'propertyAlerts/state/propertyAlertActions';
import { propertyAlertsSelector } from 'propertyAlerts/state/propertyAlertSelectors';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Table, TableProps } from 'ui-library/Components/table/Table';
import LinkCell from './Components/LinkCell';
import MultipleAlertRenderer from './MultipleAlertRenderer';
import SingleAlertRenderer from './SingleAlertRenderer';

const TimeAgoTableCell = ({ alert }: { alert: IPropertyAlert }) => {
  const property = useAppSelector(getProperty(alert.property_id));
  const timezone = property?.timezone;
  return <Text.BodySmall>{formatRelativeToTimeZone(alert.time_ago, timezone)}</Text.BodySmall>;
};

const PropertyAlertsDropDownTable = (props: Omit<TableProps, 'columns' | 'rows' | 'onFetch'>) => {
  const dispatch = useAppDispatch();
  const propertyAlerts: IPropertyAlertOverview[] = useAppSelector(propertyAlertsSelector(''));

  const columns = (): GridColDef<IPropertyAlertOverview>[] => [
    {
      field: 'property_name',
      headerName: 'Unit Name',
      width: 120,
      renderCell: unitNameRenderer,
    },
    {
      field: 'alert',
      headerName: 'Alert',
      width: 120,
      sortable: false,
      renderCell: alertColumnRenderer,
    },
    {
      field: 'time_ago',
      headerName: 'Last Activity',
      width: 180,
      sortable: false,
      renderCell: lastActivityRenderer,
    },
  ];

  const alertColumnRenderer = (params: GridRenderCellParams<unknown, IPropertyAlertOverview>) => {
    if (params.row.alerts && params.row.alerts.length > 1) {
      return (
        <MultipleAlertRenderer
          rowData={params.row}
          key={`${params.row?.property_id}_${params.row?.property_name}`}
        />
      );
    }
    if (params.row.alerts && params.row.alerts.length === 1) {
      return <SingleAlertRenderer rowData={params.row.alerts[0]} />;
    } else {
      return <div style={{ textTransform: 'capitalize' }}>-</div>;
    }
  };

  const lastActivityRenderer = (params: GridRenderCellParams) => {
    return <TimeAgoTableCell alert={params.row.alerts[0]} />;
  };

  const unitNameRenderer = (params: GridRenderCellParams) => {
    return <LinkCell text={params.row.property_name} url={`units/${params.row.property_id}`} />;
  };

  useEffect(() => {
    dispatch(getPropertiesAlerts({ pageNum: 0, numPerPage: 999, keyword: '' }));
  }, [dispatch]);

  return (
    <Table
      rows={propertyAlerts}
      columns={columns()}
      getRowId={row => row.property_id}
      hideFooter={true}
      {...props}
    />
  );
};

export default PropertyAlertsDropDownTable;
