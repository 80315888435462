import { Close } from '@mui/icons-material';
import { IconButton, Stack, SxProps, Tooltip, tooltipClasses, Typography } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

export default function CopilotPanelTitlebar({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();

  return (
    <Stack sx={headerContainerStyles}>
      <Tooltip title={t('close')} slotProps={tooltipSlotProps}>
        <IconButton onClick={onClose} sx={closeButtonStyles}>
          <Close fontSize='medium' data-testid='copilot-close-button' />
        </IconButton>
      </Tooltip>

      <Typography variant='h3-700'>{t('instant_reply')}</Typography>
    </Stack>
  );
}

const headerContainerStyles: SxProps = {
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: '100%',
};

const closeButtonStyles: SxProps = { margin: '8px', '& .button': { padding: '12px' } };

const tooltipSlotProps = {
  popper: {
    sx: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
        marginTop: '2px',
      },
    },
  },
};
