import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Autocomplete, Box, MenuItem } from '@mui/material';
import { Task } from '@operto/tasks-shared';
import { IGuest } from 'guest/guestType';
import useTranslation from 'hooks/useTranslation';
import MemberProfile from 'member/memberProfileGetter';
import { IMember } from 'member/memberType';
import { Property } from 'property/propertyType';
import React from 'react';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import { IReservation } from 'reservation/reservationType';
import { FormTextField } from 'ui-library/Components/input/FormTextField';
import { TaskOptions } from './TasksDetails';

type TasksFieldProps = {
  value: IGuest | Property | IReservation | IMember | TaskOptions;
  options: IGuest[] | Property[] | IReservation[] | IMember[] | TaskOptions[];
  control: Control<Task>;
  isEnabled?: boolean;
  fieldName: string;
  fieldLabel: string;
  disabled?: boolean;
  showMember?: boolean;
  customProps?: Record<string, unknown>;
};

export const TasksFieldSelector = ({
  value,
  options,
  control,
  isEnabled = true,
  fieldName,
  fieldLabel,
  showMember = false,
  customProps,
}: TasksFieldProps) => {
  const { t } = useTranslation();

  const handleInputChange = (inputValue: string, field: ControllerRenderProps) => {
    if (!options.some(option => option?.name === inputValue || String(option?.id) === inputValue))
      field.onChange(undefined);
  };

  const renderMemberProfile = (member: IMember) => {
    return (
      member?.id && (
        <Box sx={{ paddingX: 0.5 }}>
          <MemberProfile size='medium' memberData={member as IMember} />
        </Box>
      )
    );
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          value={value || null}
          options={options}
          getOptionLabel={option => option?.name || String(option?.id) || ''}
          onChange={(_, newOption) => field.onChange(newOption?.id)}
          onInputChange={(_, newInput) => handleInputChange(newInput, field)}
          renderInput={params => (
            <FormTextField
              {...params}
              field={fieldName}
              label={fieldLabel}
              control={control}
              InputProps={{
                ...params.InputProps,
                startAdornment: showMember ? renderMemberProfile(value as IMember) : null,
              }}
              InputLabelProps={{ shrink: true }}
              data-testid={`tasks-${fieldName}`}
              {...customProps}
            />
          )}
          renderOption={(props, option) => (
            <MenuItem {...props} key={`${fieldName}-${option.id}`} value={option.id}>
              {option?.name || String(option?.id) || ''}
            </MenuItem>
          )}
          disabled={!isEnabled}
          clearOnEscape
          clearIcon={<CancelOutlinedIcon />}
          noOptionsText={t('no_results_found')}
        />
      )}
    />
  );
};

export default TasksFieldSelector;
