import { Paper, Stack, SxProps, Theme } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridSlotsComponent,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { useMedia } from '@operto/ui';
import { ExportFormat } from 'helper/helper';
import React, { ReactNode } from 'react';
import { FiltersToolbar, FiltersToolbarProps } from './FiltersToolbar';
import { FiltersToolbarMobile, FiltersToolbarMobileProps } from './FiltersToolbarMobile';
import { SearchToolbar, SearchToolbarProps } from './SearchToolbar';
import { TableHeader } from './TableHeader';
import TableNoResultsFound from './TableNoResultsFound';
import { ColumnTitleTableTooltip } from './TableTooltip';

export type ExtendedSearchToolbarProps = SearchToolbarProps & {
  onExport?: (format: ExportFormat) => void;
  addTooltipValue?: string;
};

export type TableProps = DataGridProProps & {
  searchToolbarProps?: ExtendedSearchToolbarProps;
  filtersToolbarProps?: FiltersToolbarProps | FiltersToolbarMobileProps;
  initialState?: GridValidRowModel;
  renderHeader?: () => ReactNode;
  renderBody?: () => ReactNode;
};

type TableSxProps = SxProps<Theme> & {
  height?: string | number;
};

export const Table = ({
  sx,
  searchToolbarProps,
  filtersToolbarProps,
  components,
  initialState,
  renderHeader,
  renderBody,
  ...rest
}: TableProps) => {
  const { isMobile } = useMedia();

  const finalGridSlotComponents: Partial<GridSlotsComponent> = {
    ...defaultGridSlotComponents,
    ...components,
  };

  return (
    <>
      {searchToolbarProps && <SearchToolbar {...searchToolbarProps} />}
      <Paper
        elevation={0}
        sx={{
          height: (sx as TableSxProps)?.height ?? '100%',
          overflow: 'clip',
        }}
      >
        {renderBody ? (
          renderBody()
        ) : (
          <Stack height='100%'>
            {renderHeader && renderHeader()}
            {filtersToolbarProps &&
              (isMobile ? (
                <FiltersToolbarMobile {...(filtersToolbarProps as FiltersToolbarMobileProps)} />
              ) : (
                <FiltersToolbar {...(filtersToolbarProps as FiltersToolbarProps)} />
              ))}
            <DataGridPro
              disableColumnMenu
              sx={tableStyles(sx) as TableSxProps}
              components={finalGridSlotComponents}
              {...rest}
              initialState={initialState}
            />
          </Stack>
        )}
      </Paper>
    </>
  );
};

Table.Header = TableHeader;

const defaultGridSlotComponents: Partial<GridSlotsComponent> = {
  BaseTooltip: ColumnTitleTableTooltip,
  NoResultsOverlay: TableNoResultsFound,
  NoRowsOverlay: TableNoResultsFound,
};

const tableStyles = (sx: SxProps<Theme>): SxProps<Theme> => ({
  ...sx,
  marginX: 2,
  border: 0,
  height: '100%',
  '.MuiDataGrid-columnSeparator': {
    color: 'transparent',
  },
});
