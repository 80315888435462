import { Box } from '@mui/material';
import { useMedia } from '@operto/ui';
import { getMembers } from 'member/state/memberActions';
import { memberSelector } from 'member/state/memberSelectors';
import useTasks from 'Pages/Tasks/useTasks';
import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getReservations } from 'reservation/state/reservationActions';
import { getAllReservationsByIds } from 'reservation/state/reservationSelectors';
import { ChannelSort, DefaultGenerics, Channel as TChannel } from 'stream-chat';
import { ChannelList, useChatContext } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import MessageChannelListContainer from './MessageChannelListContainer';
import MessageChanneListItem from './MessageChannelListItem';
import { MessageFilterIndex } from './MessagingPage';

interface ChannelMessagesListProps {
  previousChannelId?: string;
  currentChannelId?: string;
  handleNewChat: (onNewChat: boolean) => void;
  handleChannelOnClick: (channel: TChannel) => void;
  handleFilterToggle: (filterIndex: MessageFilterIndex) => object;
  filterIndex: MessageFilterIndex;
  children: React.ReactElement;
  activeChannel: TChannel<DefaultGenerics>;
}

export type tasksChatPropsType = {
  id: string;
  unitName?: string;
  unitId: string;
  assigneeName?: string;
};

const ChannelMessagesList = ({
  previousChannelId,
  activeChannel,
  handleNewChat,
  handleChannelOnClick,
  handleFilterToggle,
  filterIndex,
  children,
}: ChannelMessagesListProps) => {
  const dispatch = useAppDispatch();
  const { isDesktop, isTablet, isMobile } = useMedia();
  const reservationOptions = useAppSelector(getAllReservationsByIds());
  const membersOptions = useAppSelector(memberSelector());
  const { setActiveChannel } = useChatContext();
  const { data: tasks } = useTasks({ fetchOnMount: true, refetchOnWindowFocus: false });

  const options = { state: true, presence: true };
  const sort: ChannelSort = { last_updated: -1 };
  const maxWidth = () => {
    if (isDesktop) return '388px';
    if (isTablet && activeChannel) return '298px';
    return 'none';
  };

  const display = () => {
    if (isDesktop) return 'flex';
    if (isMobile && activeChannel) return 'none';
    return 'flex';
  };

  const tasksChatProps: tasksChatPropsType[] = useMemo(
    () =>
      tasks?.map(task => {
        const reservation = reservationOptions.find(
          reservation => +reservation?.id === +task?.reservationId,
        );
        const assigneeName = membersOptions.find(member => +member?.id === +task?.assigneeId)?.name;

        return {
          id: task.id,
          unitName: task?.unit,
          unitId: String(reservation?.property_id),
          assigneeName: assigneeName,
        };
      }),
    [membersOptions, reservationOptions, tasks],
  );

  useEffect(() => {
    dispatch(getReservations());
    dispatch(getMembers());
  }, [dispatch]);

  useEffect(() => {
    setActiveChannel(activeChannel as TChannel<DefaultStreamChatGenerics>);
  }, [activeChannel, setActiveChannel]);

  return (
    <Box
      sx={{
        maxWidth: maxWidth(),
        width: '100%',
        display: display(),
        flexDirection: 'column',
      }}
    >
      {children}
      <ChannelList
        allowNewMessagesFromUnfilteredChannels={false}
        List={MessageChannelListContainer}
        setActiveChannelOnMount={false}
        Preview={props => (
          <MessageChanneListItem
            previousChannelId={previousChannelId}
            onNewChat={handleNewChat}
            lastClickedOnChannel={handleChannelOnClick}
            hasCurrentChannel={!!activeChannel}
            showFavouriteIcon={filterIndex === MessageFilterIndex.GUESTS}
            tasksChatProps={tasksChatProps}
            {...props}
          />
        )}
        filters={handleFilterToggle(filterIndex)}
        sort={sort}
        options={options}
      />
    </Box>
  );
};

export default ChannelMessagesList;
