import { Typography } from '@mui/material';
import React from 'react';
import { StreamMessage } from 'stream-chat-react';
import { BaseSystemMessage } from './BaseSystemMessage';

interface SystemMessageProps {
  message: StreamMessage;
}

export const ChannelMembersSystemMessage = ({ message }: SystemMessageProps) => {
  const content = <Typography variant='caption'>{message.text}</Typography>;

  return <BaseSystemMessage date={message.created_at?.toLocaleString() || ''} content={content} />;
};
