export enum Actions {
  // Alert Actions
  hydrateAlerts = 'HYDRATE_ALERTS',
  hydrateActiveAlerts = 'HYDRATE_ACTIVE_ALERTS',

  // Codes Actions
  addTeamCode = 'ADD_TEAM_CODE',
  deleteCode = 'DELETE_CODE',
  hydrateCodes = 'HYDRATE_CODES',
  hydrateCode = 'HYDRATE_CODE',
  upsertCode = 'UPSERT_CODE',
  upsertCodes = 'UPSERT_CODES',
  deleteBackupCode = 'DELETE_BACKUP_CODE',
  deleteBackupCodePending = 'DELETE_BACKUP_CODE_PENDING',
  deleteBackupCodeSuccess = 'DELETE_BACKUP_CODE_SUCCESS',
  deleteBackupCodeFailed = 'DELETE_BACKUP_CODE_FAILED',
  deleteBackupCodeCancelled = 'DELETE_BACKUP_CODE_CANCEL',
  deleteBackupCodeReset = 'DELETE_BACKUP_CODE_RESET',
  makeAvailablePending = 'MAKE_AVAILABLE_PENDING',
  makeAvailableSuccess = 'MAKE_AVAILABLE_SUCCESS',
  makeAvailableFailed = 'MAKE_AVAILABLE_FAILED',
  makeAvailableComplete = 'MAKE_AVAILABLE_COMPLETE',
  makeAvailableReset = 'MAKE_AVAILABLE_RESET',
  removeMakeAvailableId = 'REMOVE_MAKE_AVAILABLE_ID',

  // Devices
  deleteDevice = 'DELETE_DEVICE',
  hydrateDevices = 'HYDRATE_DEVICES',
  insertDevices = 'INSERT_DEVICES',
  upsertDevice = 'UPSERT_DEVICE',

  // Campaign Actions
  hydrateCampaigns = 'HYDRATE_CAMPAIGNS',
  deleteCampaign = 'DELETE_CAMPAIGN',
  insertCampaign = 'INSERT_CAMPAIGN',
  upsertCampaign = 'UPSERT_CAMPAIGN',

  // Company Actions
  storeCompanyInfoFromServer = 'STORE_COMPANY_INFO_FROM_SERVER',
  storeCompanyId = 'STORE_COMPANY_ID',
  storeCompanyActiveAlerts = 'STORE_ACTIVE_ALERTS',
  storeCompanyActiveAlertPropertyId = 'STORE_COMPANY_ACTIVE_ALERT_PROPERTY_ID',
  storeCompanyPastAlerts = 'STORE_PAST_ALERTS',
  storeCompanyPastAlertPropertyId = 'STORE_COMPANY_PAST_ALERT_PROPERTY_ID',
  storeMessengerSetting = 'STORE_MESSENGER_SETTING',
  warnToDisableSmartButtonTriggers = 'WARN_TO_DISABLE_SMART_BUTTON_TRIGGERS',
  dismissWarnToDisableSmartButtonTriggers = 'DISMISS_WARN_TO_DISABLE_SMART_BUTTON_TRIGGERS',
  storeCompanyMetaData = 'STORE_COMPANY_META_DATA',

  // Dashboard Configuration
  hydrateSettings = 'HYDRATE_SETTINGS',
  hydrateCheckInStats = 'HYDRATE_CHECK_IN_STATS',
  hydrateCheckOutStats = 'HYDRATE_CHECK_OUT_STATS',
  hydrateGuestCheckingStats = 'HYDRATE_GUEST_CHECKING_OUT_STATS',
  hydratePropertyAlertsStats = 'HYDRATE_PROPERTY_ALERTS_STATS',

  // Event Actions
  bulkAddEvents = 'BULK_ADD_EVENTS',
  hydrateEvents = 'HYDRATE_EVENTS',
  insertEvent = 'INSERT_EVENT',
  insertEvents = 'INSERT_EVENTS',
  updateEventsCounts = 'UPDATE_EVENTS_COUNTS',
  hydratePropertyAlerts = 'HYDRATE_PROPERTY_ALERTS',
  insertPropertyAlerts = 'INSERT_PROPERTY_ALERTS',
  updatePropertyAlertsCount = 'UPDATE_PROPERTY_ALERTS_COUNT',

  // Groups Actions
  addTeamGroup = 'ADD_TEAM_GROUP',
  deleteGroup = 'DELETE_GROUP',
  hydrateGroups = 'HYDRATE_GROUPS',
  insertGroups = 'INSERT_GROUPS',
  upsertGroup = 'UPSERT_GROUP',
  updateGroup = 'UPDATE_GROUP',
  updateGroupAttachedUnits = 'UPDATE_GROUPS_ATTACHED_UNITS',
  updateGroupCounts = 'UPDATE_GROUP_COUNTS',
  updateGroupType = 'UPDATE_GROUP_TYPE',

  // Integrations Actions
  hydrateIntegrations = 'HYDRATE_INTEGRATIONS',
  upsertIntegrations = 'UPSERT_INTEGRATIONS',

  // Intercom Actions
  upsertIntercom = 'UPSERT_INTERCOM',
  showUpdateIntercom = 'SHOW_UPDATE_INTERCOM',
  hideUpdateIntercom = 'HIDE_UPDATE_INTERCOM',

  // Members Actions
  upsertMembers = 'UPSERT_MEMBERS',
  insertMember = 'INSERT_MEMBER',
  insertMembers = 'INSERT_MEMBERS', // deprecated using UPSERT_MEMBERS
  addTeamMember = 'ADD_TEAM_MEMBER',
  deleteMember = 'DELETE_MEMBER',
  disableMember = 'DISABLE_MEMBER',
  enableMember = 'ENABLE_MEMBER',
  hydrateMembers = 'HYDRATE_MEMBERS', // deprecated using UPSERT_MEMBERS
  addPropertiesToMember = 'ADD_PROPERTIES_TO_MEMBER',
  upsertMember = 'UPSERT_MEMBER',
  updateMembers = 'UPDATE_MEMBER',
  updateMembersExcludeGroup = 'UPDATE_MEMBERS_EXCLUDE_GROUP',
  addMembers = 'ADD_MEMBERS',
  refreshMemberCode = 'REFRESH_MEMBER_CODE',
  updateMemberCounts = 'UPDATE_MEMBER_COUNTS',

  // Guests Actions
  hydrateGuests = 'HYDRATE_GUESTS',
  updateGuestsCounts = 'UPDATE_GUEST_COUNTS', // deprecated using "updateGuestsMeta"
  insertGuests = 'INSERT_GUESTS',
  upsertGuest = 'UPSERT_GUEST',
  deleteGuest = 'DELETE_GUEST',
  updateGuestsMeta = 'UPDATE_GUEST_META',

  // Notification Actions
  deleteNotification = 'DELETE_NOTIFICATION',
  insertNotification = 'INSERT_NOTIFICATION',
  upsertNotification = 'UPSERT_NOTIFICATION',
  updateNetatmoNotification = 'UPDATE_NETATMO_NOTIFICATION',
  updateNoiseAwareNotification = 'UPDATE_NOISEAWARE_NOTIFICATION',

  updatingCleaningNote = 'UPDATING_CLEANING_NOTE',
  updatedCleaningNote = 'UPDATING_CLEANING_NOTE',

  // Netatmo Actions
  hydrateNetatmoThresholds = 'HYDRATE_NETATMO_THRESHOLDS',
  changeNetatmoIndexTab = 'CHANGE_NETATMO_INDEX_TAB',
  insertNetatmoData = 'INSERT_NETATMO_DATA',
  insertMultipleNetatmoDataByDevice = 'INSERT_NETATMO_DATA_BY_DEVICE_ID',
  insertMultipleNetatmoData = 'INSERT_MULTIPLE_NETATMO_DATA',
  insertHistoricalNetatmoData = 'INSERT_HISTORICAL_NETATMO_DATA',
  insertHistoricalNetatmoDataByDevice = 'INSERT_HISTORICAL_NETATMO_DATA_BY_DEVICE',
  addHistoricalNetatmoData = 'ADD_MORE_HISTORICAL_DATA',
  beginNetatmoThresholdUpdate = 'BEGIN_NETATMO_THRESHOLD_UPDATE',
  endNetatmoThresholdUpdate = 'END_NETATMO_THRESHOLD_UPDATE',
  netatmoThresholdUpdateSuccess = 'NETATMO_THRESHOLD_UPDATE_SUCCESS',
  netatmoThresholdUpdateFailed = 'NETATMO_THRESHOLD_UPDATE_FAILED',
  insertHistorical = 'INSERT_HISTORICAL',
  hydrateDeviceSettings = 'HYDRATE_DEVICE_SETTINGS',
  insertDeviceSettings = 'INSERT_DEVICE_SETTINGS',

  hydrateThresholds = 'HYDRATE_THRESHOLDS',
  updateThresholdNotification = 'UPDATE_THRESHOLD_NOTIFICATIONS',
  beginThresholdUpdate = 'BEGIN_THRESHOLD_UPDATE',
  endThresholdUpdate = 'END_THRESHOLD_UPDATE',
  thresholdUpdateSuccess = 'THRESHOLD_UPDATE_SUCCESS',
  thresholdUpdateFailed = 'THRESHOLD_UPDATE_FAILED',

  // NoiseAwareActionss
  hydrateNoiseAwareThresholds = 'HYDRATE_NOISEAWARE_THRESHOLDS',
  changeNoiseAwareIndexTab = 'CHANGE_NOISEAWARE_INDEX_TAB',
  insertNoiseAwareData = 'INSERT_NOISEAWARE_DATA',
  insertNoiseAwareDataByDevice = 'INSERT_NOISEAWARE_DATA_BY_DEVICE_ID',
  insertMultipleNoiseAwareData = 'INSERT_MULTIPLE_NOISEAWARE_DATA',
  insertHistoricalNoiseAwareData = 'INSERT_HISTORICAL_NOISEAWARE_DATA',
  insertHistoricalNoiseAwareDataByDevice = 'INSERT_HISTORICAL_NOISEAWARE_DATA_BY_DEVICE',
  addHistoricalNoiseAwareData = 'ADD_MORE_HISTORICAL_DATA',
  beginNoiseAwareThresholdUpdate = 'BEGIN_NOISEAWARE_THRESHOLD_UPDATE',
  endNoiseAwareThresholdUpdate = 'END_NOISEAWARE_THRESHOLD_UPDATE',
  noiseAwareThresholdUpdateSuccess = 'NOISEAWARE_THRESHOLD_UPDATE_SUCCESS',
  noiseAwareThresholdUpdateFailed = 'NOISEAWARE_THRESHOLD_UPDATE_FAILED',

  // Overview Actions
  hydrateOverview = 'HYDRATE_OVERVIEW',
  // Payment Method Actions
  hydratePaymentMethod = 'HYDRATE_PAYMENT_METHOD',
  storeStripeToken = 'STORE_STRIPE_TOKEN',

  // Property Actions
  deleteProperty = 'DELETE_PROPERTY',
  hydrateProperties = 'HYDRATE_PROPERTIES', // deprecated - use UPSERT_PROPERTIES
  insertProperties = 'INSERT_PROPERTIES', // deprecated - use UPSERT_PROPERTIES
  upsertProperties = 'UPSERT_PROPERTIES',
  insertProperty = 'INSERT_PROPERTY',
  upsertProperty = 'UPSERT_PROPERTY',
  storePropertyId = 'STORE_PROPERTY_ID',
  updateProperty = 'UPDATE_PROPERTY',
  commonPropertyLinks = 'COMMON_PROPERTY_LINKS',
  hydrateFavoriteProperties = 'HYDRATE_FAVORITE_PROPERTIES',
  insertFavoriteProperties = 'INSERT_FAVORITE_PROPERTY',
  deleteFavoriteProperty = 'DELETE_FAVORITE_PROPERTY',
  upsertFavoriteProperty = 'UPSERT_FAVORITE_PROPERTY',
  favoritesLoading = 'FAVORITES_LOADING',
  favoritesSuccess = 'FAVORITES_SUCCESS',
  favoritesFailed = 'FAVORITES_FAILED',
  removeAddFavorite = 'REMOVE_ADD_FAVORITES',
  updatePropertyCleaningCounts = 'UPDATE_PROPERTY_CLEANING_COUNTS',
  updatePropertyCounts = 'UPDATE_PROPERTY_COUNTS',
  updatePropertyGroupTypeCounts = 'UPDATE_PROPERTY_GROUP_TYPE_COUNTS',

  // Common Properrties Actions
  hydrateCommonProperties = 'HYDRATE_COMMON_PROPERTIES',

  // Reservation Actions
  cancelReservation = 'CANCEL_RESERVATION',
  cancelPmsReservation = 'CANCEL_PMS_RESERVATION',
  changePropertyDetailTab = 'CHANGE_PROPERTY_DETAIL_TAB',
  hydrateReservation = 'HYDRATE_RESERVATION',
  upsertReservation = 'UPSERT_RESERVATION',
  storeReservation = 'STORE_RESERVATION',
  addReservations = 'ADD_RESERVATIONS',
  hydrateReservations = 'HYDRATE_RESERVATIONS',
  upsertGuestName = 'UPSERT_GUEST_NAME',
  addTeamReservation = 'ADD_TEAM_RESERVATION',
  deleteReservation = 'DELETE_RESERVATION',
  beginGuestVerificationUpdate = 'BEGIN_GUEST_VERIFICATION_UPDATE',
  endGuestVerificationUpdate = 'END_GUEST_VERIFICATION_UPDATE',
  guestVerificationUpdateSuccess = 'GUEST_VERIFICATION_UPDATE_SUCCESS',
  guestVerificationUpdateFailed = 'GUEST_VERIFICATION_UPDATE_FAILED',
  insertReservation = 'INSERT_RESERVATION',
  grantPmsReservation = 'GRANT_PMS_RESERVATION',

  // Role Actions
  deleteRole = 'DELETE_ROLE',
  insertRole = 'INSERT_ROLE',
  upsertRole = 'UPSERT_ROLE',

  // Temperature Setting Actions
  setTempSetting = 'SET_TEMP_SETTING',
  SettingTado = 'SET_TADO_TEMPERATURE_ON_DEVICE',

  // Template Actions
  hydrateTemplate = 'HYDRATE_TEMPLATE',
  updateTemplateItem = 'UPDATE_TEMPLATE_ITEM',

  // UI Actions
  // Lock and Unlock
  lock = 'LOCK',
  unlock = 'UNLOCK',
  dismissLockCommandBanner = 'DISMISS_LOCK_COMMAND_BANNER',
  updateLockStatus = 'UPDATE_LOCK_STATUS',

  // Hide Actions
  hideAddUnitToUnitGroup = 'HIDE_ADD_UNIT_TO_UNIT_GROUP',
  hideAddUnitsToMember = 'HIDE_ADD_UNITS_TO_MEMBER',
  hideAddGroupsToMember = 'HIDE_ADD_GROUPS_TO_MEMBER',
  hideAddGroupsToUnit = 'HIDE_ADD_GROUPS_TO_UNIT',
  hideMemberProfile = 'HIDE_MEMBER_PROFILE',
  hideServiceNotification = 'HIDE_SERVICE_NOTIFICATION',
  hideAddCode = 'HIDE_ADD_CODE',
  hideActivateUnits = 'HIDE_ACTIVATE_UNITS',
  hideViewMoreUnits = 'HIDE_VIEW_MORE_UNITS',
  hideViewMoreReservations = 'HIDE_VIEW_MORE_RESERVATIONS',
  hideViewMoreAlerts = 'HIDE_VIEW_MORE_ALERTS',
  hideCodeDetailView = 'HIDE_CODE_DETAIL_VIEW',
  hideAddReservation = 'HIDE_ADD_RESERVATION',
  hideRefreshLoader = 'HIDE_REFRESH_LOADER',
  hideSidebar = 'HIDE_SIDEBAR',
  hideBackupCode = 'HIDE_BACKUP_CODE',
  hideViewMoreFavorites = 'HIDE_VIEW_MORE_FAVORITES',
  hideViewMorePastAlerts = 'HIDE_VIEW_MORE_PAST_ALERTS',
  hideMakeAvailableBanner = 'HIDE_MAKE_AVAILABLE_BANNER',
  hideViewMoreCheckOuts = 'HIDE_VIEW_MORE_CHECKOUTS',

  // Show Actions
  setPropertyId = 'SET_PROPERTY_ID',
  showPropertyDetail = 'SHOW_PROPERTY_DETAIL',
  showServiceNotification = 'SHOW_SERVICE_NOTIFICATION',
  showViewMoreUnits = 'SHOW_VIEW_MORE_UNITS',
  showViewMoreReservations = 'SHOW_VIEW_MORE_RESERVATIONS',
  showViewMoreAlerts = 'SHOW_VIEW_MORE_ALERTS',
  showCodeDetailView = 'SHOW_CODE_DETAIL_VIEW',
  showMemberProfile = 'SHOW_MEMBER_PROFILE',
  showRefreshLoader = 'SHOW_REFRESH_LOADER',
  showViewMoreFavorites = 'SHOW_VIEW_MORE_FAVORITES',
  showViewMorePastAlerts = 'SHOW_VIEW_MORE_PAST_ALERTS',
  showMakeAvailableBanner = 'SHOW_MAKE_AVAILABLE_BANNER',
  showViewMoreCheckOuts = 'SHOW_VIEW_MORE_CHECKOUTS',

  // Toggle Actions
  toggleAddMemberToTeam = 'TOGGLE_ADD_MEMBER_TO_TEAM',
  toggleAddGroup = 'TOGGLE_ADD_GROUP',
  toggleConfirmationPopup = 'TOGGLE_CONFIRMATION_POPUP',
  toggleAddTeamMember = 'TOGGLE_ADD_TEAM_MEMBER',
  toggleAddProperty = 'TOGGLE_ADD_PROPERTY',
  toggleAddIntercom = 'TOGGLE_ADD_INTERCOM',
  toggleEditIntercom = 'TOGGLE_EDIT_INTERCOM',
  toggleAddIntegrationGuesty = 'TOGGLE_ADD_INTEGRATION_GUESTY',
  toggleAddIntegrationHostaway = 'TOGGLE_ADD_INTEGRATION_HOSTAWAY',
  toggleAddIntegrationMews = 'TOGGLE_ADD_INTEGRATION_MEWS',
  toggleAddIntegrationAvantio = 'TOGGLE_ADD_INTEGRATION_AVANTIO',
  toggleAddIntegrationKigo = 'TOGGLE_ADD_INTEGRATION_KIGO',
  toggleAddIntegrationWebRezPro = 'TOGGLE_ADD_INTEGRATION_WEBREZPRO',
  toggleAddIntegrationHostfully = 'TOGGLE_ADD_INTEGRATION_HOSTFULLY',
  toggleAddIntegrationLightmaker = 'TOGGLE_ADD_INTEGRATION_LIGHTMAKER',
  toggleAddIntegrationVRScheduler = 'TOGGLE_ADD_INTEGRATION_VRSCHEDULER',
  toggleAddIntegrationDirect = 'TOGGLE_ADD_INTEGRATION_DIRECT',
  toggleAddUnitToUnitGroup = 'TOGGLE_ADD_UNIT_TO_UNIT_GROUP',
  toggleUnitGroup = 'TOGGLE_UNIT_GROUP',
  toggleActivityFeed = 'TOGGLE_ACTIVITY_FEED',
  toggleAddGroupToMember = 'TOGGLE_ADD_GROUPS_TO_MEMBER',
  toggleAddUnitsToMember = 'TOGGLE_ADD_UNITS_TO_MEMBER',
  toggleAddTeamToMember = 'TOGGLE_ADD_TEAM_TO_MEMBER',
  toggleAddGroupsToUnit = 'TOGGLE_ADD_GROUPS_TO_UNIT',
  toggleAddNewReservation = 'TOGGLE_ADD_NEW_RESERVATION',
  toggleTempSetting = 'TOGGLE_TEMP_SETTING',
  toggleMobileSidebar = 'TOGGLE_MOBILE_SIDEBAR',
  toggleMemberProfileOptions = 'TOGGLE_MEMBER_PROFILE_OPTIONS',
  toggleLockPending = 'TOGGLE_LOCK_PENDING',
  toggleLockFailed = 'TOGGLE_LOCK_FAILED',
  toggleLockSuccess = 'TOGGLE_LOCK_SUCCESS',
  toggleUnLockPending = 'TOGGLE_UNLOCK_PENDING',
  toggleUnLockFailed = 'TOGGLE_UNLOCK_FAILED',
  toggleUnLockSuccess = 'TOGGLE_UNLOCK_SUCCESS',
  toggleLockPropertyId = 'TOGGLE_LOCK_PROPERTY_ID',
  toggleRefreshLoader = 'TOGGLE_REFRESH_LOADER',
  toggleRefreshLoaderSuccess = 'TOGGLE_REFRESH_LOADER_SUCCESS',
  toggleRefreshLoaderPending = 'TOGGLE_REFRESH_LOADER_PENDING',
  toggleRefreshLoaderFailed = 'TOGGLE_REFRESH_LOADER_FAILED',
  toggleMasterActivityFeed = 'TOGGLE_MASTER_ACTIVITY_FEED',
  toggleViewPastUnitAlerts = 'TOGGLE_VIEW_PAST_UNIT_ALERTS',
  toggleSlidebar = 'TOGGLE_SLIDEBAR',
  toggleSnackbar = 'TOGGLE_SNACKBAR',
  togglePanelSnackbar = 'TOGGLE_PANELSNACKBAR',
  toggleIntegrationSlideBar = 'TOGGLE_INTERGRATIONS_SLIDEBAR',

  // Replace Actions
  replaceTeamsAttachedMembers = 'REPLACE_TEAMS_ATTACHED_MEMBERS',
  replaceGroupAttachedUnits = 'REPLACE_GROUPS_ATTACHED_UNITS',

  // Units Actions
  activateUnits = 'ACTIVATE_UNITS',

  // User Actions
  identifyUser = 'IDENTIFY_USER',
  storeToken = 'STORE_TOKEN',
  logout = 'LOGOUT',
  pendingUpdatePassword = 'PENDING_UPDATED_PASSWORD',
  updatedPassword = 'UPDATED_PASSWORD',
  failedUpdatedPassword = 'FAILED_UPDATED_PASSWORD',
  resetUpdatePassword = 'RESET_UPDATE_PASSWORD',
  storeGetStreamUser = 'STORE_GETSTREAM_USER',
  storeFeatures = 'STORE_FEATURES',
  storeRole = 'STORE_ROLE',

  // PMS Actions
  guestyPropertyImportComplete = 'GUESTY_PROPERTY_IMPORT_SUCCESS',
  cloudbedsPropertyImportComplete = 'CLOUDBEDS_PROPERTY_IMPORT_SUCCESS',
  myVRPropertyImportComplete = 'MY_VR_PROPERTY_IMPORT_SUCCESS',
  hostawayPropertyImportComplete = 'HOST_AWAY_PROPERTY_IMPORT_SUCCESS',
  mewsPropertyImportComplete = 'MEWS_PROPERTY_IMPORT_SUCCESS',
  hydrateSensors = 'HYDRATE_SENSORS',

  // shared Actions
  hydrateCheckins = 'HYDRATE_CHECKINS',
  hydrateActivities = 'HYDRATE_ACTIVITIES',

  // Guest Portal Actions
  guestAuthenticate = 'GUEST_AUTHENTICATE',
  updateRoomStatus = 'UPDATE_ROOM_STATUS',
  updateGuestProfile = 'UPDATE_GUEST_PROFILE',
  updateGuestCounts = 'updateGuestCounts',

  // refresh unit member screen after adding members
  refreshUnitMembersTable = 'REFRESH_UNIT_MEMBERS_TABLE',
}
