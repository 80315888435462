import { SxProps } from '@mui/material';
import { LogDataType } from '@operto/delivery-logs-shared';
import { Loading } from 'Common/Loading';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toggleSlidebar } from 'redux/actions/ui';
import { resetRefetch } from 'redux/delivery-logs/slice';
import { useAppDispatch } from 'redux/hooks';
import { ApplicationState } from 'redux/reducers';
import { INITIAL_STATE, useGetUnitsQuery } from 'redux/units/api-slice';
import { UnitFilterType } from 'redux/units/types';
import { SlidebarType } from 'types/ui';
import {
  PaginatedTable,
  getSortModelByUrl,
  sortTableByUrl,
} from 'ui-library/Components/table/PaginatedTable';
import DeliveryLogsTitlebar from './DeliveryLogsTitlebar';
import useDeliveryLogFilters from './useDeliveryLogFilters';
import useDeliveryLogs from './useDeliveryLogs';
import useDeliveryLogsColumns from './useDeliveryLogsColumns';

const PAGE_NUM = 0;
const PAGE_SIZE = 100;

const DeliveryLogsTable = () => {
  const firstLoad = useRef(true);
  const dispatch = useAppDispatch();
  const [searchString, setSearchString] = useState('');
  const deliveryLogsFilters = useDeliveryLogFilters();
  const columns = useDeliveryLogsColumns();
  const { data: units = INITIAL_STATE, isFetching: unitsFetching } = useGetUnitsQuery({
    filterType: UnitFilterType.ALL_UNITS,
    numPerPage: 100000,
  });

  //get the property ids from total properties as an array of string
  const propertyIds = units.units.map(unit => unit.id.toString());

  const {
    data: deliveryLogsData,
    isFetching,
    isLoading,
    refetch: refetchDeliveryLogs,
  } = useDeliveryLogs(deliveryLogsFilters.searchParams, propertyIds);
  const [filteredLogs, setFilteredLogs] = useState<LogDataType>(deliveryLogsData ?? []);
  const shouldRefetchFlag = useSelector((state: ApplicationState) => state.deliveryLogs.refetch);

  const handleSearch = useCallback(
    (pageNum: number, numPerPage: number, searchString?: string) => {
      setSearchString(searchString || '');

      if (!searchString?.length) {
        return setFilteredLogs(deliveryLogsData);
      }
      const lowerCaseSearchString = searchString.toLowerCase();
      const filteredData = deliveryLogsData?.filter((row: LogDataType) =>
        ['guestName', 'recipient', 'unitName', 'unitId', 'templateName'].some(key =>
          row[key]?.toString().toLowerCase().includes(lowerCaseSearchString),
        ),
      );
      setFilteredLogs(filteredData);
    },
    [deliveryLogsData],
  );

  const onRowClick = (row: unknown) => {
    // Workaround to avoid console error when dispatching row:
    // ui.ts:190 A non-serializable value was detected in an action, in the path:
    // `content.delivery-logs-details.content.columns.0.sortComparator`
    const serializableRow = JSON.parse(JSON.stringify(row));
    dispatch(resetRefetch());
    dispatch(toggleSlidebar(SlidebarType.DELIVERY_LOGS_DETAILS, serializableRow));
  };

  useEffect(() => {
    if (shouldRefetchFlag) {
      const performRefetch = async () => {
        dispatch(resetRefetch()); // Reset refetch flag
        await refetchDeliveryLogs();
      };
      performRefetch();
    }
  }, [shouldRefetchFlag, refetchDeliveryLogs, dispatch]);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    }

    // If searchParams (filters) changes, we need to handle search again
    handleSearch(PAGE_NUM, PAGE_SIZE, searchString);
  }, [handleSearch, searchString]);

  if (firstLoad.current && (unitsFetching || isLoading)) {
    return <Loading />;
  }

  return (
    <>
      <DeliveryLogsTitlebar />
      <PaginatedTable
        enableToolbar
        rows={filteredLogs ?? []}
        columns={columns}
        filtersToolbarProps={deliveryLogsFilters}
        onSortModelChange={model =>
          deliveryLogsFilters.onFilterChange(
            sortTableByUrl(model, deliveryLogsFilters.searchParams),
          )
        }
        sortModel={getSortModelByUrl(deliveryLogsFilters.searchParams)}
        loading={!deliveryLogsFilters.isSynched || (isFetching && !deliveryLogsData)}
        onRowClick={onRowClick}
        sx={rowStyles}
        onFetch={handleSearch}
        getRowId={row => row.id}
      />
    </>
  );
};

const rowStyles: SxProps = {
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
};

export default DeliveryLogsTable;
