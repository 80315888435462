import { Store } from '@reduxjs/toolkit';
import { validateCognitoToken } from 'api/tokenAPI';
import { logger } from 'lib/logger';
import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { userSelector } from 'user/state/userSelectors';
import auth, { isCognitoToken } from './auth';

type AuthComponentProps = {
  children: ReactNode;
  store: Store;
};

export const AuthComponent = ({ store, children }: AuthComponentProps) => {
  const { accessToken } = useAppSelector(userSelector());
  const [state, setState] = useState({
    redirect: false,
    token: '',
    authenticatedAccordingToAuthFunc: false,
  });

  useEffect(() => {
    const checkAuthState = () => {
      const authenticatedAccordingToAuthFunc = auth(store);
      if (!authenticatedAccordingToAuthFunc) {
        setState(prev => ({ ...prev, redirect: true }));
      } else {
        setState(prev => ({ ...prev, authenticatedAccordingToAuthFunc }));
      }
    };

    const checkAndRedirect = async () => {
      const getParams = new URLSearchParams(window.location.search);
      const token: string = getParams.get('token')
        ? (getParams.get('token') as string)
        : (localStorage.getItem('mpAuth') ?? '').slice(1, -1);
      if (token) {
        try {
          const isValid = await validateCognitoToken(token);
          if (isValid) {
            setState(prev => ({ ...prev, token }));
            localStorage.setItem('mpAuth', JSON.stringify(token));
          }
        } catch (err) {
          logger.error('Caught an error when validating the cognito token', err);
          if (err.response.status === 401) {
            setState(prev => ({ ...prev, redirect: true }));
          }
        }
        checkAuthState();
      } else {
        setState(prev => ({ ...prev, redirect: true }));
      }
    };

    checkAndRedirect();
  }, [store]);

  return (
    <>
      {state.authenticatedAccordingToAuthFunc && accessToken ? children : ''}
      {state.redirect && (
        <Navigate
          to={isCognitoToken(state.token) ? process.env.REACT_APP_AMPLIFY_APP_AUTH_URL : '/login'}
        />
      )}
    </>
  );
};
