import { CloseOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';

const defaultLength = 0;
// since helper text is shown as error text in error state
// need to check for error state and show error text as needed
const getHelperText = (
  value: string,
  error: boolean,
  errorText: string,
  maxLength: number,
): string => {
  if (shouldShowError(error, value, maxLength)) {
    return `${errorText} ${!value?.length ? defaultLength : value?.length}/${maxLength}`;
  } else {
    return `${!value?.length ? defaultLength : value?.length}/${maxLength}`;
  }
};

// if you want to show something like 31/30, set forceMaxLenth to false
const getMaxLength = (forceMaxLength: boolean, maxLength: number): number => {
  if (forceMaxLength) {
    return maxLength;
  } else {
    return null;
  }
};

const shouldShowError = (error: boolean, value: string, maxLength: number): boolean => {
  return error || value?.length > maxLength;
};

// TODO: this should use a TextFieldProps type instead of all these parameters
const renderTextField = (
  label: string,
  placeholder: string,
  value: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  maxLength: number,
  required = false,
  rows = 1,
  forceMaxLength = true,
  minRows = 1,
  maxRows = 4,
  error = false,
  errorText = 'Character limit exceeded.',
  testId?: string,
  handleClear?: () => void,
) => (
  <TextField
    required={required}
    label={label}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    multiline={maxRows > 1}
    fullWidth
    InputProps={{
      endAdornment: handleClear && value && (
        <InputAdornment position='end'>
          <IconButton
            onClick={handleClear}
            aria-label='clear-text-btn'
            edge='end'
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.54)', // Semi-transparent dark background
              marginRight: '0px',
              width: '24px',
              height: '24px',
            }}
          >
            <CloseOutlined
              style={{ color: 'rgba(255,255,255,1)', width: '18px', height: '18px' }}
            />
          </IconButton>
        </InputAdornment>
      ),
    }}
    inputProps={{
      maxLength: getMaxLength(forceMaxLength, maxLength),
    }}
    helperText={getHelperText(value, error, errorText, maxLength)}
    FormHelperTextProps={{ sx: { textAlign: 'right' } }}
    error={shouldShowError(error, value, maxLength)}
    rows={rows}
    minRows={!rows ? minRows : undefined}
    maxRows={!rows ? maxRows : undefined}
    InputLabelProps={{ shrink: value?.length > 0 || placeholder !== '' }} // Ensures the label shrinks when there's a value
    data-testid={testId}
  />
);

export default renderTextField;
