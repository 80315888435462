import { Box } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { Task } from '@operto/tasks-shared';
import { companySelector } from 'company/state/companySelectors';
import { endOfDay, formatISO, parseISO, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { ClientContext } from 'helper/streamChatHelper';
import useTasksFilters from 'hooks/useTasksFilters';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import { IMember, MemberFilterType } from 'member/memberType';
import { getMembersByCompany } from 'member/state/memberActions';
import { membersByIdsSelector, memberSelector } from 'member/state/memberSelectors';
import React, {
  useCallback,
  useContext,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { ChannelSort, Channel as TChannel } from 'stream-chat';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { userSelector } from 'user/state/userSelectors';
import { createEventEmmiter } from './../../shared/event';
import TasksDetails from './TasksDetails';
import columns from './TasksTableColumns';
import TasksTitlebar from './TasksTitlebar';
import { TaskSummaryCard } from './TaskSummaryCard';
import useTasks, {
  CANCELED_STATUS,
  CLOSED_STATUS,
  COMPLETED_STATUS,
  NOT_STARTED_STATUS,
  STARTED_STATUS,
} from './useTasks';

type TableEvent = 'refresh';
export const TaskTableEmitter = createEventEmmiter<TableEvent>();

const PAGE_NUM = 0;
const PAGE_SIZE = 100;

type TaskWithMsg = Task & {
  msgGroupCount?: number;
  msgGroupId?: string;
  msgMemberCount?: number;
  msgMemberId?: string;
};

export default function TasksTable({
  type = 'default',
  resID,
  onTaskClick,
}: {
  resID?: string;
  type?: 'messenger' | 'default';
  onTaskClick?: (row: GridRowParams<Task>) => void;
}) {
  const { clientObject } = useContext(ClientContext);
  const dispatch = useDispatch();
  const company = useAppSelector(companySelector());
  const allMembers = useAppSelector(memberSelector());
  const loggedInMember = useAppSelector(userSelector());
  const { t } = useTranslation();
  const firstLoad = useRef(true);
  const location = useLocation();
  const navigate = useNavigate();
  const channelListeners = useRef<{ unsubscribe: () => void }[]>([]);
  const [searchString, setSearchString] = useState('');
  const [filteredTasks, setFilteredTasks] = useState<TaskWithMsg[]>([]);
  const [showAddTaskPanel, setShowAddTaskPanel] = useState(false);
  const [editTaskId, setEditTaskId] = useState<string>();
  const [isFetchingChannels, setFetchingChannels] = useState<boolean>(false);
  const [assigneeFilterValues, setAssigneeFilterValues] = useState<IMember[] | undefined>();
  const tasksFilters = useTasksFilters(assigneeFilterValues);
  const statusSelected = useMemo(() => {
    const selectedFilerList = tasksFilters.searchParams.get('task_status')?.split(',') ?? [];
    if (selectedFilerList.length > 0) {
      const statusSet = selectedFilerList.reduce((acc, optionStatus) => {
        switch (optionStatus) {
          case 'not_started':
            NOT_STARTED_STATUS.forEach(status => acc.add(status));
            break;
          case 'started':
            STARTED_STATUS.forEach(status => acc.add(status));
            break;
          case 'completed':
            COMPLETED_STATUS.forEach(status => acc.add(status));
            break;
          case 'canceled':
            CANCELED_STATUS.forEach(status => acc.add(status));
            break;
        }
        return acc;
      }, new Set());

      return Array.from(statusSet);
    }
  }, [tasksFilters.searchParams]);

  const prioritySelected = tasksFilters.searchParams.get('task_priority')?.split(',');
  const assigneeSelected = tasksFilters.searchParams.get('task_assignee')?.split(',');

  const formatDateRange = (from: string, until: string) => {
    if (from && until) {
      return [formatISO(startOfDay(parseISO(from))), formatISO(endOfDay(parseISO(until)))];
    }
  };

  const dueDateFrom = tasksFilters.searchParams.get('due_date_from');
  const dueDateUntil = tasksFilters.searchParams.get('due_date_until');
  const dueDateSelected = formatDateRange(dueDateFrom, dueDateUntil);

  const requestDateFrom = tasksFilters.searchParams.get('request_date_from');
  const requestDateUntil = tasksFilters.searchParams.get('request_date_until');
  const requestDateSelected = formatDateRange(requestDateFrom, requestDateUntil);

  const {
    data: tasks,
    isLoading,
    isFetching,
    refetchTasks,
    getAssigneeIds,
  } = useTasks({
    statusFilter: statusSelected,
    priorityFilter: prioritySelected,
    assigneeFilter: assigneeSelected,
    dueDateFilter: dueDateSelected,
    requestDateFilter: requestDateSelected,
  });
  const assigneeIDsState = getAssigneeIds({ withFrequency: true, companyId: String(company.id) });
  const isDeferredDataLoading = useDeferredValue(isLoading || isFetching || isFetchingChannels);

  const queryChannelsPerTasks = useCallback(
    async (
      taskList: Task[],
    ): Promise<{ guestChannels: TChannel[]; staffTaskChannels: TChannel[] }> => {
      setFetchingChannels(true);
      if (taskList && taskList.length > 0) {
        const sort: ChannelSort = [{ last_updated: -1 }];
        const channelIdSet = new Set(
          taskList
            .filter(task => task.reservationId)
            .map(task => `${company.id}-${task.reservationId}`),
        );
        const listOfChannelIds = Array.from(channelIdSet.values());

        let filterByChannelIds = {};
        if (listOfChannelIds.length > 0) {
          filterByChannelIds = {
            id: {
              $in: listOfChannelIds,
            },
          };
        }
        const guestFilter = {
          ...filterByChannelIds,
          members: {
            $in: [loggedInMember.getStreamUser.id],
          },
        };
        const guestChannels = await clientObject.streamChatClient.queryChannels(guestFilter, sort, {
          watch: true,
          state: true,
        });

        const staffTasksFilter = {
          members: { $in: [loggedInMember?.getStreamUser?.id] },
          channel_data: 'tasks',
        };

        const staffTaskChannels = await clientObject.streamChatClient.queryChannels(
          staffTasksFilter,
          sort,
          {
            watch: true,
            state: true,
          },
        );

        return { guestChannels, staffTaskChannels };
      }
      return { guestChannels: [], staffTaskChannels: [] };
    },
    [clientObject.streamChatClient, company.id, loggedInMember.getStreamUser.id],
  );

  const taskList = useMemo(() => {
    const taskStatus = tasksFilters.searchParams.get('task_status');
    const taskData = (tasks ?? []).map(task => {
      if (task.reservationId) {
        task.msgGroupCount = 0;
        task.msgGroupId = `${company.id}-${task.reservationId}`;
      }
      if (task.assigneeId) {
        task.msgMemberCount = 0;
        task.msgMemberId = `cid${company.id}-tid${task.id}`;
      }
      return task;
    });
    if (taskStatus?.includes('overdue')) {
      const nowUtc = new Date();
      return taskData.filter(task => {
        const isOverdueStatus = !CLOSED_STATUS.includes(task.status);
        return (
          zonedTimeToUtc(task.dueDateLocalized, task.unitTimeZone || 'America/Vancouver') <
            nowUtc && isOverdueStatus
        );
      });
    }
    return taskData;
  }, [tasks, tasksFilters.searchParams, company.id]);

  const memberIds = taskList.map(task => +task.assigneeId);
  const members = useAppSelector(membersByIdsSelector(memberIds));

  const [summaryCardProps, setSummaryCardProps] = useState({});

  const handleSearch = useCallback(
    (pageNum: number, numPerPage: number, searchString?: string) => {
      setSearchString(searchString || '');

      if (!searchString?.length) {
        return setFilteredTasks(taskList);
      }

      const lowerCaseSearchString = searchString.toLowerCase();

      const filteredData = taskList?.filter(row => {
        return (
          row.priority?.toLowerCase()?.includes(lowerCaseSearchString) ||
          row.guest?.toLowerCase()?.includes(lowerCaseSearchString) ||
          row.unit?.toLowerCase()?.includes(lowerCaseSearchString) ||
          row.title?.toLowerCase()?.includes(lowerCaseSearchString) ||
          row.description?.toLowerCase()?.includes(lowerCaseSearchString) ||
          row.assignee?.toLowerCase()?.includes(lowerCaseSearchString)
        );
      });
      setFilteredTasks(filteredData);
    },
    [taskList],
  );

  const handleRowClick = ({ row }: GridRowParams<Task>) => {
    onTaskClick?.(row);
    setShowAddTaskPanel(true);
    setEditTaskId(row.id);
  };

  const handleCreateTask = () => {
    setShowAddTaskPanel(true);
    setEditTaskId(undefined);
  };

  const handleCreateTaskPanelClose = () => {
    setShowAddTaskPanel(false);
    setEditTaskId(undefined);
  };

  const handleTaskUpdate = (task: Task) => {
    refetchTasks();
    setEditTaskId(task.id);
  };

  const freeChannelListeners = () => {
    channelListeners.current.forEach(listener => listener.unsubscribe());
    channelListeners.current = [];
  };

  const renderContent = () => {
    let content = <></>;
    let sidePanel = <></>;
    switch (type) {
      case 'messenger':
        content = (
          <PaginatedTable
            onRowClick={handleRowClick}
            sx={{ overflowX: 'auto' }}
            pagination={false}
            columns={columns({
              members,
              t,
              columnSet: [
                'task',
                'dueDate',
                'status',
                'priority',
                'assignee',
                'assigneeMsg',
                'createdAt',
              ],
            })}
            rows={(resID && taskList.filter(task => task.reservationId === resID)) ?? []}
            onFetch={handleSearch}
            loading={isDeferredDataLoading}
            initialState={{
              sorting: {
                sortModel: [{ field: 'dueDateLocalized', sort: 'asc' }],
              },
            }}
          />
        );
        break;
      default:
        content = (
          <>
            <TasksTitlebar onCreateClick={handleCreateTask} />
            <PaginatedTable
              enableToolbar
              onRowClick={handleRowClick}
              columns={columns({
                members,
                t,
                navigate,
              })}
              sx={{ overflowX: 'auto' }}
              rows={filteredTasks ?? []}
              onFetch={handleSearch}
              filtersToolbarProps={tasksFilters}
              loading={isDeferredDataLoading}
              additionalComponents={<TaskSummaryCard data={summaryCardProps} />}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'dueDateLocalized', sort: 'asc' }],
                },
              }}
            />
          </>
        );
        sidePanel = showAddTaskPanel && (
          <TasksDetails
            taskId={editTaskId}
            handleClose={handleCreateTaskPanelClose}
            onTaskUpdate={handleTaskUpdate}
          />
        );
    }
    return (
      <>
        <Box
          sx={{
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {content}
        </Box>

        {sidePanel}
      </>
    );
  };

  useEffect(() => {
    dispatch(
      getMembersByCompany({
        filterType: MemberFilterType.ALL_MEMBERS,
        pageNum: 0,
        numPerPage: 100000,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    }

    handleSearch(PAGE_NUM, PAGE_SIZE, searchString);
  }, [handleSearch, searchString]);

  useEffect(() => {
    const nowUtc = new Date();
    const summary = taskList.reduce(
      (acc, task) => {
        const dueDate = zonedTimeToUtc(
          task.dueDateLocalized,
          task.unitTimeZone || 'America/Vancouver',
        );

        if (!CLOSED_STATUS.includes(task.status)) {
          if (!task.assigneeId || isNaN(task.assigneeId)) {
            acc['Unassigned']++;
          }

          if (dueDate < nowUtc) {
            acc['Overdue']++;
          } else if (dueDate.toDateString() === nowUtc.toDateString()) {
            acc['Due today']++;
          }
        }

        return acc;
      },
      {
        Overdue: 0,
        'Due today': 0,
        Unassigned: 0,
      },
    );

    setSummaryCardProps({
      ...summary,
    });

    queryChannelsPerTasks(taskList)
      .then(async ({ guestChannels, staffTaskChannels }) => {
        freeChannelListeners();

        for (const task of taskList) {
          guestChannels.forEach((channel: TChannel) => {
            const chId = channel.id.split('-');
            if (task.reservationId === chId[1]) {
              task.msgGuestCount = channel.countUnread();
              task.msgGroupId = channel.id;
              const newMsgListener = channel.on('message.new', () => {
                task.msgGuestCount = channel.countUnread();
              });
              channelListeners.current.push(newMsgListener);
            }
          });
          staffTaskChannels.forEach((channel: TChannel) => {
            const chId = channel.id.split('tid');
            if (task.assigneeId && task.id === chId[1]) {
              const hasMember = channel.state.members[`mid-${task.assigneeId}`];
              if (hasMember) {
                task.msgMemberCount = channel.countUnread();
                task.msgMemberId = channel.id;
                const newMsgListener = channel.on('message.new', () => {
                  task.msgMemberCount = channel.countUnread();
                });
                channelListeners.current.push(newMsgListener);
              }
            }
          });
        }
      })
      .catch(error => {
        logger.error(error);
      })
      .finally(() => {
        setFetchingChannels(false);
      });

    return () => {
      freeChannelListeners();
    };
  }, [taskList, queryChannelsPerTasks]);

  useEffect(() => {
    const navigationState = location.state as {
      showAddTaskPanel?: boolean;
      editTaskId?: string;
    };

    if (navigationState) {
      if (navigationState.showAddTaskPanel) {
        setShowAddTaskPanel(true);
      }
      if (navigationState.editTaskId) {
        setEditTaskId(navigationState.editTaskId);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (!assigneeIDsState.isLoading && !assigneeFilterValues) {
      const frequencyMap = (assigneeIDsState?.data as Record<string, number>) ?? {};
      const membersWithTasks = allMembers.filter(member => frequencyMap[member.id] > 0);
      const filterValues = [...membersWithTasks].sort((a, b) => {
        const freqA = frequencyMap[a.id] || 0;
        const freqB = frequencyMap[b.id] || 0;
        return freqB - freqA;
      });
      setAssigneeFilterValues(filterValues);
    }
  }, [assigneeIDsState?.data, allMembers, assigneeFilterValues, assigneeIDsState?.isLoading]);

  useEffect(() => {
    TaskTableEmitter.on('refresh', refetchTasks);
    return () => {
      TaskTableEmitter.off('refresh', refetchTasks);
    };
  }, [refetchTasks]);

  return renderContent();
}
