import { Box, Checkbox, Grid, ListItemAvatar, ListItemButton, Typography } from '@mui/material';
import WarningIcon from 'Common/Icons/WarningIcon';
import useTranslation from 'hooks/useTranslation';
import { MessageFilterIndex } from 'Pages/Messaging/MessagingPage';
import * as React from 'react';
import ProfileIcon from 'ui-library/Components/icon/ProfileIcon';

export interface ChatListItemProps {
  contactName: string;
  onClick?: () => void;
  chatMemberType: MessageFilterIndex;
  checkboxOnClick?: (reservationId: number) => void;
  reservationId: number;
  memberExists: boolean;
  unit?: string;
  imageUrl?: string;
  hasError?: boolean;
}

const ChatListItem = ({
  contactName,
  onClick,
  chatMemberType,
  checkboxOnClick,
  reservationId,
  memberExists,
  unit,
  imageUrl,
  hasError,
}: ChatListItemProps) => {
  const { t } = useTranslation();

  const isMember = chatMemberType === MessageFilterIndex.MEMBERS;
  const isGuest = chatMemberType === MessageFilterIndex.GUESTS;

  const handleItemClick = () => {
    if (isGuest) {
      onClick?.();
    } else {
      checkboxOnClick?.(reservationId);
    }
  };

  return (
    <ListItemButton
      onClick={handleItemClick}
      disabled={hasError}
      sx={{
        '&.Mui-disabled': {
          opacity: 0.6,
        },
      }}
    >
      {isMember && (
        <Checkbox
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            checkboxOnClick?.(reservationId);
          }}
          checked={memberExists}
        />
      )}
      <ListItemAvatar>
        <ProfileIcon imageUrl={imageUrl} fullName={contactName} />
      </ListItemAvatar>
      <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {isGuest && <Typography variant='caption'>{unit}</Typography>}
          <Typography variant='subtitle2'>{contactName}</Typography>
        </Box>
        {isGuest && hasError && (
          <Box
            onClick={e => e.stopPropagation()}
            sx={{
              pointerEvents: 'auto',
            }}
          >
            <WarningIcon tooltipTitle={t('messenger_assignment_error')} />
          </Box>
        )}
      </Grid>
    </ListItemButton>
  );
};

export default ChatListItem;
