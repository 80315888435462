import { Avatar, Box, Typography } from '@mui/material';
import { GridColDef, GridComparatorFn, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getCompanyRequestList } from '@operto/upsells-shared';
import { getCompanyRequest } from 'api/addOnAPI';
import { companySelector } from 'company/state/companySelectors';
import { format } from 'date-fns';
import { exportFile } from 'helper/helper';
import useTranslation from 'hooks/useTranslation';
import { useGetCompanyRequests } from 'hooks/useUpsells';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { userSelector } from 'user/state/userSelectors';
import OrderDetailsSidebar from './OrderDetailSidebar';

// TODO: move export to upsells-shared
export type Order = Awaited<ReturnType<typeof getCompanyRequestList>>[0] & {
  transactionId: string | undefined;
  companyId: string | undefined;
  offerId: string | undefined; // TODO: add these properties to requestList definition
  preferredTime: string | undefined;
  quantitySelected?: number | null;
  declinedAt?: string | undefined;
  declinedReason?: string | undefined;
  /** TODO Type Order.selectedQuantity, not implemented yet
   * @see {@link /src/Common/Tables/Orders/OrderDetailSidebar.tsx}
   * @line 238
   * */
  selectedQuantity?: number | null;
  taxPrice?: number | undefined;
  taxRate?: number | undefined;
};

export const getStatusColor = (status: string) => {
  switch (status?.toLowerCase()) {
    case 'pending':
      return '#E4E965';
    case 'approved':
    case 'completed':
      return '#BCDFE4';
    case 'rejected':
    case 'declined':
      return '#676668';
    case 'canceled':
      return '#E7E3E4';
    default:
      return 'white';
  }
};

export const setColor = (status: string) => {
  switch (status?.toLowerCase()) {
    case 'rejected':
    case 'declined':
      return '#f5f5f5';
    default:
      return '#111111';
  }
};

const getDisplayStatus = (status: string) => {
  if (!status) {
    return 'N/A';
  }
  switch (status.toLowerCase()) {
    case 'approved':
    case 'completed':
      return 'Purchased';
    case 'rejected':
    case 'declined':
      return 'Declined';
    default:
      return status[0].toUpperCase() + status.slice(1);
  }
};

const OrdersTable = ({ title }: { title: string }) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const company = useAppSelector(companySelector());
  const { t } = useTranslation();
  const { accessToken } = useAppSelector(userSelector());
  const { data: companyRequests, isLoading } = useGetCompanyRequests(
    {
      params: { companyId: company.id },
      headers: { Authorization: `Bearer ${accessToken}` },
    },
    {
      enabled: !!company.id,
    },
  );

  const fetchOrder = useCallback(
    async (requestId: string) => {
      try {
        const { data: responseBody } = await getCompanyRequest('' + company.id, requestId);
        return responseBody.data;
      } catch (error) {
        logger.error('Error fetching order', error);
      }
    },
    [company.id],
  );

  const onUpdate = async (orderId: Order['id']) => {
    const freshOrder = await fetchOrder(orderId);
    const currentOrder: { [key: string]: unknown } = filteredOrders.find(o => o.id === orderId);
    for (const field of Object.getOwnPropertyNames(freshOrder)) {
      currentOrder[field] = freshOrder[field];
    }
    setFilteredOrders(Array.from(filteredOrders));
    setSelectedOrder(currentOrder as Order);
  };

  const handleSearch = useCallback(
    (pageNum: number, numPerPage: number, searchString?: string) => {
      if (!searchString?.length) {
        return setFilteredOrders(orders);
      }

      const lowerCaseSearchString = searchString.toLowerCase();
      const filteredOrders = orders.filter(
        (order: Order) =>
          getDisplayStatus(order?.requestStatus).toLowerCase().includes(lowerCaseSearchString) ||
          order.name?.toLowerCase()?.includes(lowerCaseSearchString) ||
          order.guestId?.toLowerCase()?.includes(lowerCaseSearchString) ||
          order.guestName?.toLowerCase()?.includes(lowerCaseSearchString) ||
          order.unitId?.toLowerCase()?.includes(lowerCaseSearchString) ||
          order.unitName?.toLowerCase()?.includes(lowerCaseSearchString),
      );

      setFilteredOrders(filteredOrders);
    },
    [orders],
  );

  const handleExport = (format = 'csv') => {
    let columns = {};
    if (format === 'csv') {
      columns = {
        id: t('order_id'),
        requestStatus: t('status'),
        requestedDate: t('requested'),
        name: t('order_name'),
        selectedQuantity: t('quantity'),
        totalPrice: t('price'),
        unitName: t('unit'),
        unitId: t('unit_id'),
        guestName: t('guest_name'),
        guestId: t('guest_id'),
      };
    } else if (format === 'xml') {
      columns = {
        id: 'order_id',
        requestStatus: 'status',
        requestedDate: 'requested',
        name: 'order_name',
        selectedQuantity: 'quantity',
        totalPrice: 'price',
        unitName: 'unit',
        unitId: 'unit_id',
        guestName: 'guest_name',
        guestId: 'guest_id',
      };
    } else {
      columns = {
        id: 'order_id',
        requestStatus: 'status',
        requestedDate: 'requested',
        name: 'order_name',
        selectedQuantity: 'quantity',
        totalPrice: 'price',
        unitName: 'unit',
        unitId: 'unit_id',
        guestName: 'guest_name',
        guestId: 'guest_id',
      };
    }

    exportFile({
      format,
      columns,
      data: filteredOrders,
    });
  };

  // Sort orders by requestStatus with "Pending" on top and then by requestStatus in descending order
  const requestStatusComparator: GridComparatorFn<string> = (statusA, statusB) => {
    if (statusA === 'Pending' && statusB !== 'Pending') {
      return -1; // statusA comes before statusB
    }
    if (statusA !== 'Pending' && statusB === 'Pending') {
      return 1; // statusB comes before statusA
    }
    return statusB.localeCompare(statusA); // Sort by status in descending order
  };

  const columnsDef: GridColDef[] = [
    {
      field: 'requestStatus',
      headerName: t('status'),
      width: 120,
      resizable: false,
      sortComparator: requestStatusComparator,
      valueGetter: ({ row }) => getDisplayStatus(row.requestStatus),
      renderCell: ({ row }: GridRenderCellParams<string, Order>) => (
        <Box
          sx={{
            backgroundColor: getStatusColor(row.requestStatus),
            color: setColor(row.requestStatus),
            paddingVertical: '5px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '5px',
            fontWeight: 600,
            fontSize: '10px',
          }}
          data-testid='order-request-status'
        >
          {getDisplayStatus(row.requestStatus)}
        </Box>
      ),
    },
    {
      field: 'requestedDate',
      headerName: t('requested'),
      width: 120,
      resizable: false,
      sortable: true,
      renderCell: ({ row }: GridRenderCellParams<string, Order>) => (
        <Box>
          <Typography variant='body2' noWrap>
            {format(new Date(row.requestedDate), 'yyyy-MM-dd')}
          </Typography>
          <Typography variant='caption' color='textSecondary' noWrap>
            {format(new Date(row.requestedDate), 'hh:mm aa')}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'name',
      headerName: t('order'),
      minWidth: 200,
      renderCell: ({ row }: GridRenderCellParams<string, Order>) => (
        <Box
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', background: 'white' }}
          onClick={() => setSelectedOrder(row)}
          data-testid='order-name'
        >
          <Avatar
            src={row.imageUrl}
            alt={row.name}
            variant='rounded'
            sx={{ marginRight: '10px', borderRadius: '8px' }}
          />
          <Box>
            <Typography
              variant='body2'
              noWrap
              sx={{
                color: 'primary.main',
                fontWeight: 600,
                cursor: 'pointer',
              }}
            >
              {row.name}
            </Typography>
            <Typography variant='caption' color='textSecondary' noWrap>
              {row.id}
            </Typography>
          </Box>
        </Box>
      ),
      flex: 2,
    },
    {
      field: 'quantitySelected',
      headerName: t('quantity'),
      width: 170,
      resizable: false,
      sortable: true,
      cellClassName: 'white-cell',
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ row }: GridRenderCellParams<Order>) => (
        <Box sx={{ background: 'white' }}>{row.quantitySelected}</Box>
      ),
    },
    {
      field: 'totalPrice',
      headerName: t('price'),
      width: 170,
      resizable: false,
      sortable: true,
      cellClassName: 'white-cell',
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ row }: GridRenderCellParams<string, Order>) => (
        <Box>{`$${row.totalPrice.toFixed(2)} USD`}</Box>
      ),
    },
    {
      field: 'unitAssigned.units',
      headerName: t('unit'),
      minWidth: 200,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<string, Order>) => (
        <Box>
          <Typography variant='body2' noWrap>
            {row.unitName}
          </Typography>
          <Typography variant='caption' color='textSecondary' noWrap>
            {row.unitId}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'guestName',
      headerName: t('guest'),
      minWidth: 200,
      sortable: true,
      renderCell: ({ row }: GridRenderCellParams<string, Order>) => <Box>{row.guestName}</Box>,
    },
  ];

  useEffect(() => {
    if (orders.length) {
      setFilteredOrders(orders);
    }
  }, [orders]);

  useEffect(() => {
    if (!!companyRequests) {
      // Sort orders by requestedDate in descending order
      const sortedRequests = [...companyRequests.data].sort(
        (a: Order, b: Order) =>
          new Date(b.requestedDate).getTime() - new Date(a.requestedDate).getTime(),
      );
      setOrders(sortedRequests);
    }
  }, [company.id, companyRequests]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'inherit' }}>
      <PaginatedTable
        enableToolbar
        title={title}
        rows={filteredOrders}
        rowCount={filteredOrders.length}
        columns={columnsDef}
        loading={isLoading}
        onFetch={handleSearch}
        onExport={handleExport}
      />
      {selectedOrder && (
        <OrderDetailsSidebar
          order={selectedOrder}
          onClose={() => setSelectedOrder(null)}
          onUpdate={onUpdate}
        />
      )}
    </Box>
  );
};

export default OrdersTable;
