import { Box, Button, Typography } from '@mui/material';
import List from '@mui/material/List';
import { useMedia } from '@operto/ui';
import ChatListItem from 'Common/Lists/ChatListItem';
import SearchField from 'Common/TextField/SearchField';
import { createGuestChannel, createMemberChannel } from 'Pages/Messaging/MessagingActions';
import { FilterType, GuestsFilterKeys, IGuest } from 'guest/guestType';
import { getGuests } from 'guest/state/guestActions';
import { guestSelector, guestsTotalCountSelector } from 'guest/state/guestSelectors';
import debounce from 'lodash/debounce';
import { IMember, MemberFilterType } from 'member/memberType';
import { getMembersByCompany } from 'member/state/memberActions';
import { memberMetaSelector, memberSelector } from 'member/state/memberSelectors';
import * as React from 'react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { INITIAL_STATE, useGetGroupsQuery } from 'redux/groups/api-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Channel } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';
import OpertoProgressCircle from 'ui-library/Components/misc/OpertoProgressCircle';
import { currentUserSelector } from 'user/state/userSelectors';
import { UserState } from 'user/userTypes';
import { MessageFilterIndex } from './MessagingPage';

export interface MessagePeopleListChatContainerProps {
  onNewChat: (isNew: boolean) => void;
  peopleType: MessageFilterIndex;
  user: UserState;
  lastClickedOnChannel: (channel: Channel) => void;
}
const MessagePeopleListChatContainer = ({
  onNewChat,
  peopleType,
  user,
  lastClickedOnChannel,
}: MessagePeopleListChatContainerProps) => {
  const dispatch = useAppDispatch();
  const guests = useAppSelector(guestSelector());
  const guestsMetaSelector = useAppSelector(guestsTotalCountSelector(FilterType.ALL_GUESTS));
  const members = useAppSelector(memberSelector());
  const self = useAppSelector(currentUserSelector());
  const membersMetaSelector = useAppSelector(memberMetaSelector(MemberFilterType.ALL_MEMBERS));
  const { isTablet, isMobile } = useMedia();
  const { client, setActiveChannel } = useChatContext();
  const [channelMembers, setChannelMembers] = useState([user.user.id]);
  const [channelGuests, setChannelGuests] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const { data = INITIAL_STATE, isLoading } = useGetGroupsQuery();

  const isGuest = peopleType === MessageFilterIndex.GUESTS;
  const isMember = peopleType === MessageFilterIndex.MEMBERS;
  const channel = isGuest ? channelGuests : channelMembers;
  const peopleTypeString = isGuest ? 'guests' : 'members';

  const fetchGuestsOrMembers = useCallback(
    (keyword = '', pageNum = 0) => {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set(GuestsFilterKeys.KEYWORD, keyword);
      urlSearchParams.set(GuestsFilterKeys.PAGE_NUMBER, pageNum.toString());
      if (isGuest) {
        dispatch(
          getGuests({
            filterType: FilterType.ALL_GUESTS,
            urlSearchParams,
          }),
        );
      } else {
        dispatch(
          getMembersByCompany({
            filterType: MemberFilterType.ALL_MEMBERS,
            numPerPage: 50,
            pageNum,
            keyword,
          }),
        );
      }
    },
    [dispatch, isGuest],
  );

  const debounceSearch = debounce(fetchGuestsOrMembers, 200);

  const findNumberInString = (text: string) => {
    // find one or more numebres in a string
    const match = text.match(/\d+/);
    return match ? match[0] : text;
  };

  const isGuestNotAssignedToUser = (guest: { property_id: number }) => {
    const group = data.groups?.find(group => group.propertyIds.includes(guest.property_id));
    return !group || !group.memberIds.includes(self?.id);
  };

  const filteredGuests = guests.filter(
    (row: IGuest) =>
      row?.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      row?.property_name?.toLowerCase().includes(findNumberInString(searchValue?.toLowerCase())),
  );

  const filteredMembers = members.filter(
    (row: IMember) => row?.name && row?.name.toLowerCase().includes(searchValue?.toLowerCase()),
  );

  const hasMore = () => {
    if (isGuest) {
      return guests.length < guestsMetaSelector?.total_records && guests?.length > 0;
    } else {
      return members.length < membersMetaSelector?.total_records && members?.length > 0;
    }
  };

  const handleSelect = (reservationId: number) => {
    let newChannelIds = [];
    if (isGuest) {
      newChannelIds = !channelGuests.includes(reservationId)
        ? [...channelGuests, reservationId]
        : channelGuests.filter(guest => {
            return guest !== reservationId;
          });
      setChannelGuests(newChannelIds);
    } else {
      newChannelIds = !channelMembers.includes(reservationId)
        ? [...channelMembers, reservationId]
        : channelMembers.filter(member => {
            return member !== reservationId;
          });
      setChannelMembers(newChannelIds);
    }
  };

  const handleOnClick = async (reservationId?: number) => {
    if (isGuest) {
      await dispatch(createGuestChannel(reservationId)).then(async data => {
        const filters = { cid: { $eq: String(data) } };
        const channels = await client.queryChannels(filters);
        setActiveChannel(channels[0]);
        lastClickedOnChannel(channels[0]);
        setChannelGuests([]);
      });
    } else {
      await dispatch(createMemberChannel(channelMembers)).then(async data => {
        const filters = { cid: { $eq: String(data) } };
        const channels = await client.queryChannels(filters);
        setActiveChannel(channels[0]);
        lastClickedOnChannel(channels[0]);
      });
    }
    onNewChat(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(e.currentTarget.value);
    debounceSearch(e.currentTarget.value);
  };

  const handleFetchMore = () => {
    if (isGuest) {
      debounceSearch(searchValue, guestsMetaSelector.pageNum + 1);
    } else {
      debounceSearch(searchValue, membersMetaSelector.pageNum + 1);
    }
  };

  const isChecked = (id: number) =>
    isGuest ? channelGuests.includes(id) : channelMembers.includes(id);

  const isDisabledButton = () => {
    if (channel === channelGuests) {
      return channelGuests.length !== 1;
    } else {
      return channelMembers.length < 2;
    }
  };

  const getHeight = () => {
    const screenHeight = window.innerHeight;
    const TABLET_PERCENTAGE = 0.8905;
    const MOBILE_PERCENTAGE = 0.8866;

    if (isTablet) {
      const heightInPixels = Math.round(screenHeight * TABLET_PERCENTAGE);
      return `${heightInPixels}px`;
    }
    if (isMobile) {
      const heightInPixels = Math.round(screenHeight * MOBILE_PERCENTAGE);
      return `${heightInPixels}px`;
    }
    return 'none';
  };

  useEffect(() => {
    setSearchValue('');
    fetchGuestsOrMembers();
  }, [fetchGuestsOrMembers]);

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', height: getHeight() }}>
      <Box
        sx={{
          flexBasis: '64px',
          height: '58px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 24px',
          borderBottom: `1px solid #ebebeb`,
        }}
      >
        <Typography variant='h3'>Select {peopleTypeString}</Typography>
        {isMember && (
          <Button
            onClick={() => handleOnClick()}
            color='primary'
            variant='contained'
            size='medium'
            disabled={isDisabledButton()}
            sx={{ textTransform: 'none' }}
          >
            Start chat
          </Button>
        )}
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            padding: '0 2px',
            height: '74px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <SearchField onChange={handleSearch} value={searchValue} size='small' fullWidth />
        </Box>

        <List
          id='scrollable-chat-list'
          sx={{
            width: '90%',
            margin: '0 24px',
            height: '470px',
            marginBottom: '16px',
            overflowY: 'auto',
            flex: '1 0 0',
          }}
        >
          <InfiniteScroll
            dataLength={isGuest ? guests.length || 0 : members.length || 0}
            next={handleFetchMore}
            hasMore={hasMore()}
            scrollableTarget='scrollable-activity'
            loader={<OpertoProgressCircle />}
          >
            {(() => {
              if (isGuest && !isLoading) {
                return filteredGuests.map((guest: IGuest) => {
                  return (
                    <Fragment key={guest.id}>
                      <ChatListItem
                        reservationId={guest.reservation_id}
                        contactName={guest.name}
                        memberExists={isChecked(guest.reservation_id)}
                        chatMemberType={MessageFilterIndex.GUESTS}
                        onClick={() => handleOnClick(guest.reservation_id)}
                        unit={guest.property_name}
                        hasError={isGuestNotAssignedToUser(guest)}
                      />
                    </Fragment>
                  );
                });
              } else {
                return filteredMembers
                  .filter(member => member?.id !== self?.id)
                  .map((member: IMember) => {
                    return (
                      <Fragment key={member.id}>
                        <ChatListItem
                          checkboxOnClick={handleSelect}
                          reservationId={member.id}
                          contactName={member.name}
                          memberExists={isChecked(member.id)}
                          chatMemberType={MessageFilterIndex.MEMBERS}
                        />
                      </Fragment>
                    );
                  });
              }
            })()}
          </InfiniteScroll>
        </List>
      </Box>
    </Box>
  );
};

export default MessagePeopleListChatContainer;
