export interface ICompany {
  roomTypeEnabled: boolean;
  address: string;
  name: string;
  email: string;
  logoUrl: string;
  phoneNumber: string;
  websiteUrl: string;
  id: number;
  accessCodeFormat: string | null;
  code: string | null;
  guestPortalStatus?: GuestPortalStatus;
  messengerSetting: MessengerSetting;
  smartButtonMessengerTriggers: boolean;
  accessCodeSetting: string | null;
  testAccountEnabled: boolean;
  verificationProvider?: string;
  smwEnabled: boolean;
  allMembersMigratedToCognito: boolean;
  newLoginEnabled: boolean;
  metaData: string;
}

export enum GuestPortalStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface MessengerSetting {
  mp_member_messenger_enabled: boolean;
  mp_guest_messenger_enabled: boolean;
  gp_smart_button_messenger_enabled: boolean;
  mp_instant_reply_enabled: boolean;
}

export enum ProviderTypes {
  NONE = 'none',
  SUPERHOG = 'superhog',
  AUTOHOST = 'autohost_web',
}

export interface CompanyMetaData {
  translation_exclusion?: string[];
}
