import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Badge, Box, SxProps, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { utcToTimeZone } from 'helper/date';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { TableCell } from 'ui-library/Components/table/TableCell';
import DeliveryLogsTableAction from './DeliveryLogsTableAction';

export default function useDeliveryLogsColumns() {
  const { t } = useTranslation();

  const renderScheduledAt = ({ row }: GridRenderCellParams) => {
    const scheduledAt = row.scheduledAt;
    const timezone = row.timezone;
    const yyyMMMDD = utcToTimeZone(scheduledAt, timezone, 'yyyy-MM-dd');
    const hhmmA = utcToTimeZone(scheduledAt, timezone, 'hh:mm aa zz');

    return <TableCell title={yyyMMMDD} description={hhmmA} flexDirection='column' />;
  };

  const renderDeliveredAt = ({ row }: GridRenderCellParams) => {
    const deliveredAt = row.deliveredAt;
    if (!deliveredAt) return <TableCell title='' flexDirection='column' />;

    const timezone = row.timezone;
    const yyyMMMDD = utcToTimeZone(deliveredAt, timezone, 'yyyy-MM-dd');
    const hhmmA = utcToTimeZone(deliveredAt, timezone, 'hh:mm aa zz');

    return <TableCell title={yyyMMMDD} description={hhmmA} flexDirection='column' />;
  };

  const renderStatus = ({ row }: GridRenderCellParams) => {
    if (row.status === 'delivered') {
      return (
        <Box marginLeft={5}>
          <Tooltip title={t('delivered')}>
            <CheckCircleOutlineIcon style={{ color: '#2E7D32' }} />
          </Tooltip>
        </Box>
      );
    } else {
      return (
        <Box marginLeft={5}>
          <Tooltip title={t('failed_to_deliver')}>
            <ErrorOutlineOutlinedIcon style={{ color: '#D32F2F' }} />
          </Tooltip>
        </Box>
      );
    }
  };

  const renderGuest = ({ row }: GridRenderCellParams) => {
    const guestName = row.guestName;

    const TooltipTitle = <Box component='span'>{guestName}</Box>;

    return (
      <TableCell
        sx={{ overflow: 'hidden' }}
        title={
          <Tooltip title={TooltipTitle}>
            <Typography variant='body-sm-400' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {guestName}
            </Typography>
          </Tooltip>
        }
        flexDirection='column'
      />
    );
  };

  const renderUnit = ({ row }: GridRenderCellParams) => {
    const unitName = row.unitName;
    const unitId = row.unitId;

    const TooltipTitle = (
      <Box>
        <Box component='span'>{unitName}</Box>
        <br />
        <Box component='span'>{t('unit_id_with_id_value', { id: unitId })}</Box>
      </Box>
    );

    return (
      <TableCell
        sx={{ overflow: 'hidden' }}
        title={
          <Tooltip title={TooltipTitle}>
            <Typography variant='body-sm-400' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {unitName}
            </Typography>
          </Tooltip>
        }
        description={
          <Tooltip title={TooltipTitle}>
            <Typography
              variant='caption-lg-400'
              color='text.secondary'
            >{`ID ${unitId}`}</Typography>
          </Tooltip>
        }
        flexDirection='column'
      />
    );
  };

  const renderTemplate = ({ row }: GridRenderCellParams) => {
    const channelType = row.channelType;
    if (channelType === 'email') {
      return (
        <>
          <Badge>
            <Box sx={{ ...channelBoxStyle, backgroundColor: '#FFEA30' }}>
              <MailOutlineIcon />
            </Box>
          </Badge>
          <Typography
            variant='body-sm-400'
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: '8px' }}
          >
            {row.templateName}
          </Typography>
        </>
      );
    }

    if (channelType === 'sms') {
      return (
        <>
          <Badge>
            <Box sx={{ ...channelBoxStyle, backgroundColor: '#259C72' }}>
              <ChatBubbleOutlineIcon sx={{ color: 'white' }} />
            </Box>
          </Badge>
          <Typography
            variant='body-sm-400'
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: '8px' }}
          >
            {row.templateName}
          </Typography>
        </>
      );
    }
  };

  const renderActions = ({ row }: GridRenderCellParams<unknown>) => {
    return (
      <TableCell
        title={
          <DeliveryLogsTableAction
            deliveryLogId={row.id}
            channelType={row.channelType}
            communications={row.communications}
            deliveryLogsRow={row}
          />
        }
      />
    );
  };

  const columns = [
    {
      field: 'scheduledAt',
      headerName: 'Scheduled on',
      flex: 15,
      sortable: true,
      renderCell: renderScheduledAt,
    },
    {
      field: 'deliveredAt',
      headerName: 'Delivered on',
      flex: 15,
      sortable: false,
      renderCell: renderDeliveredAt,
    },
    {
      field: 'status',
      headerName: 'Delivery status',
      flex: 15,
      sortable: false,
      renderCell: renderStatus,
    },
    {
      field: 'guest',
      headerName: 'Guest',
      flex: 30,
      sortable: false,
      renderCell: renderGuest,
    },
    {
      field: 'recipient',
      headerName: 'Recipient',
      flex: 30,
      sortable: false,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      flex: 20,
      sortable: false,
      renderCell: renderUnit,
    },
    {
      field: 'template',
      headerName: 'Template',
      flex: 40,
      sortable: false,
      renderCell: renderTemplate,
    },
    {
      field: 'action',
      headerName: '',
      renderCell: renderActions,
      type: 'number',
      sortable: false,
      resizable: false,
      flex: 8,
      focusable: false,
    },
  ];

  return columns;
}

const channelBoxStyle: SxProps = {
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
};
