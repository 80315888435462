import { Box } from '@mui/material';
import React, { PropsWithChildren, useDeferredValue } from 'react';
import { Channel, DefaultGenerics } from 'stream-chat';
import { ChannelListMessengerProps } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { MessageFilterIndex } from './MessagingPage';

export interface MessageChannelListContainerProps {
  onNewChat?: (onNewChat: unknown) => void;
  peopleType?: MessageFilterIndex;
  isNewChat?: boolean;
  queryRecentChannel?: boolean;
  channelToDisplay?: Channel<DefaultStreamChatGenerics>;
}
const MessageChannelListContainer = ({
  children,
  error,
  loading,
  LoadingErrorIndicator,
  LoadingIndicator,
}: PropsWithChildren<ChannelListMessengerProps<DefaultGenerics>> & {
  channelToDisplay?: Channel<DefaultStreamChatGenerics>;
}) => {
  const isLoadingDeferred = useDeferredValue(loading);

  if (error) {
    return <LoadingErrorIndicator type={'connection'} />;
  }

  if (isLoadingDeferred) {
    return <LoadingIndicator />;
  }

  return (
    <Box
      id='messageChannelListContainer'
      sx={{
        margin: 'inherit',
        flexDirection: 'column',
        display: 'flex',
        overflowY: 'auto',
      }}
    >
      {children}
    </Box>
  );
};

export default MessageChannelListContainer;
