import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useMedia } from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import * as React from 'react';
import styled from 'styled-components';

export interface SearchFieldProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  value: string;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  isMainActivity?: boolean;
}

const SearchField = ({
  value,
  onChange,
  size = 'medium',
  fullWidth = false,
  isMainActivity = false,
}: SearchFieldProps) => {
  const { isMobile } = useMedia();
  const { t } = useTranslation();
  const handleClearInput = () => {
    onChange({ currentTarget: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <SearchForm
      variant='outlined'
      fullWidth={fullWidth}
      size={size}
      $isMobile={isMobile}
      data-testid='search-field'
    >
      <SearchLabel $isMobile={isMobile} $isMainActivity={isMainActivity}>
        {t('search')}
      </SearchLabel>

      <SearchInput
        inputProps={{ 'data-testid': 'search-field-input' }}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position='end'>
            {value ? (
              <ClearIcon
                onClick={handleClearInput}
                sx={{ cursor: 'pointer' }}
                data-testid='search-field-clear-icon'
              />
            ) : (
              <SearchIcon />
            )}
          </InputAdornment>
        }
        $isMobile={isMobile}
      />
    </SearchForm>
  );
};

const SearchLabel = styled(InputLabel)<{ $isMobile?: boolean; $isMainActivity: boolean }>`
  && {
    z-index: 100;
    background-color: inherit;
  }
`;

const SearchInput = styled(OutlinedInput)<{ $isMobile: boolean }>`
  && {
    border-radius: 25px;

    .MuiOutlinedInput-input {
      padding: ${props => props.$isMobile && '12px 14px'};
    }
  }
`;

const SearchForm = styled(FormControl)<{ $isMobile: boolean; size: 'small' | 'medium' }>`
  && {
    background-color: ${props => props.theme.containerAccentBGColor};
    margin: ${props => props.$isMobile && '0 8px 0 0'};
  }
`;

export default SearchField;
