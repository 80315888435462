import { Player } from '@lottiefiles/react-lottie-player';
import { BaseContainer } from 'Style/Components/BaseComponents';
import * as React from 'react';

export interface LoadingContainerProps {
  children?: React.ReactNode;
  loading?: boolean;
  overlay?: boolean;
}

const LoadingContainer = ({ children, loading, overlay }: LoadingContainerProps) => {
  const renderLoading = () => {
    return (
      <>
        {loading && (
          <BaseContainer
            style={{
              position: 'absolute',
              zIndex: 11,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgb(255, 255, 255, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            data-testid='loading-container'
          >
            <Player
              autoplay
              loop
              src='https://assets1.lottiefiles.com/packages/lf20_p6mlni56.json'
              style={{ height: '200px', width: '200px' }}
            />
          </BaseContainer>
        )}
        {children ?? <></>}
      </>
    );
  };

  if (overlay) {
    return renderLoading();
  }
  return <BaseContainer style={{ position: 'relative' }}>{renderLoading()}</BaseContainer>;
};

export default LoadingContainer;
