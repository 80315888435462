import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Drawer, Stack } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FormContainer } from 'Common/FormContainer';
import { trackEvent } from 'lib/analytics';
import { logger } from 'lib/logger';
import React, { useEffect, useState } from 'react';
import { toggleSnackbar } from 'redux/actions/ui';
import { useUpdateGroupPropertiesMutation } from 'redux/groups/api-slice';
import { Group } from 'redux/groups/types';
import { useAppDispatch } from 'redux/hooks';
import { INITIAL_STATE, useGetUnitsQuery } from 'redux/units/api-slice';
import { Unit, UnitFilterType } from 'redux/units/types';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import {
  addButtonStyle,
  addRemoveButtonContainerStyle,
  groupNameChipStyle,
  itemsTableStyle,
  removeButtonStyle,
  tableContainerStyle,
} from 'ui-library/Components/table/table-styles';
import { NamedChip } from '../Common/NamedChip';
import { UnitsSelectionTable, renderUnits } from '../Common/UnitsSelectionTable';
import {
  computeCurrentListItems,
  computeItemsToMove,
  computeListItemsToUpdate,
  getPinnedRowClassName,
  pinnedComparator,
} from './group-utils';

const createColumnDef = (selectedUnits: number[]): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: selectedUnits.length === 0 ? 'Units' : `${selectedUnits.length} selected`,
      flex: 1,
      valueGetter: params => params.row?.isPinned,
      renderCell: renderUnits,
      sortable: false,
      sortComparator: pinnedComparator,
    },
    {
      field: 'groups',
      headerName: 'Group',
      width: 300,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <NamedChip
          names={params.row.groups?.map((group: Group) => group.name)}
          sx={groupNameChipStyle}
        />
      ),
    },
  ];
};

type GroupsFormProps = {
  onClose: () => void;
  group: Group;
  open: boolean;
};

export const GroupUnitsEditForm = ({ onClose, group, open }: GroupsFormProps) => {
  const dispatch = useAppDispatch();
  const { data: unitsInGroup = INITIAL_STATE, isFetching: unitsInGroupFetching } = useGetUnitsQuery(
    {
      filterType: UnitFilterType.GROUP,
      numPerPage: 100000,
      [UnitFilterType.GROUP]: group?.id,
    },
  );

  const { data: unitsNotInGroup = INITIAL_STATE, isFetching: unitsNotInGroupFetching } =
    useGetUnitsQuery({
      filterType: UnitFilterType.EXCLUDE_GROUP,
      numPerPage: 100000,
      [UnitFilterType.EXCLUDE_GROUP]: group?.id,
    });

  const [updateGroupProperties, { isLoading: saveLoading }] = useUpdateGroupPropertiesMutation();

  const [selectedUnitsInGroup, setSelectedUnitsInGroup] = useState<number[]>([]);
  const [selectedUnitsNotInGroup, setSelectedUnitsNotInGroup] = useState<number[]>([]);

  const [pinnedUnitsInGroup, setPinnedUnitsInGroup] = useState<Unit[]>([]);
  const [pinnedUnitsNotInGroup, setPinnedUnitsNotInGroup] = useState<Unit[]>([]);

  const handleSave = async () => {
    try {
      await updateGroupProperties({
        groupId: group.id,
        propertyIds: computeListItemsToUpdate(
          group.propertyIds,
          pinnedUnitsInGroup,
          pinnedUnitsNotInGroup,
        ),
      }).unwrap();

      trackEvent({
        event: 'EDITED',
        screen: 'GROUP UNITS',
      });

      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Group properties are updated successfully',
          variant: SnackbarVariant.SUCCESS,
        }),
      );
      onClose();
    } catch (error) {
      logger.error(`Error updating group units: ${error}`);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: 'Group properties are not updated successfully',
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  const handleAdd = () => {
    const unitsToMove: Unit[] = computeItemsToMove(unitsNotInGroup.units, selectedUnitsNotInGroup);

    // moving units from not in group to in group
    setPinnedUnitsInGroup(prevPinnedUnitsInGroup => [...prevPinnedUnitsInGroup, ...unitsToMove]);

    // removing units from not in group
    setPinnedUnitsNotInGroup(prevPinnedUnitsNotInGroup =>
      prevPinnedUnitsNotInGroup.filter(unit => !selectedUnitsNotInGroup.includes(unit.id)),
    );

    // resetting selected units
    setSelectedUnitsNotInGroup([]);
  };

  const handleRemove = () => {
    const unitsToMove: Unit[] = computeItemsToMove(unitsInGroup.units, selectedUnitsInGroup);

    // moving units from in group to not in group
    setPinnedUnitsNotInGroup(prevPinnedUnitsNotInGroup => [
      ...prevPinnedUnitsNotInGroup,
      ...unitsToMove,
    ]);

    // removing units from in group
    setPinnedUnitsInGroup(prevPinnedUnitsInGroup =>
      prevPinnedUnitsInGroup.filter(unit => !selectedUnitsInGroup.includes(unit.id)),
    );

    // resetting selected units
    setSelectedUnitsInGroup([]);
  };

  const handleSelectedUnits = (selectedUnitIds: number[], unitType: UnitFilterType) => {
    if (unitType === UnitFilterType.GROUP) {
      setSelectedUnitsInGroup(selectedUnitIds);
    } else {
      setSelectedUnitsNotInGroup(selectedUnitIds);
    }
  };

  const handleTitleInfo = (tableType: UnitFilterType) => {
    if (tableType === UnitFilterType.GROUP) {
      return `${unitsInGroup.totalUnits} Units in the '${group.name}' group`;
    } else if (unitsNotInGroup.totalUnits === 1) {
      return `${unitsNotInGroup.totalUnits} Unit NOT in the '${group.name}' group`;
    } else {
      return `${unitsNotInGroup.totalUnits} Units NOT in the '${group.name}' group`;
    }
  };

  useEffect(() => {
    return () => {
      if (!open) {
        setPinnedUnitsNotInGroup([]);
        setPinnedUnitsInGroup([]);
      }
    };
  }, [open]);

  return (
    <Drawer
      open={open}
      anchor='right'
      onClose={onClose}
      PaperProps={{
        sx: { width: '80%', backgroundColor: 'background.default01', borderRadius: 0 },
      }}
    >
      <FormContainer
        title={`Edit units in the '${group?.name}' group`}
        loading={saveLoading}
        dirty={
          !unitsInGroupFetching &&
          !unitsNotInGroupFetching &&
          (pinnedUnitsInGroup.length > 0 || pinnedUnitsNotInGroup.length > 0)
        }
        onClose={onClose}
        onSubmit={handleSave}
      >
        <Stack direction='row' height='100%' width='100%'>
          <Box sx={tableContainerStyle} data-testid='in-group-selection-table'>
            <UnitsSelectionTable
              loading={unitsInGroupFetching}
              selectionModel={selectedUnitsInGroup}
              onSelectionChange={unitIds => handleSelectedUnits(unitIds, UnitFilterType.GROUP)}
              rows={computeCurrentListItems(
                unitsInGroup.units,
                pinnedUnitsInGroup,
                pinnedUnitsNotInGroup,
              )}
              getRowClassName={getPinnedRowClassName}
              columns={createColumnDef(selectedUnitsInGroup)}
              tableInfo={handleTitleInfo(UnitFilterType.GROUP)}
              isRowSelectable={() => !(selectedUnitsNotInGroup.length > 0)}
              disableSearch={selectedUnitsNotInGroup.length > 0}
              sortingMode='server'
              sx={itemsTableStyle}
            />
          </Box>
          <Box sx={addRemoveButtonContainerStyle}>
            <Button
              startIcon={<KeyboardArrowLeftIcon />}
              sx={addButtonStyle(selectedUnitsNotInGroup.length > 0 && !unitsNotInGroupFetching)}
              onClick={() => handleAdd()}
              disabled={unitsNotInGroupFetching}
            >
              Add
            </Button>
            <Button
              endIcon={<KeyboardArrowRightIcon />}
              sx={removeButtonStyle(selectedUnitsInGroup.length > 0 && !unitsInGroupFetching)}
              onClick={() => handleRemove()}
              disabled={unitsInGroupFetching}
            >
              Remove
            </Button>
          </Box>
          <Box sx={tableContainerStyle} data-testid='not-in-group-selection-table'>
            <UnitsSelectionTable
              loading={unitsNotInGroupFetching}
              selectionModel={selectedUnitsNotInGroup}
              onSelectionChange={unitIds =>
                handleSelectedUnits(unitIds, UnitFilterType.EXCLUDE_GROUP)
              }
              rows={computeCurrentListItems(
                unitsNotInGroup.units,
                pinnedUnitsNotInGroup,
                pinnedUnitsInGroup,
              )}
              getRowClassName={getPinnedRowClassName}
              columns={createColumnDef(selectedUnitsNotInGroup)}
              tableInfo={handleTitleInfo(UnitFilterType.EXCLUDE_GROUP)}
              isRowSelectable={() => !(selectedUnitsInGroup.length > 0)}
              disableSearch={selectedUnitsInGroup.length > 0}
              sortingMode='server'
              sx={itemsTableStyle}
            />
          </Box>
        </Stack>
      </FormContainer>
    </Drawer>
  );
};
